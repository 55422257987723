import { isNil } from 'lodash';

import userStore from 'stores/userStore';
import filterStore from 'stores/filterStore';
import servicesStore from 'stores/servicesStore';

import {
  filterKinds,
} from 'components/utils/filters/logic';
import { VARIANTS_CONFIGS } from 'components/utils/filters';
import { filtersUIConfig, filtersCategoryConfig } from 'components/pages/users/Filters/FilterPopup';
import { getSegmentType } from 'components/pages/analyze/SegmentsTab/logic/segments';
import { Events } from 'trackers/analytics/enums';

export function getFilterConfig({ kind }) {
  if (kind === filterKinds.CAMPAIGN_TAGS) {
    return filtersUIConfig[filterKinds.CAMPAIGNS];
  }
  return filtersUIConfig[kind] || {};
}

export function getFilterCategory({ kind }) {
  const filterConfig = getFilterConfig({ kind });

  const { categotyId } = filterConfig || {};

  const categoryConfig = filtersCategoryConfig.find((config) => config.id === categotyId) || {};

  return categoryConfig.title;
}

export function getObjectType({ kind, selectedFunnels }) {
  const filterConfig = getFilterConfig({ kind });

  if (!filterConfig.supportsSelecetedFunnel) {
    return 'None';
  }

  if (!selectedFunnels || selectedFunnels.length === 0) {
    return 'Accounts';
  }

  if (selectedFunnels.length === userStore.funnels.length) {
    return 'All Funnel Stages';
  }

  return 'Funnel Stages';
}

export function getEventMethod({ variant }) {
  const variantConfig = VARIANTS_CONFIGS[variant];
  return variantConfig?.label;
}

export function getEventSelectedField({
  kind, fieldIndex, fieldId, rules,
}) {
  if (isNil(fieldIndex) && !isNil(fieldId)) {
    return userStore.userAccount?.customFieldsIdToLabelMap?.[fieldId] || userStore.userAccount?.leadSourcesIdToLabelMap?.[fieldId];
  }

  if (isNil(fieldIndex) && isNil(fieldId) && rules) {
    const fieldNames = [];
    for (const rule of rules) {
      for (const subRule of rule) {
        fieldNames.push(subRule.fieldName);
      }
    }

    return fieldNames.join(',');
  }

  const filterProps = filterStore.filtersData.find((filterData) => filterData.kind === kind);
  if (!filterProps) {
    return undefined;
  }

  return filterProps.fieldNames[fieldIndex];
}

export function getFilterEventProperties({ filter }) {
  const {
    kind,
    data: {
      fieldId,
      variant,
      position,
      timeFrame,
      fieldIndex,
      selectedFunnel,
      relatedEntities,
      selectedFunnels,
      selectedOptions,
      rules,
    },
  } = filter;

  return {
    group: getFilterCategory({ kind }),
    type: kind,
    objectType: getObjectType({ kind, selectedFunnels }),
    method: getEventMethod({ variant }),
    segmentType: getSegmentType({ filterKind: kind, segmentId: fieldId }),
    selectedField: getEventSelectedField({
      kind, fieldIndex, fieldId, rules,
    }),
    selectedValue: selectedOptions,
    checkedAssociation: relatedEntities,
    checkedBeforeFunnel: !!selectedFunnel,
    checkedCustomDate: !!timeFrame,
    touchpointPosition: position,
  };
}

export function trackFilterEvents({ filterItems, isSaved = false }) {
  for (const filterItem of filterItems) {
    const properties = getFilterEventProperties({ filter: filterItem });

    servicesStore.eventTracker.track({
      eventName: Events.filtersAppliedFilter,
      properties: {
        ...properties,
        isSaved,
      },
    });
  }
}
