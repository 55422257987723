import React from 'react';
import PropTypes from 'prop-types';
import { lt, isEqual, gt } from 'lodash';
import get from 'lodash/get';
import { inject, observer } from 'mobx-react';
import classnames from 'classnames';
import { Checkbox } from '@infinigrow/libs';

import userStore from 'stores/userStore';
import Component from 'components/Component';
import MultiCheckSelect from 'components/controls/MultiCheckSelect';
import FilterActions from 'components/pages/users/Filters/FilterActions';
import Calendar from 'components/controls/Calendar';
import Textfield from 'components/controls/Textfield';
import Dropdown from 'components/controls/Dropdown';
import FilterVariantsWithFunnelStage from 'components/pages/users/Filters/FilterVariantsWithFunnelStage';
import EllipsisTooltip from 'components/controls/EllipsisTooltip';
import ContainsSelect from 'components/pages/users/Filters/FilterPanel/UI/ContainsSelect';
import checkboxStyle from 'styles/controls/multi-check-select.css';
import filterStyles from 'styles/users/filters.css';

import {
  COMPARISON_OPERATORS_CONTAINS,
  COMPARISON_TOGGLE,
  ConfigPropType,
  CONTAINS,
} from 'components/utils/filters';
import { compose } from 'components/utils/utils';
import { EQUALS, filterKinds } from 'components/utils/filters/logic';
import { dateFormat, checkIfDate } from 'utils';
import { getUpdateSelectedFunnels } from 'components/pages/users/Filters/logic/FilterVariantsWithFunnelStage';
import { groupBySegmentsOptions, getOptionsWithSegmentMapping } from 'components/pages/analyze/SegmentsTab/logic/segments';
import { allFunnelStagesOption } from 'components/utils/filters/enums';

const styles = filterStyles.locals;

const COMPARISON_FUNCTION_MAP = {
  LOWER: lt,
  EQUALS: isEqual,
  HIGHER: gt,
};

const enhance = compose(
  inject(({
    filterStore: {
      getFilterKindOptions,
    },
  }) => ({
    getFilterKindOptions,
  })),
  observer
);

class MultiFieldFilter extends Component {
  styles = [checkboxStyle];

  static propTypes = {
    config: ConfigPropType.isRequired,
    onCancel: PropTypes.func.isRequired,
    onAddFilter: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { activeFilter } = props;

    this.state = {
      comparisonOperator: EQUALS,
      manualValue: '',
      isUseCondition: false,
      selectedOptions: [],
      selectedFunnels: [allFunnelStagesOption],
      fieldId: props.config.fieldKey[0],
      variant: 0,
      dateValue: 0,
      selectedFilter: [],
      isContainsSelected: false,
      isRelatedEntities: false,
    };

    if (activeFilter) {
      let selectedFunnels = get(activeFilter, 'data.selectedFunnels');
      if (selectedFunnels) {
        selectedFunnels = selectedFunnels.map((funnel) => ({ label: userStore.getMetricNickname({ metric: funnel, isSingular: true }), value: funnel }));
      }
      this.state = {
        ...this.state,
        ...activeFilter.data,
        isContainsSelected: activeFilter.data.comparisonOperator === CONTAINS,
        comparisonOperator: activeFilter.data.comparisonOperator,
        isRelatedEntities: activeFilter.data.relatedEntities,
        selectedFunnels,
        selectedFilter: activeFilter,
      };
    }
  }

  componentDidMount() {
    this.setSelectedFunnels(this.state.selectedFunnels);
  }

  changeRelatedEntitiesCheckbox = (value) => {
    this.setState({ isRelatedEntities: value });
    this.updateFilter({ relatedEntities: value });
  };

  updateFilter = (newData) => {
    const {
      selectedOptions, variant, fieldId, comparisonOperator, manualValue, isRelatedEntities, selectedFilter,
    } = this.state;
    const data = {
      selectedOptions,
      variant,
      fieldId,
      manualValue,
      comparisonOperator,
      selectedFunnels: get(selectedFilter, 'data.selectedFunnels'),
      relatedEntities: isRelatedEntities,
      ...newData,
    };
    this.setState({
      variant: data.variant,
      selectedOptions: data.selectedOptions,
      fieldId: data.fieldId,
      manualValue: data.manualValue,
      comparisonOperator,
    });

    const { config: { kind } } = this.props;
    const filterObj = {
      kind,
      data: {
        variant: data.variant,
        selectedOptions: data.selectedOptions,
        selectedFunnels: data.selectedFunnels,
        fieldId: data.fieldId,
        comparisonOperator,
        ...data,
      },
    };
    this.setState({ selectedFilter: filterObj });
    this.props.updateFiltersItems(filterObj);
  };

  handleFilterApply = () => {
    this.props.addFilterToSelection(this.state.selectedFilter, null, null, () => this.props.onAddFilter());
  };

  setFilterfieldId = (e) => {
    this.updateFilter({
      fieldId: e.value,
      selectedOptions: [],
      comparisonOperator: EQUALS,
      manualValue: '',
      dateValue: 0,
    });
  };

  setFilterVariant = (e) => this.updateFilter({ variant: e.value });

  selectOptions = (selectedOptions) => {
    const selected = selectedOptions !== null ? selectedOptions.map((opt) => opt.value) : [];
    this.updateFilter({ selectedOptions: selected });
  };

  clearCondition = () => {
    this.updateFilter({
      manualValue: '', comparisonOperator: EQUALS, dateValue: 0,
    });
  };

  optionsFilter = (arrayToFilter) => arrayToFilter.map((item) => {
    const date = dateFormat(item);
    return {
      label: checkIfDate(item) ? date : item,
      value: item,
    };
  });

  onChangeComparison = ({ value }) => {
    this.setState({
      comparisonOperator: value,
      isContainsSelected: value === CONTAINS,
      manualValue: '',
    }, () => {
      const { activeFilter } = this.props;
      if (get(activeFilter, 'data.comparisonOperator') === value) {
        this.updateFilter({ selectedOptions: activeFilter.data.selectedOptions, manualValue: '' });
      } else {
        this.updateFilter({ selectedOptions: [], manualValue: '' });
      }
    });
  };

  containsValueHandler = (values = []) => {
    const containsItems = values.map((item) => item.label);
    this.updateFilter({ selectedOptions: containsItems });
  };

  onAddManualValue = ({ target }) => {
    this.setState({
      manualValue: +target.value,
      selectedOptions: [],
    }, () => {
      this.updateFilter({ selectedOptions: [], manualValue: +target.value });
    });
  };

  setSelectedFunnels = (items = []) => {
    const selectedFunnels = getUpdateSelectedFunnels(items);
    const selectedFunnelsForRequest = selectedFunnels.map((funnel) => funnel.value).filter((a) => a !== 'accounts');
    this.setState({
      selectedFunnels,
    }, () => this.updateFilter({ selectedFunnels: selectedFunnelsForRequest }));
  };

  render() {
    const {
      config, onCancel, variantOptions, getFilterKindOptions,
    } = this.props;
    const {
      variant, selectedOptions, fieldId, comparisonOperator, manualValue, isContainsSelected, isRelatedEntities, selectedFunnels,
    } = this.state;

    const fieldIndex = config.fieldKey.findIndex((key) => key === fieldId);
    let optionsForIndex = config.options[fieldIndex] || [];
    const optionsContainsOnlyNumbers = optionsForIndex.every((val) => !Number.isNaN(val));
    const dateOptions = optionsForIndex.filter((val) => checkIfDate(val));
    const haveNumericOptions = optionsContainsOnlyNumbers;
    const comparisonOperatorIsNotEqual = comparisonOperator !== EQUALS;
    const manualValueIsNotEmpty = manualValue !== '';
    const isCustomFieldTab = config.kind === filterKinds.CUSTOM_FIELDS;

    let userFunnelsStr = 'Accounts';
    const hasSelectedAccountStage = selectedFunnels?.some((funnel) => funnel.label === 'Accounts');
    const isAllFunnelStagesSelected = selectedFunnels?.some((funnel) => funnel.value === 'allStages');
    if (isAllFunnelStagesSelected) {
      userFunnelsStr = 'all funnel stages';
    } else if (selectedFunnels && selectedFunnels.length > 0 && !hasSelectedAccountStage) {
      const userFunnels = selectedFunnels.map((funnel) => funnel.label);
      userFunnelsStr = userFunnels.join(', ');
    }

    if (comparisonOperatorIsNotEqual || manualValueIsNotEmpty) {
      const comparisonFn = COMPARISON_FUNCTION_MAP[comparisonOperator] || (() => false);
      optionsForIndex = optionsForIndex.filter((val) => comparisonFn(+val, manualValue));
    }

    let fieldOptions = config.fieldKey.map((fKey, index) => ({
      label: config.fieldNames[index] || fKey,
      value: fKey,
    }));

    if (isCustomFieldTab) {
      fieldOptions = getOptionsWithSegmentMapping({ options: groupBySegmentsOptions({ options: fieldOptions }) });
    }

    return (
      <div className={styles.filterConfig}>
        <div className={classnames(styles.filterContent, isCustomFieldTab && styles.filterContentCustomFields)}>
          <header className={styles.filterHeader}>Find journeys which</header>
          <div className={styles.filterVariant}>
            <FilterVariantsWithFunnelStage
              onChangeVariantHandler={this.setFilterVariant}
              variantsOptions={variantOptions}
              defaultIndex={variant}
              showBottomInfo
              followingKind="the following:"
              onChangeFunnelHandler={this.setSelectedFunnels}
              selectedFunnels={selectedFunnels}
            />
          </div>
          <div className={styles.filterVariant}>
            <Dropdown
              onChange={this.setFilterfieldId}
              options={fieldOptions}
              selectedKey={config.fieldKey[fieldIndex]}
              controlWidth={210}
              isSearchable
            />
          </div>

          <div className={styles.rowCenter} style={{ marginBottom: 15, position: 'relative' }}>
            <Dropdown
              onChange={this.onChangeComparison}
              options={Object.entries(haveNumericOptions ? COMPARISON_OPERATORS_CONTAINS : COMPARISON_TOGGLE).map(([value, label]) => ({ value, label }))}
              selectedKey={comparisonOperator}
              placeholder="Select condition"
              controlWidth={210}
            />
            {haveNumericOptions && !isContainsSelected && (
              dateOptions.length > 0 ? (
                <div className={styles.manualValue}>
                  <Calendar
                    value={this.state.dateValue}
                    onChange={(e) => {
                      this.setState({
                        manualValue: Number(new Date(e)),
                        selectedOptions: [],
                        dateValue: new Date(e).toLocaleString('en-GB', { dateStyle: 'medium' }),
                      });
                    }}
                  />
                  <div
                    className={styles.popupClose}
                    onClick={this.clearCondition}
                  />
                </div>
              ) : (
                <div className={styles.manualValue}>
                  <Textfield
                    value={manualValue}
                    style={{ width: 72 }}
                    onChange={this.onAddManualValue}
                    type="number"
                  />
                  <div
                    className={styles.popupCloseValue}
                    onClick={this.clearCondition}
                  />
                </div>
              ))}
          </div>

          {isContainsSelected ? (
            <div className={styles.rowCenter} style={{ marginBottom: 15, position: 'relative' }}>
              <ContainsSelect containsValue={selectedOptions.map((item) => ({ label: item, value: item }))} containsValueHandler={this.containsValueHandler} />
            </div>
          ) : (
            <MultiCheckSelect
              className={styles.optionSelect}
              selected={this.optionsFilter(selectedOptions)}
              options={this.optionsFilter(optionsForIndex)}
              placeholder="Search item..."
              onChange={this.selectOptions}
              isAsyncPagination={config.isSearchable}
              searchFunction={(searchValue, offset) => getFilterKindOptions({
                searchValue, filterKind: config.kind, filterKey: config.fieldKey[fieldIndex], offset,
              })}
              selectAll
              key={config.fieldKey[fieldIndex]}
            />
          )}

        </div>
        {isCustomFieldTab
          && (
            <div className={styles.relatedObjects}>
              <Checkbox
                key="Use Associated objects"
                checked={isRelatedEntities}
                onChange={() => this.changeRelatedEntitiesCheckbox(!isRelatedEntities)}
              />
              <div className={styles.relatedObjectsLabel}>
                {'Find '}
                <EllipsisTooltip
                  text={`${userFunnelsStr}`}
                  className={styles.relatedObjectsEllipsis}
                />
                {' that are associated with '}
                <EllipsisTooltip
                  text={`${config.fieldNames[fieldIndex]} ${selectedOptions.length ? ':' : ''} ${selectedOptions}`}
                  className={styles.relatedObjectsEllipsis}
                />
              </div>
            </div>
          )}
        <FilterActions
          onApply={this.handleFilterApply}
          onCancel={onCancel}
          filtersItems={this.props.filtersItems}
        />
      </div>
    );
  }
}

export default enhance(MultiFieldFilter);
