import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import { cloneDeep, isEmpty, isEqual } from 'lodash';

import NotificationSection from 'components/pages/settings/actions/actionPopup/notificationSection';
import TriggerSection from 'components/pages/settings/actions/actionPopup/triggerSection';
import PreviewSection from 'components/pages/settings/actions/actionPopup/previewSection';
import PopupWithDrawerSteps from 'components/common/PopupWithDrawerSteps';

import {
  nameAlreadyInUseErrorMessage,
  emptyNotification,
  actionTriggerTypes,
  notificationFrequency,
  actionTriggerTypesLabels,
  notificationTypesLabels,
} from 'components/pages/settings/actions/enums';
import { getUniqueTitle } from 'components/utils/logic/utils';
import { isTriggerRulesAreValid } from 'components/pages/settings/actions/logic/actions';
import { getNotificationDetailsForServerRequest } from 'components/pages/settings/actions/actionPopup/notificationSection/logic/notificationSection';

import { Events } from 'trackers/analytics/enums';

import servicesStore from 'stores/servicesStore';

function ActionPopup({
  userStore,
  onClose,
  actionData = {},
  userActionNames = [],
  updateActionRequest,
  isActionExists,
  createNewAction,
  region,
  getMetricNickname = userStore?.getMetricNickname,
  userRegionsNicknames,
  isEnableMultipleNotifications,
}) {
  const [actionName, setActionName] = useState({
    name: actionData.name || getUniqueTitle({ baseTitle: 'New Alert', listOfNames: userActionNames }),
    error: '',
  });

  const [trigger, setTrigger] = useState({
    region: actionData.region || region,
    triggerType: actionData.triggerType || actionTriggerTypes.accountEngagement,
    frequency: actionData.frequency || notificationFrequency.daily,
  });

  const [triggerRules, setTriggerRules] = useState(cloneDeep(actionData.triggerRules) || [{}]);

  const isNewNotification = !actionData.notifications || actionData.notifications?.length === 0;
  const [notifications, setNotifications] = useState(isNewNotification ? [emptyNotification] : cloneDeep(actionData.notifications));

  const [validationAlert, setValidationAlert] = useState({
    isTriggerRulesValid: isTriggerRulesAreValid({ triggerType: actionData.triggerType, triggerRules: actionData.triggerRules }),
    isNotificationsValid: false,
  });

  function changeActionName({ newName }) {
    const isNameAlreadyExists = userActionNames.some((name) => name.toLowerCase() === newName.toLowerCase());
    if (isNameAlreadyExists) {
      setActionName({ name: newName, error: nameAlreadyInUseErrorMessage });
    } else {
      setActionName({ name: newName, error: '' });
    }
  }

  async function onSaveAction({ newRegion }) {
    const isNewAction = !isActionExists({ actionId: actionData.actionId });

    for (const notification of notifications) {
      const { body, subject } = getNotificationDetailsForServerRequest({
        notificationType: notification.notificationType, actionName: actionName.name, triggerType: trigger.triggerType, frequency: trigger.frequency, triggerRules, toDoMessage: notification.toDoMessage,
      });
      notification.subject = subject;
      notification.body = body;
    }

    if (isNewAction) {
      const newAction = {
        name: actionName.name,
        triggerRules,
        triggerType: trigger.triggerType,
        region: newRegion,
        notifications,
        frequency: trigger.frequency,
      };

      await createNewAction({ newAction });
    } else {
      const updatedAction = {};
      if (actionData.name !== actionName.name) {
        updatedAction.name = actionName.name;
      }

      if (!isEqual(triggerRules, actionData.triggerRules)) {
        updatedAction.triggerRules = triggerRules;
      }

      if (actionData.triggerType !== trigger.triggerType) {
        updatedAction.triggerType = trigger.triggerType;
      }

      if (actionData.region !== newRegion) {
        updatedAction.region = newRegion;
      }

      if (!isEqual(actionData.notifications, notifications)) {
        updatedAction.notifications = notifications;
      }

      if (actionData.frequency !== trigger.frequency) {
        updatedAction.frequency = trigger.frequency;
      }

      if (isEmpty(updatedAction)) {
        onClose();
        return;
      }
      await updateActionRequest({ actionId: actionData.actionId, properties: updatedAction });
    }

    servicesStore.eventTracker.track({
      eventName: Events.alertsSavedAlert,
      properties: {
        triggerType: actionTriggerTypesLabels[trigger.triggerType],
        alertType: notificationTypesLabels[notifications[0].notificationType],
        frequency: trigger.frequency,
        source: isNewAction ? 'New alert' : 'Edit Alert',
      },
    });

    onClose();
  }

  const isMultiRegionsAccount = userRegionsNicknames?.length > 1;

  const steps = [{
    title: '1. Set a trigger',
    isValid: validationAlert.isTriggerRulesValid,
    isShowContinueButton: true,
    content: (<TriggerSection
      triggerType={trigger.triggerType}
      setTriggerType={(newTriggerType) => setTrigger({ ...trigger, triggerType: newTriggerType })}
      triggerRules={triggerRules}
      setTriggerRules={(newTriggerRules) => setTriggerRules(newTriggerRules)}
      setNotifications={setNotifications}
      isActionsAreValid={validationAlert.isTriggerRulesValid}
      setIsActionsAreValid={(isValid) => setValidationAlert((prev) => ({ ...prev, isTriggerRulesValid: isValid }))}
    />),
  }, {
    title: '2. Create an alert',
    isValid: validationAlert.isNotificationsValid,
    isShowContinueButton: true,
    content: (<NotificationSection
      triggerRules={triggerRules}
      notifications={notifications}
      setNotifications={setNotifications}
      isActionsAreValid={validationAlert.isNotificationsValid}
      setIsActionsAreValid={(isValid) => setValidationAlert((prev) => ({ ...prev, isNotificationsValid: isValid }))}
      triggerData={trigger}
      setTriggerData={(newTriggerData) => setTrigger({ ...trigger, ...newTriggerData })}
      isEnableMultipleNotifications={isEnableMultipleNotifications}
    />),
  }, {
    title: '3. Preview',
    isShowContinueButton: false,
    isValid: true,
    hideCheckedIcon: true,
    content: (<PreviewSection
      triggerRules={triggerRules}
      toDoMessage={notifications?.[0]?.toDoMessage}
      actionName={actionName.name}
      setNotifications={setNotifications}
      notifications={notifications}
      triggerData={trigger}
    />),
  }];

  return (
    <PopupWithDrawerSteps
      onClose={onClose}
      title={actionName}
      updateTitle={({ newTitle }) => changeActionName({ newName: newTitle })}
      isTitleEditable
      titlePlaceholder={`E.g., "Track ${getMetricNickname({ metric: 'funnel1ToFunnel2ConversionRate' })}"`}
      isShowBusinessUnitSelection={isMultiRegionsAccount}
      region={trigger.region}
      onClickSave={({ newRegion }) => onSaveAction({ newRegion })}
      steps={steps}
    />
  );
}

export default inject(
  ({
    userStore: {
      updateActionRequest,
      isActionExists,
      createNewAction,
      userRegionsNicknames,
      userMonthPlan: {
        region,
      } = {},
      getMetricNickname,
    } = {},
  }) => ({
    updateActionRequest,
    isActionExists,
    createNewAction,
    region,
    getMetricNickname,
    userRegionsNicknames,
  }),
  observer
)(ActionPopup);
