import moment from 'moment';
import { FORECASTING_METHODS, PACING_METHODS } from 'components/utils/utils';
import {
  budgetTypeMapping,
} from 'components/utils/date';
import groupBy from 'lodash/groupBy';
import entries from 'lodash/entries';
import mergeWith from 'lodash/mergeWith';
import {
  get, isNil, sum,
} from 'lodash';
import { calculateAutomaticIntegration } from 'components/utils/integrations';

const sumFields = (objects) => mergeWith({}, ...objects, (valA = 0, valB = 0) => valA + valB);

export const getDataPerTimeFrame = (timeFrame, format, rawIndicatorsDataPerDay) => {
  const grouppedByTF = groupBy(rawIndicatorsDataPerDay, timeFrame);
  const timeEntries = entries(grouppedByTF);
  return timeEntries
    .map(([, days]) => days.map(({
      date, month, name, ...day
    }) => day))
    .map(sumFields)
    .map((month, index) => ({
      ...month,
      name: moment(new Date(+timeEntries[index][0])).format(format),
    }));
};

export const getFrequenciser = {
  dataPerDay: (dataPerDay) => dataPerDay.map(({
    date, month, week, ...item
  }) => item),
  dataPerMonth: (dataPerDay) => getDataPerTimeFrame('month', 'YY MMM DD', dataPerDay),
  dataPerWeek: (dataPerDay) => getDataPerTimeFrame('week', 'YY MMM DD', dataPerDay),
  dataPerQuarter: (dataPerDay) => getDataPerTimeFrame('quarter', 'YY MMM DD', dataPerDay),
};

export function getAnnualBudgetFromAppData(data) {
  return !isNil(data.annualBudget) ? data.annualBudget : sum(data.annualBudgetArray);
}

export function calculatedDataExtender(data) {
  const committedForecasting = data.forecastedIndicators && data.forecastedIndicators.map((month) => {
    const newMonth = {};
    for (const indicator of Object.keys(month)) {
      newMonth[indicator] = month[indicator].committed;
    }
    return newMonth;
  });

  let isTrial = null;
  let isAccountEnabled = null;
  if (data.userAccount) {
    isTrial = new Date() < new Date(data.userAccount.trialEnd);
    isAccountEnabled = isTrial || data.userAccount.isPaid;
  }
  const annualBudget = getAnnualBudgetFromAppData(data);

  const integrations = calculateAutomaticIntegration(data);

  return {
    calculatedData: {
      committedForecasting,
      annualBudget,
      isTrial,
      isAccountEnabled,
      integrations,
      campaignsMapping: get(data, 'salesforceapi.campaignsMapping', {}),
      fiscalYearFirstMonth: data.fiscalYearFirstMonth,
    },
    ...data,
  };
}

export const extendedData = (data) => {
  const userMonthPlanDataFromState = {
    targetAudience: data.targetAudience && data.targetAudience.length > 0 ? data.targetAudience : [{
      fields: {
        teamSize: null,
        salary: null,
        education: null,
        dailyOnlinePresence: null,
        age: null,
        gender: null,
      },
      info: {
        weight: 100,
      },
    }],
    annualBudget: data.annualBudget || null,
    annualBudgetFiscalCurrent: data.annualBudgetFiscalCurrent || null,
    annualBudgetFiscalNext: data.annualBudgetFiscalNext || null,
    annualBudgetArray: data.annualBudgetArray || [],
    fiscalYearFirstMonth: data.fiscalYearFirstMonth || 0,
    UID: data.UID,
    region: data.region,
    blockedChannels: data.blockedChannels || [],
    forecasterConfig: data.forecasterConfig || {},
    inHouseChannels: data.inHouseChannels || [],
    userMinMonthBudgets: data.userMinMonthBudgets || [],
    maxChannels: data.maxChannels || -1,
    actualIndicators: data.actualIndicators || {},
    campaigns: [],
    campaignsTemplates: data.campaignsTemplates || {},
    campaignIdeas: data.campaignIdeas || [],
    numberOfPlanUpdates: data.numberOfPlanUpdates,
    budget: data.annualBudget,
    events: data.events || [],
    googleapi: data.googleapi,
    hubspotapi: data.hubspotapi,
    facebookapi: data.facebookapi,
    youtubeapi: data.youtubeapi,
    mozapi: data.mozapi,
    salesforceapi: data.salesforceapi,
    marketoapi: data.marketoapi,
    driftapi: data.driftapi,
    linkedinapi: data.linkedinapi,
    twitterapi: data.twitterapi,
    googlesheetsapi: data.googlesheetsapi,
    facebookadsapi: data.facebookadsapi,
    linkedinadsapi: data.linkedinadsapi,
    twitteradsapi: data.twitteradsapi,
    quoraadsapi: data.quoraadsapi,
    adwordsapi: data.adwordsapi,
    bingadsapi: data.bingadsapi,
    sixsenseapi: data.sixsenseapi,
    pricingTiers: data.pricingTiers && data.pricingTiers.length > 0 ? data.pricingTiers : [{
      price: '',
      isMonthly: false,
      weight: 100,
    }],
    planNeedsUpdate: data.planNeedsUpdate,
    notifications: data.notifications || [],
    technologyStack: data.technologyStack || [],
    budgetConstraints: data.budgetConstraints || {},
    planBudgets: [],
    forecastedIndicators: data.forecastedIndicators || [],
    userIndicatorsSchema: data.userIndicatorsSchema,
    userRegionsNicknames: data.userRegionsNicknames,
    expenses: data.expenses || [],
    channelsImpact: data.channelsImpact || {},
    CRMConfig: data.CRMConfig || {},
    siteStructure: data.siteStructure,
    offline: data.offline || [],
    timerMinutes: 0,
    filters: data.filters || [],
    preferences: data.preferences || {},
    budgetType: budgetTypeMapping.fiscalCurrent,
    isCheckAnnual: data.preferences && data.preferences.isCheckAnnual,
    isDivideEqually: data.preferences && data.preferences.isDivideEqually,
    forecastMethod: (data.preferences && data.preferences.forecastMethod) || FORECASTING_METHODS.PACING_FOR,
    pacingMethod: (data.preferences && data.preferences.pacingMethod) || PACING_METHODS.PACING_FOR,
    homePage: data.preferences && data.preferences.homePage,
    currency: data.currency,
    savedFilters: data.savedFilters,
    isSecondaryRegion: data.isSecondaryRegion,
    dashboardConfig: data.dashboardConfig || {},
    marketingAssistedWeight: data.marketingAssistedWeight,
    customFunnels: data.customFunnels,
    accountType: data.accountType,
    paginationCounterUnmappedPage: {
      OFFLINE: 0,
      URLS: 0,
      UTMS: 0,
      ONLINE_CAMPAIGNS: 0,
    },
    revenueFunnel: data.revenueFunnel,
    pipelineFunnel: data.pipelineFunnel,
    businessUnitConnections: data.businessUnitConnections,
    accountViewRegion: data.accountViewConfig?.region,
    isPipelineStageHidden: data.isPipelineStageHidden,
  };

  return { userMonthPlanDataFromState };
};
