import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { ToastContainer, Slide, toast } from 'react-toastify';
import { injectStyle } from 'react-toastify/dist/inject-style';
import history from 'history';
import { Button } from '@infinigrow/libs';

import ButtonWithOptions from 'components/common/ButtonWithOptions';
import ConfirmPopup from 'components/ConfirmPopup';
import Dropdown from 'components/controls/Dropdown';
import CreateNewReportPopup from 'components/pages/reports/CreateNewReportPopup';
import Spinner from 'components/pages/journeys/Spinner';
import SearchInput from 'components/controls/SearchInput';
import FolderActionsPopups from 'components/pages/reports/FolderActionsPopups';
import FiltersPanel from 'components/pages/users/Filters/FilterPanel/FiltersPanel';
import TimeframeDropdown from 'components/common/TimeframeDropdown';

import { makeFilters } from 'components/utils/filters';
import { folderActionsKeys, folderOfUnassignedReports } from 'components/pages/reports/enums';
import { filterTagsColorThemeOptions } from 'components/pages/users/Filters/FilterPanel/enums';

import reportsStyle from 'styles/reports/reports.css';

const styles = reportsStyle.locals;

function ReportsHeaderMenu({
  reportsList,
  selectedReport,
  setSelectedReport,
  deleteReportRequest,
  isReportHasNewChanges,
  discardWidgetsConfigurationChanges,
  saveWidgetsConfigurationChanges,
  isSaveWidgetsReportsLoaded,
  isUpdatingWidgetsReportsLoading,
  hasReportId,
  foldersList,
  updateSearchQuery,
  filtersData,
  updateReportConfiguration,
}) {
  useEffect(() => {
    reportsStyle.use();
    injectStyle();

    return () => {
      reportsStyle.unuse();
    };
  }, []);

  const [folderActionsPopupType, setFolderActionsPopupType] = useState(null);
  const [showConfirmPopup, setShowConfirmPopup] = useState({ isShow: false, message: '' });
  const [isShowCreateNewReportPopup, setIsShowCreateNewReportPopup] = useState({ isShow: false, isRename: false, isDuplicate: false });
  const [showGlobalConfigurationSection, setShowGlobalConfigurationSection] = useState(false);
  const reportId = selectedReport.value;

  const onDeleteReport = () => {
    const popupMessage = (
      <>
        {'Are you sure you want to delete '}
        <b>{selectedReport?.label}</b>
        {' report?'}
      </>
    );
    setShowConfirmPopup({ isShow: true, message: popupMessage });
  };

  const onDuplicateReport = () => {
    setIsShowCreateNewReportPopup({ isShow: true, isRename: false, isDuplicate: true });
  };

  const onRenameReport = () => {
    setIsShowCreateNewReportPopup({ isShow: true, isRename: true });
  };

  const actionsOptionsMenu = [
    { label: 'Edit report name', action: onRenameReport },
    { label: 'Duplicate report', action: onDuplicateReport },
    { label: 'Move to folder', action: () => setFolderActionsPopupType(folderActionsKeys.moveToFolder) },
    { label: 'Delete report', action: onDeleteReport },
  ];

  const confirmDeletePopupCallBack = (abortAction) => {
    if (!abortAction) {
      deleteReportRequest({ reportId });
      history.push('/reports');
    }
    setShowConfirmPopup({ isShow: false, message: '' });
  };

  const onChangeReport = (report) => {
    setSelectedReport(report);
    history.push(`/reports?reportId=${report.value}`);
  };

  const closeCreateNewReportPopup = () => {
    setIsShowCreateNewReportPopup({ isShow: false, isRename: false });
  };

  const toastSuccessMessage = (message = 'Changes saved successfully!') => {
    toast.success(message, {
      position: 'bottom-center',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: 'light',
      toastId: message,
      style: { width: '320px', fontSize: '14px' },
    });
  };

  const onSaveWidgetsConfigurationChanges = async () => {
    await saveWidgetsConfigurationChanges({ reportId });
    toastSuccessMessage();
  };

  function updateReportTimeframe({ newTimeframe }) {
    const updatedTimeframeObject = { ...newTimeframe };
    delete updatedTimeframeObject.label;
    updateReportConfiguration({ configuration: { timeframe: updatedTimeframeObject }, reportId });
  }

  function updateReportFilters({ newFilters }) {
    const filtersForRequest = newFilters.map((filter) => ({ data: filter.data, kind: filter.config.kind, isUiOnly: filter.isUiOnly }));
    updateReportConfiguration({ configuration: { filters: filtersForRequest }, reportId });
  }

  let popupTitle = 'Create new report';
  let subTitle;
  let actionText = 'Create report';
  let secondaryText;
  if (isShowCreateNewReportPopup.isRename) {
    popupTitle = 'Edit report name';
    actionText = 'Save';
  } else if (isShowCreateNewReportPopup.isDuplicate) {
    popupTitle = 'Duplicate report';
    subTitle = 'You can always change the report name in the report tab';
    actionText = 'Save';
    secondaryText = 'Back';
  }
  const selectedFolder = foldersList.find((folder) => folder.value === selectedReport.folderId);
  let reportsByFolderOptions = [];
  if (selectedFolder?.value === folderOfUnassignedReports.value) {
    reportsByFolderOptions = reportsList.filter((report) => report.folderId == null);
  } else {
    reportsByFolderOptions = reportsList.filter((report) => report.folderId === selectedFolder?.value);
  }
  const reportsListOptions = [{
    label: selectedFolder?.label || folderOfUnassignedReports.label,
    options: reportsByFolderOptions,
  }];

  return (
    <>
      <div className={styles.headerMenuWrapper}>
        {hasReportId ? (
          <>
            <Dropdown
              options={reportsListOptions}
              selectedKey={selectedReport?.value}
              noOptionsMessage={() => 'No Reports Yet'}
              controlWidth={320}
              onChange={onChangeReport}
              bottomButton={{
                label: 'View all Reports',
                action: () => history.push('/reports'),
                className: styles.viewAllReportsButton,
              }}
              menuListMaxHeight="308px"
            />
            <div className={styles.headerButtons}>
              {isSaveWidgetsReportsLoaded && <Spinner />}
              {isReportHasNewChanges[reportId] && (
                <div
                  onClick={() => discardWidgetsConfigurationChanges({ reportId })}
                  className={`${styles.headerButtonDiscard} ${isUpdatingWidgetsReportsLoading ? styles.disabledDiscard : null}`}
                  disabled={isUpdatingWidgetsReportsLoading}
                >
                  Discard
                </div>
              )}
              <Button
                className={styles.headerButtonSave}
                type="primaryBlue"
                onClick={() => onSaveWidgetsConfigurationChanges()}
                disabled={isUpdatingWidgetsReportsLoading || !isReportHasNewChanges[reportId]}
              >
                Save changes
              </Button>
              <ButtonWithOptions options={actionsOptionsMenu} />
              <div className={styles.globalConfigurationIcon} onClick={() => setShowGlobalConfigurationSection((prev) => !prev)} />
            </div>
          </>
        ) : (
          <>
            <SearchInput
              debounce={0}
              onSearch={(search) => updateSearchQuery({ searchQuery: search })}
              placeholder="Search..."
              classes={{ input: styles.searchInput }}
            />
            <div className={styles.headerButtons}>
              <Button
                className={styles.headerButtonSave}
                type="secondaryBlue"
                onClick={() => setFolderActionsPopupType(folderActionsKeys.createNewFolder)}
              >
                New Folder
              </Button>
              <Button
                className={styles.headerButtonSave}
                type="primaryBlue"
                onClick={() => history.push('/reports/new')}
              >
                New Report
              </Button>
            </div>
          </>
        )}
      </div>
      <ConfirmPopup
        hidden={!showConfirmPopup.isShow}
        callback={confirmDeletePopupCallBack}
        confirmBtn="Delete"
        title={showConfirmPopup.message}
      />
      {showGlobalConfigurationSection ? (
        <div className={styles.globalConfigurationWrapper}>
          <FiltersPanel
            filters={makeFilters(selectedReport.filters || [], filtersData).map((filter) => ({ ...filter, filterTagColorTheme: filterTagsColorThemeOptions.orange }))}
            setFilters={(newFilters) => updateReportFilters({ newFilters })}
            buttonText="Add report filters"
            addFilterButtonClassName={styles.addFilterButton}
          />
          <TimeframeDropdown
            onChange={(newTimeframe) => updateReportTimeframe({ newTimeframe })}
            timeframe={selectedReport.timeframe}
            titlePrefix="Timeframe: "
            setPreviousTimeframe={(newPreviousTimeframe) => {
              updateReportConfiguration({ configuration: { isCompareToPreviousEnabled: newPreviousTimeframe }, reportId });
            }}
            isPreviousTimeframeEnable={selectedReport.isCompareToPreviousEnabled}
            isShowCompareToPrev
          />
        </div>
      ) : null}

      {folderActionsPopupType && (
        <FolderActionsPopups
          updateActionType={(action) => setFolderActionsPopupType(action)}
          type={folderActionsPopupType}
          toastSuccessMessage={toastSuccessMessage}
          selectedFolder={selectedFolder}
          selectedReport={selectedReport}
          isAttachCurrentReportToFolder={false}
        />
      )}

      {isShowCreateNewReportPopup.isShow && (
        <CreateNewReportPopup
          title={popupTitle}
          subTitle={subTitle}
          actionText={actionText}
          secondaryText={secondaryText}
          onClosePopup={closeCreateNewReportPopup}
          isRenameMode={isShowCreateNewReportPopup.isRename}
          isDuplicateMode={isShowCreateNewReportPopup.isDuplicate}
          toastSuccessMessage={toastSuccessMessage}
          folderId={hasReportId ? selectedFolder?.value : null}
          selectedReport={selectedReport}
        />
      )}

      <ToastContainer
        style={{ width: '400px', fontSize: '12px' }}
        position="bottom-center"
        autoClose={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        transition={Slide}
      />
    </>
  );
}

export default inject(
  ({
    reportsStore: {
      reportsList,
      setSelectedReport,
      selectedReport,
      deleteReportRequest,
      isReportHasNewChanges,
      discardWidgetsConfigurationChanges,
      saveWidgetsConfigurationChanges,
      isSaveWidgetsReportsLoaded,
      isUpdatingWidgetsReportsLoading,
      foldersList,
      updateSearchQuery,
      updateReportConfiguration,
    },
    filterStore: {
      filtersData,
    },
  }) => ({
    reportsList,
    setSelectedReport,
    selectedReport,
    deleteReportRequest,
    isReportHasNewChanges,
    discardWidgetsConfigurationChanges,
    saveWidgetsConfigurationChanges,
    isSaveWidgetsReportsLoaded,
    isUpdatingWidgetsReportsLoading,
    foldersList,
    updateSearchQuery,
    filtersData,
    updateReportConfiguration,
  }),
  observer
)(ReportsHeaderMenu);
