import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { cloneDeep } from 'lodash';
import { inject, observer } from 'mobx-react';
import { Button } from '@infinigrow/libs';

import serverCommunication from 'data/serverCommunication';

import useStyles from 'hooks/useStyles';

import Dropdown from 'components/controls/Dropdown';
import Email from 'components/pages/settings/actions/actionPopup/notificationSection/Email';
import Toggle from 'components/controls/Toggle';
import InfoMarker from 'components/pages/InfoMarker';
import Spinner from 'components/pages/journeys/Spinner';

import { notificationTypes, notificationTypeOptions, notificationFrequencyOptions } from 'components/pages/settings/actions/enums';
import { isTriggerRulesAreValid } from 'components/pages/settings/actions/logic/actions';
import { isNotificationDataValid } from 'components/pages/settings/actions/actionPopup/notificationSection/logic/notificationSection';

import userStore from 'stores/userStore';
import servicesStore from 'stores/servicesStore';

import style from 'styles/settings/actions/actionPopup.css';

const styles = style.locals || {};

function NotificationSection({
  notifications,
  setNotifications,
  triggerRules,
  isActionsAreValid,
  setIsActionsAreValid,
  triggerData,
  setTriggerData,
  isEnableMultipleNotifications = false,
  integrationsConfig,
  updateIntegrationsConfig,
}) {
  useStyles([style]);

  const [isLoadingSlackIntegrationUrl, setIsLoadingSlackIntegrationUrl] = useState(false);
  const [slackChannels, setSlackChannels] = useState({
    channels: null,
    isLoading: false,
  });

  useEffect(() => {
    const isNewNotificationDataValid = isNotificationDataValid({ notificationData: notifications, triggerFrequency: triggerData.frequency });
    const isNewTriggerRulesAreValid = isTriggerRulesAreValid({ triggerType: triggerData.triggerType, triggerRules });

    const isNewActionDataValid = isNewNotificationDataValid && isNewTriggerRulesAreValid;
    if (isNewActionDataValid !== isActionsAreValid) {
      setIsActionsAreValid(isNewActionDataValid);
    }

    const isHavingSlackNotification = notifications.some((notification) => notification.notificationType === notificationTypes.slack);
    if (isHavingSlackNotification && integrationsConfig?.slack?.isConnected && !slackChannels.channels) {
      requestSlackChannels();
    }
  }, [triggerRules, triggerData, notifications, integrationsConfig]);

  async function requestSlackChannels() {
    setSlackChannels((prev) => ({ ...prev, isLoading: true }));
    try {
      const serverResponse = await serverCommunication.serverRequest('POST', 'integrations/subscriptionList', JSON.stringify({ platform: 'slack' }), userStore.userMonthPlan.region);
      const subscriptionList = await serverResponse.json();
      setSlackChannels((prev) => ({ ...prev, channels: subscriptionList }));
    } catch (exception) {
      servicesStore.logger.error('failed to set slack channels from the server', {
        UID: userStore.userMonthPlan.UID,
        region: userStore.userMonthPlan.region,
        exception,
      });
    }

    setSlackChannels((prev) => ({ ...prev, isLoading: false }));
  }

  function updateActionData({ actionIndex, actionKey, actionValue }) {
    const updatedActionData = cloneDeep(notifications);
    updatedActionData[actionIndex][actionKey] = actionValue;

    if (actionKey === 'notificationType') {
      updatedActionData[actionIndex].recipients = null;
    }
    const isNewActionDataValid = isNotificationDataValid({ notificationData: updatedActionData, triggerFrequency: triggerData.frequency });
    if (isNewActionDataValid !== isActionsAreValid) {
      setIsActionsAreValid(isNewActionDataValid);
    }
    setNotifications(updatedActionData);
  }

  function addNewAction() {
    const updatedActionsData = [...notifications];
    updatedActionsData.push({});
    setNotifications(updatedActionsData);
  }

  function removeAction({ actionIndex }) {
    const updatedActions = [...notifications];
    updatedActions.splice(actionIndex, 1);
    setNotifications(updatedActions);
  }

  async function integrateSlackRequest() {
    setIsLoadingSlackIntegrationUrl(true);
    try {
      const response = await serverCommunication.serverRequest('GET', 'slackapi');
      const integrateSlackUrl = await response.json();
      const slackIntegrationWindow = window.open(integrateSlackUrl);
      const timer = setInterval(async () => {
        if (slackIntegrationWindow.closed) {
          clearInterval(timer);
          const code = localStorage.getItem('code');
          if (!code) {
            return;
          }
          localStorage.removeItem('code');
          await serverCommunication.serverRequest('POST', 'slackapi', JSON.stringify({ code }), userStore.userMonthPlan.region);
          updateIntegrationsConfig({ newIntegrationsConfig: { slack: { isConnected: true } } });
        }
      }, 1000);
    } catch (exception) {
      servicesStore.logger.error('failed to set slack integration url from the server', {
        UID: userStore.userMonthPlan.UID,
        region: userStore.userMonthPlan.region,
        exception,
      });
    }
    setIsLoadingSlackIntegrationUrl(false);
  }

  return (
    <>
      {notifications?.map((notification, actionIndex) => {
        const notificationIndex = `notification${actionIndex}`;
        return (
          <div className={styles.ruleWrapper} key={notificationIndex}>
            <div className={styles.row}>
              <div className={classnames(styles.text, styles.titleMinWidth)}>
                Alert type
              </div>
              <Dropdown
                selectedKey={notification.notificationType}
                options={notificationTypeOptions}
                onChange={(newActionType) => updateActionData({
                  actionIndex,
                  actionKey: 'notificationType',
                  actionValue: newActionType.value,
                })}
                controlWidth={160}
                placeholder="Select"
              />

              {(notification.notificationType === notificationTypes.slack && !integrationsConfig?.slack?.isConnected) ? (
                <Button
                  type="secondaryBlue"
                  onClick={() => integrateSlackRequest()}
                  containerClassName={styles.slakeIntegrationButton}
                >
                  <div className={styles.slackIntegrationIcon} />
                  Integrate Slack
                </Button>
              ) : null}

              {isLoadingSlackIntegrationUrl ? (<Spinner />) : null}

              {notifications.length > 1 ? (
                <div
                  data-testid="trashIcon"
                  className={styles.trashIcon}
                  onClick={() => removeAction({ actionIndex })}
                />
              ) : null}
            </div>

            <div className={styles.row}>
              <div className={classnames(styles.text, styles.titleMinWidth)}>
                Frequency
                <InfoMarker tooltipText="How often at most you’ll receive an alert." />
              </div>
              <Toggle
                options={notificationFrequencyOptions}
                selectedValue={triggerData.frequency}
                onClick={(value) => setTriggerData({ frequency: value })}
              />
            </div>

            {notification.notificationType === notificationTypes.email ? (
              <Email
                emailData={notification}
                updateEmailData={({ actionKey, actionValue }) => updateActionData({
                  actionIndex,
                  actionKey,
                  actionValue,
                })}
              />
            ) : null}

            {notification.notificationType === notificationTypes.slack ? (
              <div className={styles.row}>
                <div className={classnames(styles.text, styles.titleMinWidth)}>
                  Channel
                </div>
                <Dropdown
                  onChange={({ value }) => updateActionData({
                    actionIndex,
                    actionKey: 'recipients',
                    actionValue: [{
                      channel: value,
                      name: slackChannels.channels?.find((slackChannel) => slackChannel.channelId === value).channelName,
                    }],
                  })}
                  options={slackChannels.channels?.map((slackChannel) => ({ value: slackChannel.channelId, label: slackChannel.channelName })) || []}
                  selectedKey={notification.recipients?.[0]?.channel}
                  controlWidth={160}
                  disabled={!integrationsConfig?.slack?.isConnected || slackChannels.isLoading}
                  isSearchable
                />

                {slackChannels.isLoading ? (<Spinner />) : null}
              </div>
            ) : null}

          </div>
        );
      })}
      {isEnableMultipleNotifications ? (
        <Button
          dataTestId="addNotification"
          type="secondaryBlue"
          disabled={!isActionsAreValid}
          onClick={() => addNewAction()}
        >
          + AND
        </Button>
      ) : null}
    </>
  );
}

export default inject(
  ({
    userStore: {
      integrationsConfig,
      updateIntegrationsConfig,
    } = {},
  }) => ({
    integrationsConfig,
    updateIntegrationsConfig,
  }),
  observer
)(NotificationSection);
