import React, { useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import uniqueId from 'lodash/uniqueId';
import { Button } from '@infinigrow/libs';

import useStyles from 'hooks/useStyles';

import MenuButton from 'components/common/MenuButton';
import Popup from 'components/Popup';
import MultiCheckSelect from 'components/controls/MultiCheckSelect';
import ContainsSelect from 'components/pages/users/Filters/FilterPanel/UI/ContainsSelect';
import EllipsisTooltip from 'components/controls/EllipsisTooltip';
import Tooltip from 'components/controls/Tooltip';

import { CONTAINS } from 'components/utils/filters';
import { errorIndicationStatuses } from 'components/common/enums';
import { getFilterRemoveIconColor } from 'components/pages/users/Filters/FilterPanel/logic/filterMenuButton';
import { getFilterTagTooltip } from 'components/pages/users/Filters/FilterPanel/logic/FilterTag';
import { getFilterErrorTooltip } from 'components/pages/settings/quickFilters/logic/quickFilters';

import filterStyles from 'styles/users/filters.css';

const styles = filterStyles.locals || {};

export default function FilterMenuButton({
  title,
  filterKind,
  filtersConfig,
  filterData,
  type,
  leftIcon,
  selectedOptions,
  withArrowIndication,
  tooltip,
  disabled,
  dataTestId,
  isShowError,
  tagId,
  options,
  className,
  onApply,
  getFilterKindOptions,
  onOpenFilter,
  onRemoveFilter,
  typeUI,
}) {
  useStyles([filterStyles]);

  const [isOpen, setIsOpen] = useState(false);
  const [userSelectedOptions, setUserSelectedOptions] = useState(selectedOptions);

  useEffect(() => {
    setUserSelectedOptions(selectedOptions);
  }, [selectedOptions]);

  function onChangeContainsValues(values = []) {
    const containsItems = values.map((item) => item.label);
    setUserSelectedOptions(containsItems);
  }

  function applyCheckedOptions() {
    setIsOpen(false);
    onApply({ newSelectedOptions: userSelectedOptions });
  }

  function applyContainsValues() {
    setIsOpen(false);
    onApply({ newSelectedOptions: userSelectedOptions });
  }

  function onCancel() {
    setUserSelectedOptions(selectedOptions);
    setIsOpen(false);
  }

  function onOpenQuickFilter() {
    setIsOpen(true);

    if (onOpenFilter) {
      onOpenFilter();
    }
  }

  function onClickRemoveFilter({ event }) {
    event.stopPropagation();
    onApply({ newSelectedOptions: [] });
    setUserSelectedOptions([]);

    if (onRemoveFilter) {
      onRemoveFilter();
    }
  }

  const config = filtersConfig.find((c) => c.kind === filterKind);
  const filterKey = config.isSearchable ? filterData.fieldId || config.fieldKey[filterData.fieldIndex] : '';

  const isContainsSelected = filterData.comparisonOperator === CONTAINS;

  const isSelectedValues = selectedOptions.length > 0;

  const buttonTooltip = useMemo(() => {
    if (selectedOptions.length > 1) {
      const selectedOptionsLabels = selectedOptions.map((item) => item?.label || item);
      return getFilterTagTooltip(selectedOptionsLabels, filterKind);
    }
    return title;
  }, [title, selectedOptions]);

  return (
    <div className={styles.filterMenuButtonWrapper}>
      <MenuButton
        title={(
          <div className={styles.filterLabelContainer}>
            {leftIcon || null}
            {selectedOptions.length > 1 ? (
              <Tooltip
                id={uniqueId('quick-filter-tooltip-')}
                tip={buttonTooltip}
              >
                <div className={styles.filterTagLabel}>{title}</div>
              </Tooltip>
            ) : (
              <EllipsisTooltip text={buttonTooltip}>
                {title}
              </EllipsisTooltip>
            )}
          </div>
        )}
        className={classnames(isSelectedValues ? styles[typeUI] : '', className)}
        withArrowIndication={withArrowIndication}
        isOpen={isOpen}
        onClick={() => onOpenQuickFilter()}
        rightIconRerender={isSelectedValues ? (
          <div
            data-testid="filter-remove-icon"
            className={styles.removeIcon}
            onClick={(event) => onClickRemoveFilter({ event })}
            style={{ background: getFilterRemoveIconColor({ isSelectedValues, type: typeUI }) }}
          />
        ) : null}
        tooltip={type === errorIndicationStatuses.error ? getFilterErrorTooltip() : tooltip}
        disabled={disabled}
        dataTestId={dataTestId}
        isShowError={isShowError}
        tagId={tagId}
      />
      {isOpen ? (
        <Popup className={styles.filterButtonPopup} onClose={() => onCancel()}>
          <div className={styles.popupContainer}>
            {isContainsSelected ? (
              <div className={styles.containsSelectContainer} data-testid="quick-filter-contains">
                <ContainsSelect
                  controlWidth={385}
                  containsValue={userSelectedOptions.map((item) => ({
                    label: item, value: item,
                  }))}
                  containsValueHandler={(values) => onChangeContainsValues(values)}
                />
              </div>
            ) : (
              <MultiCheckSelect
                className={styles.optionSelect}
                selected={userSelectedOptions}
                options={options}
                placeholder="Search item..."
                onChange={(item) => setUserSelectedOptions(item)}
                selectAll
                isAsyncPagination={config.isSearchable}
                searchFunction={(searchValue, offset) => getFilterKindOptions({
                  searchValue, filterKind: config.kind, filterKey, offset,
                })}
              />
            )}
            <div className={styles.footer}>
              <Button
                type="secondaryBlue"
                onClick={() => onCancel()}
              >
                Cancel
              </Button>
              <Button
                type="primaryBlue"
                disabled={!userSelectedOptions.length}
                onClick={() => (isContainsSelected ? applyContainsValues() : applyCheckedOptions())}
              >
                Apply
              </Button>
            </div>
          </div>
        </Popup>
      ) : null}
    </div>
  );
}
