import isNil from 'lodash/isNil';

import userStore from 'stores/userStore';

import { questionTextType, suggestionsDropdownTypes } from 'components/pages/questions/enums';
import { getParseDateRangeLabel } from 'stores/analyze/logic/timeUtils';
import { getNickname as getChannelNickname } from 'components/utils/channels';
import { getSegmentLabel } from 'components/pages/analyze/SegmentsTab/logic/segments';
import { FREQUENCY_MAP } from 'components/utils/frequency';
import { budgetChangeOperatorsOptions, timeFrameOptions } from 'components/widgets/whatIf/enums';
import { getBaseFunnelAndKpiStages } from 'components/widgets/whatIf/logic/whatIfScenarios';
import { getCostPerAndROIMetricsOptions } from 'components/pages/analyze/OverviewTab/logic/HistoricalPerformance';

export function splitSuggestionTextToObjects({ text = '', placeHoldersTypes = {}, placeHoldersValues = {} }) {
  const splitText = [];
  const wordsArray = text.split(' ');

  let updatedTextValue = '';
  for (const word of wordsArray) {
    if (word.includes('placeHolder')) {
      splitText.push({ text: updatedTextValue, type: questionTextType });
      const placeholderName = word.endsWith('}') ? word.slice(1, -1) : word.slice(1, -2);
      const placeholderType = placeHoldersTypes[placeholderName] || questionTextType;
      const placeholderValue = isNil(placeHoldersValues[placeholderName]) ? placeholderName : placeHoldersValues[placeholderName];
      splitText.push({ text: placeholderValue, type: placeholderType, placeholderName });
      updatedTextValue = word.endsWith('}') ? '' : `${word.slice(-1)} `;
      continue;
    }
    updatedTextValue = updatedTextValue.concat(`${word} `);
  }

  splitText.push({ text: updatedTextValue.trim(), type: questionTextType });
  return splitText;
}

export function updateTextAccordingPlaceHolderType({ text, placeHolderType, placeHolderValue }) {
  const typesThatRequireTextChange = [suggestionsDropdownTypes.kpiFocus];
  if (!typesThatRequireTextChange.includes(placeHolderType)) {
    return text;
  }

  const metricType = userStore.getMetricType({ metric: placeHolderValue });
  if (metricType === 'funnelStage') {
    return 'How many';
  }

  if (metricType === 'revenue') {
    return 'How much';
  }

  if (metricType === 'velocity' || metricType === 'conversionRate') {
    return 'What was the';
  }

  return text;
}

export function getPlaceHolderLabel({ placeHolderType, placeHolderValue }) {
  const metricsTypes = [suggestionsDropdownTypes.funnels, suggestionsDropdownTypes.kpiFocus, suggestionsDropdownTypes.conversionRateOrVelocity, suggestionsDropdownTypes.conversionRate, suggestionsDropdownTypes.velocity];
  if (metricsTypes.includes(placeHolderType)) {
    return userStore.getMetricNickname({ metric: placeHolderValue });
  }

  if (placeHolderType === suggestionsDropdownTypes.timeframe) {
    return getParseDateRangeLabel({ timeFrame: placeHolderValue }).toLowerCase();
  }

  if (placeHolderType === suggestionsDropdownTypes.whatIfTimeframes) {
    return timeFrameOptions.find((option) => option.value === placeHolderValue.value).label;
  }

  if (placeHolderType === suggestionsDropdownTypes.customFields) {
    const customFieldLabel = userStore.userAccount.customFieldsIdToLabelMap[placeHolderValue.id];
    return `${customFieldLabel} ${placeHolderValue.value}`;
  }

  if (placeHolderType === suggestionsDropdownTypes.leadSources) {
    return userStore.userAccount.leadSourcesIdToLabelMap[placeHolderValue];
  }

  if ([suggestionsDropdownTypes.channels, suggestionsDropdownTypes.content, suggestionsDropdownTypes.contentTypes].includes(placeHolderType)) {
    return getChannelNickname(placeHolderValue);
  }

  if (placeHolderType === suggestionsDropdownTypes.segment) {
    return getSegmentLabel({ segment: placeHolderValue, customFieldsIdToLabelMap: userStore.userAccount.customFieldsIdToLabelMap, customUtmsWhitelist: userStore.userAccount.customUtmsWhitelist });
  }

  if (placeHolderType === suggestionsDropdownTypes.frequency) {
    return FREQUENCY_MAP[placeHolderValue];
  }

  if (placeHolderType === suggestionsDropdownTypes.direction) {
    return budgetChangeOperatorsOptions.find((option) => option.value === placeHolderValue).label;
  }

  if (placeHolderType === suggestionsDropdownTypes.whatIfKpiFocus) {
    const { kpiStages } = getBaseFunnelAndKpiStages();
    return kpiStages.find((stage) => stage.value === placeHolderValue).label;
  }

  if (placeHolderType === suggestionsDropdownTypes.costs) {
    const costPerAndROIMetricsOptions = getCostPerAndROIMetricsOptions();
    return costPerAndROIMetricsOptions.find((option) => option.value === placeHolderValue).label;
  }

  return placeHolderValue;
}

export function getWidgetTitle({ questionTextArray }) {
  const titleArray = [];

  for (const text of questionTextArray) {
    if (text.type === questionTextType) {
      titleArray.push(text.text);
      continue;
    }
    titleArray.push(getPlaceHolderLabel({ placeHolderType: text.type, placeHolderValue: text.text }));
  }

  return titleArray.join(' ');
}
