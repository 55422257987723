import { get, cloneDeep, isEqual } from 'lodash';
import { toast } from 'react-toastify';

import questionsStore from 'stores/questions/questionsStore';
import reportsStore from 'stores/reports/reportsStore';

import { widgetsConfig, unifyKeyByWidgetType } from 'components/pages/analyze/enums';
import { folderOfUnassignedReports } from 'components/pages/reports/enums';
import { filterTagsColorThemeOptions } from 'components/pages/users/Filters/FilterPanel/enums';
import { TIMEFRAME_VALUES } from 'components/utils/timeframe';
import { areFiltersEqual } from 'components/utils/filters/logic/index';

export function getComponentAccordingWidgetType({ widgetType }) {
  for (const widgetConfig of Object.values(widgetsConfig)) {
    if (widgetConfig.type === widgetType) {
      return widgetConfig.wrapperComponent;
    }
  }
  return null;
}

export function getComponentProps({
  widgetData, reportGlobalConfiguration, isInReports, isShowTimeframeSelect,
}) {
  const isLoaded = Object.values(widgetData.isLoaded || {}).every((loaded) => loaded);
  const clonedWidgetData = cloneDeep(widgetData);
  delete clonedWidgetData.configuration;
  const reportGlobalFilters = reportGlobalConfiguration?.filters;
  const widgetFilters = widgetData.configuration.filters;
  const widgetId = widgetData.id;
  const reportId = widgetData.reportId;

  let widgetHeaderConfig = {
    filters: [...widgetFilters, ...(reportGlobalFilters?.map((filter) => ({ ...filter, isDisabled: true, filterTagColorTheme: filterTagsColorThemeOptions.orange })) || [])],
    title: widgetData.title,
    widgetId: widgetData.id,
    reportId,
    indicator: widgetData.configuration.indicator || widgetData.configuration.conversionIndicator,
    attributionModel: widgetData.configuration.attributionModel,
    displayedMetrics: widgetData.configuration.displayedMetrics,
    timeFrame: widgetData.configuration.timeFrame,
    isInReports,
    isShowTimeframeSelect,
    isReadOnly: !isInReports,
    isShowFilterOptions: true,
    isShowAttributionModelOptions: true,
    isCompareToPreviousEnabled: widgetData.configuration.isCompareToPreviousEnabled,
    description: widgetData.description,
  };

  const widgetHeaderProps = {
    isShowFilterContainer: true,
    isShowTimeframeSelect: true,
  };

  if (isInReports) {
    widgetHeaderProps.isShowWidgetDescription = true;
    widgetHeaderProps.isShowAttributionModel = true;
    widgetHeaderProps.isInReports = true;
    widgetHeaderProps.updateWidgetDescription = ({ description }) => reportsStore.renameWidgetDescriptionRequest({ description, reportId, widgetId });
    widgetHeaderProps.updateWidgetTitle = ({ title }) => reportsStore.renameWidgetRequest({ title, reportId, widgetId });
    widgetHeaderProps.updateWidgetConfig = ({ configKey, configValue }) => reportsStore.updateWidgetConfig({
      configKey, configValue, reportId, widgetId,
    });
  }

  if (!isInReports) {
    widgetHeaderConfig = {
      ...widgetHeaderConfig,
      ...widgetData.configuration,
      type: widgetData.type,
      filters: widgetFilters,
    };

    widgetHeaderProps.updateWidgetConfig = ({ configKey, configValue }) => questionsStore.updateWidgetConfig({
      configKey, configValue, questionId: widgetData.questionId,
    });
  }

  return {
    ...clonedWidgetData,
    ...widgetData.configuration,
    isLoaded,
    widgetConfig: {
      ...widgetData.configuration,
      filters: [...widgetFilters, ...(reportGlobalFilters || [])],
    },
    widgetHeaderConfig,
    widgetHeaderProps,
    isCompareToPreviousEnabled: widgetData.configuration.isCompareToPreviousEnabled,
    isInReports,
  };
}

export const parseWidgetFiltersToFiltersConfig = (widgetFilters = []) => {
  const filters = widgetFilters.map((filter) => {
    const isUiOnly = get(filter, ['isUiOnly'], false);
    return ({
      isUiOnly,
      config: {
        kind: filter.kind || filter.config?.kind,
      },
      data: filter.data,
    });
  });
  return filters;
};

export function generateUniqueCopyLabel({ originalLabel, existingLabels }) {
  let copyLabel = `Copy of ${originalLabel}`;
  while (existingLabels.includes(copyLabel)) {
    copyLabel = `Copy of ${copyLabel}`;
  }
  return copyLabel;
}

export function toastMessage({ type, message, style }) {
  const options = {
    position: 'bottom-center',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    theme: 'light',
    toastId: message,
    style: style || { width: '320px', fontSize: '14px' },
  };
  if (type === 'error') {
    toast.error(message, options);
  }
  if (type === 'success') {
    toast.success(message, options);
  }
}

function sortReportsByDate(reportA, reportB) {
  const aTimestamp = new Date(reportA.updatedDate).getTime() || 0;
  const bTimestamp = new Date(reportB.updatedDate).getTime() || 0;

  return bTimestamp - aTimestamp;
}

export function parseReportsByFoldersKey({ folders = [], reportsList = [] }) {
  const reportsByFolders = {};
  for (const folder of folders) {
    let reports = [];

    if (folder.value === folderOfUnassignedReports.value) {
      reports = reportsList.filter((report) => report.folderId == null);
    } else {
      reports = reportsList.filter((report) => report.folderId === folder.value);
    }

    reportsByFolders[folder.value] = reports.sort(sortReportsByDate);
  }
  return reportsByFolders;
}

function sortUnassignedFolderToEnd(arr) {
  return arr.sort((a, b) => {
    if (a.value === folderOfUnassignedReports.value) { return 1; }
    if (b.value === folderOfUnassignedReports.value) { return -1; }

    return a.value.localeCompare(b.value);
  });
}

export function searchFoldersAndReports({ searchValue, folders, reports }) {
  if (searchValue === '') {
    return ({ foldersResults: folders, reportsResults: reports });
  }

  const foldersResults = [];
  const reportsResults = [];
  const matchedFolders = folders.filter((folder) => folder.label.toLowerCase().includes(searchValue.toLowerCase()));

  if (matchedFolders.length > 0) {
    foldersResults.push(...matchedFolders);
    for (const matchedFolder of matchedFolders) {
      const reportsOfMatchedFolder = reports.filter((report) => report.folderId === matchedFolder.value || report.folderId === folderOfUnassignedReports.value);
      if (reportsOfMatchedFolder.length > 0) {
        reportsResults.push(...reportsOfMatchedFolder);
      }
    }
  }
  const matchedReports = reports.filter((report) => report.label.toLowerCase().includes(searchValue.toLowerCase()));
  if (matchedReports.length > 0) {
    for (const matchedReport of matchedReports) {
      const reportAlreadyAdded = reportsResults.some((report) => report.value === matchedReport.value);
      if (!reportAlreadyAdded) {
        reportsResults.push(matchedReport);
      }
      const foldersOfMatchedReport = folders.find((folder) => folder.value === matchedReport.folderId || folder.value === folderOfUnassignedReports.value);
      if (foldersOfMatchedReport) {
        const folderAlreadyAdded = foldersResults.some((folder) => folder.value === foldersOfMatchedReport.value);
        if (!folderAlreadyAdded) {
          foldersResults.push(foldersOfMatchedReport);
        }
      }
    }
  }
  const sortFoldersResults = sortUnassignedFolderToEnd(foldersResults);

  return { foldersResults: sortFoldersResults, reportsResults };
}

export function unifyWidgetWithTheSameConfig({ widgets }) {
  const unifyWidgets = [];
  const sortedWidgets = widgets.sort((a, b) => a.orderNumber - b.orderNumber);

  for (const widget of sortedWidgets) {
    const clonedWidgetData = cloneDeep(widget);
    const unifyKeys = unifyKeyByWidgetType[widget.type];
    if (!unifyKeys) {
      unifyWidgets.push(clonedWidgetData);
      continue;
    }

    const { configuration } = clonedWidgetData;
    const { timeFrame, filters } = configuration;
    let widgetFound = false;
    for (const unifyWidget of unifyWidgets) {
      const { configuration: unifyConfig } = unifyWidget;
      const { timeFrame: unifyTimeFrame, filters: unifyFilters, attributionModel: unifyAttributionModel } = unifyConfig;

      let isTimeFrameEqual = timeFrame.value === unifyTimeFrame.value;
      if (timeFrame.value === TIMEFRAME_VALUES.rolling) {
        isTimeFrameEqual = timeFrame.rollingValue === unifyTimeFrame.rollingValue;
      }
      if (timeFrame.value === TIMEFRAME_VALUES.custom) {
        isTimeFrameEqual = isEqual(timeFrame, unifyTimeFrame);
      }

      const isFiltersEqual = areFiltersEqual({ firstFiltersArray: filters, secondFiltersArray: unifyFilters });
      const isAttributionModelEqual = isEqual(configuration.attributionModel, unifyAttributionModel);

      if (widget.type === unifyWidget.type && isTimeFrameEqual && isFiltersEqual && isAttributionModelEqual) {
        for (const unifyKey of unifyKeys) {
          if (!unifyConfig[unifyKey]) {
            continue;
          }
          unifyConfig[unifyKey] = Array.from(new Set([...unifyConfig[unifyKey], ...configuration[unifyKey]]));
        }
        unifyWidget.unifyIds = Array.from(new Set([...unifyWidget.unifyIds, widget.id]));
        widgetFound = true;
      }
    }

    if (!widgetFound) {
      clonedWidgetData.unifyIds = [widget.id];
      unifyWidgets.push(clonedWidgetData);
    }
  }

  return unifyWidgets;
}
