import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { inject, observer } from 'mobx-react';
import { isEmpty, isEqual } from 'lodash';

import useStyles from 'hooks/useStyles';

import Dropdown from 'components/controls/Dropdown';
import MultiCheckSelect from 'components/controls/MultiCheckSelect';
import Toggle from 'components/controls/Toggle';

import { filterKinds } from 'components/utils/filters/logic';
import {
  toggleOptionsByFilterKind,
  supportedConfigByFilterKind,
  comparisonOperatorOptions,
  numericComparisonOperatorOptions,
} from 'components/pages/settings/quickFilters/enums';
import { accountsFunnelStageOption, allFunnelStagesOption } from 'components/utils/filters/enums';
import { includeVariants, funnelStagesVariants } from 'components/utils/filters';
import { getSelectedFunnelsToFilter, getExtraFieldOptionsByFilterKind, getSelectedFunnels } from 'components/pages/settings/quickFilters/logic/quickFilters';

import style from 'styles/settings/quickFilters/quickFilterPopup.css';

const styles = style.locals || {};

function FilterLogicSection({
  filterKind,
  setFilterKind,
  filterLogic = {},
  setFilterLogic,
  funnelsOptions,
  isQuickFilterDisabled,
}) {
  useStyles([style]);

  const [selectedFunnelStageToFilter, setSelectedFunnelStageToFilter] = useState(getSelectedFunnels({ selectedFunnels: filterLogic.selectedFunnels }));

  const extraFieldOptionsByFilterKind = getExtraFieldOptionsByFilterKind({ filterKind });
  const funnelFilterOptions = [
    { label: 'accounts', options: [accountsFunnelStageOption] },
    { label: 'all', options: [allFunnelStagesOption] },
    { label: 'funnels', options: funnelsOptions },
  ];

  useEffect(() => {
    const newSelectedFunnels = getSelectedFunnels({ selectedFunnels: filterLogic.selectedFunnels });
    if (!isEqual(newSelectedFunnels, selectedFunnelStageToFilter)) {
      setSelectedFunnelStageToFilter(newSelectedFunnels);
    }
  }, filterLogic.selectedFunnels);

  function setSelectedFunnelsToFilter({ newFunnelsToFilter }) {
    const funnelsToFilter = getSelectedFunnelsToFilter({ newFunnelsToFilter, currentFunnelsToFilter: selectedFunnelStageToFilter });
    const funnelsToFilterValues = funnelsToFilter.map((funnelToFilter) => funnelToFilter.value);
    let newSelectedFunnels = funnelsToFilterValues;
    if (funnelsToFilterValues.includes('accounts')) {
      newSelectedFunnels = [];
    }

    setFilterLogic({ selectedFunnels: newSelectedFunnels });
    setSelectedFunnelStageToFilter(funnelsToFilter);
  }

  function onUpdateFieldIndex({ newFieldIndex }) {
    const campaignTagFieldIndex = 1;
    if (filterKind === filterKinds.CAMPAIGNS && newFieldIndex === campaignTagFieldIndex) {
      setFilterKind(filterKinds.CAMPAIGN_TAGS);
    }

    setFilterLogic({ fieldIndex: newFieldIndex });
  }

  return (
    <div className={classnames(styles.wrapper, styles.wrapperSpaces)}>
      {supportedConfigByFilterKind.funnelStageSelection.includes(filterKind) ? (
        <>
          <div className={styles.controllerTitle}>I want to filter</div>
          <div className={styles.controllerWrapper}>
            <MultiCheckSelect
              options={funnelFilterOptions}
              isDropDownSelect
              selected={selectedFunnelStageToFilter}
              onChange={(newFunnelsToFilter) => setSelectedFunnelsToFilter({ newFunnelsToFilter })}
              disabled={isQuickFilterDisabled}
              isSearchable={false}
              isClearable={false}
              isSearchIconPlaceholder={false}
              isDropdownIndicator
              isGroupCheckedAsRadio
              isMultiLabelAsText
            />
          </div>
        </>
      ) : null}

      <div className={styles.controllerTitle}>by the following logic</div>
      <div className={styles.controllerWrapper}>
        <Dropdown
          onChange={(newVariant) => setFilterLogic({ variant: newVariant.value })}
          options={filterKind === filterKinds.FUNNEL_STAGES ? funnelStagesVariants : includeVariants}
          selectedKey={filterLogic?.variant}
          disabled={isQuickFilterDisabled}
          isSearchable
        />
      </div>

      {toggleOptionsByFilterKind[filterKind] ? (
        <div className={styles.controllerWrapper}>
          <Toggle
            options={toggleOptionsByFilterKind[filterKind]}
            selectedValue={filterLogic?.fieldIndex}
            onClick={(newFieldIndex) => onUpdateFieldIndex({ newFieldIndex })}
            boxClass={styles.toggleBox}
            disabled={isQuickFilterDisabled}
          />
        </div>
      ) : null}

      {!isEmpty(extraFieldOptionsByFilterKind) ? (
        <Dropdown
          options={extraFieldOptionsByFilterKind}
          selectedKey={filterLogic?.fieldId}
          onChange={(newFieldId) => setFilterLogic({ fieldId: newFieldId.value })}
          disabled={isQuickFilterDisabled}
          isSearchable
        />
      ) : null}

      {supportedConfigByFilterKind.comparisonOperator.includes(filterKind) ? (
        <div className={styles.controllerWrapper}>
          <Dropdown
            onChange={(newComparisonOperator) => setFilterLogic({ comparisonOperator: newComparisonOperator.value })}
            options={supportedConfigByFilterKind.numericComparisonOperators.includes(filterKind) ? [...comparisonOperatorOptions, ...numericComparisonOperatorOptions] : comparisonOperatorOptions}
            selectedKey={filterLogic?.comparisonOperator}
            disabled={isQuickFilterDisabled}
            isSearchable
          />
        </div>
      ) : null}
    </div>
  );
}

export default inject(
  ({
    userStore: {
      funnelsOptions,
    } = {},
  }) => ({
    funnelsOptions,
  }),
  observer
)(FilterLogicSection);
