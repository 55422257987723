exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".quickFilters__filterActionMenu-EOr{background:var(--colorsTextsTextGray);-webkit-mask:url(/assets/header/dots-more.svg) center center no-repeat;mask:url(/assets/header/dots-more.svg) center center no-repeat;-webkit-mask-size:14px;mask-size:14px;height:30px;width:24px;cursor:pointer}.quickFilters__filterButton-3r3{flex-wrap:nowrap}.quickFilters__quickFiltersContainer-Fs8{display:flex;flex-wrap:wrap;gap:12px}.quickFilters__filtersContainer-3U4{display:flex;flex-direction:column;gap:12px;position:relative}.quickFilters__filtersContainer-3U4>div{position:revert}", ""]);

// exports
exports.locals = {
	"filterActionMenu": "quickFilters__filterActionMenu-EOr",
	"filterActionMenu": "quickFilters__filterActionMenu-EOr",
	"filterButton": "quickFilters__filterButton-3r3",
	"filterButton": "quickFilters__filterButton-3r3",
	"quickFiltersContainer": "quickFilters__quickFiltersContainer-Fs8",
	"quickFiltersContainer": "quickFilters__quickFiltersContainer-Fs8",
	"filtersContainer": "quickFilters__filtersContainer-3U4",
	"filtersContainer": "quickFilters__filtersContainer-3U4"
};