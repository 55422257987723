import React from 'react';
import { inject, observer } from 'mobx-react';

import Dropdown from 'components/controls/Dropdown';
import MultiCheckSelect from 'components/controls/MultiCheckSelect';

import { allFunnelStagesOption, accountsFunnelStageOption } from 'components/utils/filters/enums';

import filterStyles from 'styles/users/filters.css';

const styles = filterStyles.locals;

function FilterVariantsWithFunnelStage({
  onChangeVariantHandler,
  defaultIndex = 0,
  variantsOptions,
  onChangeFunnelHandler,
  selectedFunnels,
  funnelsOptions,
}) {
  const getFunnelsOptions = () => [
    accountsFunnelStageOption,
    { label: 'all', options: [allFunnelStagesOption] },
    { label: 'funnels', options: funnelsOptions },
  ];

  return (
    <div>
      <div className={styles.filterHeaderStage}>
        <strong>Find</strong>
        <MultiCheckSelect
          options={getFunnelsOptions()}
          isDropDownSelect
          selected={selectedFunnels}
          onChange={onChangeFunnelHandler}
          placeholder="Accounts"
          controlWidth={175}
          isSearchable={false}
          isClearable={false}
          isSearchIconPlaceholder={false}
          isDropdownIndicator
          isGroupCheckedAsRadio
          isMultiLabelAsText
        />
        <strong>that</strong>
        <Dropdown
          onChange={onChangeVariantHandler}
          options={variantsOptions}
          selectedKey={defaultIndex}
          controlWidth={175}
        />
      </div>
    </div>
  );
}

export default inject(
  ({
    userStore: {
      funnelsOptions,
    },
  }) => ({
    funnelsOptions,
  }),
  observer
)(FilterVariantsWithFunnelStage);
