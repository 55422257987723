import { orderBy, isEqual } from 'lodash';

export const EQUALS = 'EQUALS';
export const NOT_EQUALS = 'NOT_EQUALS';

export const beforeFunnelConversionTooltip = 'Add a constraint to the filter that will only show the filtered results that also converted to a selected funnel stage afterwards.';

export const beforeFunnelConversionTimeframeTooltip = 'Select a custom timeframe for the filtered values. Use Custom Date to filter activities or stage transitions that took place during a custom timeframe, rather than the global timeframe.';

export const VARIANTS = {
  INCLUDE_ANY_OF: 0,
  INCLUDE_ALL: 1,
  DONT_INCLUDE: 2,
  BECAME_ONE_OF: 3,
  INCLUDE_ONLY: 4,
  EVER_BEEN_A: 5,
  NEVER_BEEN_A: 6,
  DONT_INCLUDE_ONLY: 7,
  RECENT_TRANSITION_IS: 8,
  INCLUDE_ANY_OF_EXACT: 9,
  DONT_SHOW_ANY_OF: 10,
  INCLUDE_ENTITIES: 11,
  EXCLUDE_ENTITIES: 12,
  ACCOUNT_STAGE_IS: 13,
};

export const filterKinds = {
  CHANNELS: 'channels',
  FUNNEL_STAGES: 'funnelStages',
  CAMPAIGNS: 'campaigns',
  CAMPAIGN_TAGS: 'campaignTags',
  CUSTOM_FIELDS: 'custom',
  CONTENT: 'content',
  FORMS: 'forms',
  CRMSource: 'CRMSource',
  CHANNELS_BEFORE_STAGES: 'channelBeforeStages',
  CONTENT_BEFORE_STAGES: 'contentBeforeStages',
  CAMPAIGN_BEFORE_STAGES: 'campaignBeforeStages',
  CONTENT_CHANNELS_BEFORE_STAGES: 'contentChannelBeforeStages',
  MARKETING_VS_SALES: 'marketingVsSales',
  PRODUCT: 'product',
  REGION: 'region',
  LEAD_STATUS: 'status',
  PRE_DEFINED: 'preDefined',
  SAVED: 'saved',
  VELOCITY: 'velocity',
  REVENUE: 'revenue',
  CAMPAIGN_COST: 'campaignCost',
  CAMPAIGN_COST_CUSTOM_FIELDS: 'campaignCustomFields',
};

export const filterKindsToBeforeStages = {
  [filterKinds.CHANNELS]: filterKinds.CHANNELS_BEFORE_STAGES,
  [filterKinds.CAMPAIGNS]: filterKinds.CAMPAIGN_BEFORE_STAGES,
  [filterKinds.CONTENT]: filterKinds.CONTENT_BEFORE_STAGES,
  [filterKinds.FUNNEL_STAGES]: filterKinds.FUNNEL_STAGES,
};

export const isCostCampaignsFilter = (kind) => kind === filterKinds.CAMPAIGN_COST;

export const isCostCustomFieldsFilter = (kind) => kind === filterKinds.CAMPAIGN_COST_CUSTOM_FIELDS;

export function areFiltersEqual({ firstFiltersArray, secondFiltersArray }) {
  if (firstFiltersArray.length !== secondFiltersArray.length) {
    return false;
  }

  const sortedFiltersA = getSortedFiltersKeys({ filters: firstFiltersArray });
  const sortedFiltersB = getSortedFiltersKeys({ filters: secondFiltersArray });

  return isEqual(sortedFiltersA, sortedFiltersB);
}

export function getSortedFiltersKeys({ filters }) {
  const sortedFilters = [];
  const orderedFilters = orderBy(filters, 'id');

  for (const filter of orderedFilters) {
    const filterDataKeys = Object.keys(filter.data || {}).sort();
    const filterConfigKeys = Object.keys(filter.config || {}).sort();
    const mappedFilter = {
      id: filter.id,
    };

    for (const filterDataKey of filterDataKeys) {
      let filterDataKeyValue = filter.data[filterDataKey];
      if (Array.isArray(filterDataKeyValue)) {
        filterDataKeyValue = filterDataKeyValue.sort();
      }
      mappedFilter[filterDataKey] = filterDataKeyValue;
    }

    for (const filterDataConfigKey of filterConfigKeys) {
      let filterDataKeyValue = filter.config[filterDataConfigKey];
      if (Array.isArray(filterDataKeyValue)) {
        filterDataKeyValue = filterDataKeyValue.sort();
      }
      mappedFilter[filterDataConfigKey] = filterDataKeyValue;
    }

    sortedFilters.push(mappedFilter);
  }

  return sortedFilters;
}
