export const tabsPaths = {
  aiPrompts: '/insights/ai-prompts',
  channels: '/analyze/channels',
  campaigns: '/analyze/campaigns',
  content: '/analyze/content',
  segments: '/analyze/segments',
  whatIf: '/analyze/what-if',
  optimalJourney: '/analyze/optimal-journey',
  analyzeOverview: '/analyze/overview',
  analyzeJourneys: '/analyze/journeys',
  oppsQuickFilters: '/settings/ops/quickFilters',
};

export const tabsToFeatureFlags = {
  [tabsPaths.aiPrompts]: 'aiPromptTab',
  [tabsPaths.campaigns]: 'headerCampaignsTab',
  [tabsPaths.whatIf]: 'whatIfAnalyzeTab',
  [tabsPaths.optimalJourney]: 'optimalJourneysAnalyzeTab',
  [tabsPaths.oppsQuickFilters]: 'quickFiltersOppsPage',
};
