import { useMemo } from 'react';
import { inject, observer } from 'mobx-react';

import useStyles from 'hooks/useStyles';

import servicesStore from 'stores/servicesStore';
import FilterMenuButton from 'components/pages/users/Filters/FilterPanel/FilterMenuButton';

import { Events } from 'trackers/analytics/enums';
import { quickFiltersCreationTypes, quickFiltersCreationTypesLabel } from 'components/pages/settings/quickFilters/enums';
import {
  getQuickFiltersObjects,
  getQuickFiltersAndDefaultsByTabName,
  getUpdatedQuickFilter, sortQuickFiltersByType,
} from 'components/pages/users/Filters/logic/quickFilters';
import { getFilterEventProperties } from 'components/pages/users/Filters/logic/FilterActions';
import { errorIndicationStatuses } from 'components/common/enums';

import aiIcon from 'assets/ai/purpleAIStars.svg';
import style from 'styles/quickFilters/quickFilters.css';

const styles = style.locals || {};

function QuickFiltersPanel({
  quickFiltersAIRecommendations = [],
  quickFiltersTemplates = [],
  appliedQuickFilters = [],
  setQuickFilters,
  filtersData,
  shouldUseAIRecommendation,
  getFilterKindOptions,
}) {
  useStyles([style]);

  function getAllQuickFilters() {
    const quickFilterTemplatesObjects = getQuickFiltersObjects({
      filters: quickFiltersTemplates,
      filtersConfig: filtersData,
    });

    const aiRecommendationsQuickFilterObjects = shouldUseAIRecommendation
      ? getQuickFiltersObjects({
        filters: quickFiltersAIRecommendations,
        filtersConfig: filtersData,
      }) : [];

    for (const [index, quickFilterTemplateObject] of quickFilterTemplatesObjects.entries()) {
      const quickFilterToUpdate = appliedQuickFilters.find((quickFilter) => quickFilter.id === quickFilterTemplateObject.id);
      if (quickFilterToUpdate) {
        quickFilterTemplatesObjects[index] = quickFilterToUpdate;
      }
    }

    for (const [index, quickFilterTemplateObject] of aiRecommendationsQuickFilterObjects.entries()) {
      const quickFilterToUpdate = appliedQuickFilters.find((quickFilter) => quickFilter.id === quickFilterTemplateObject.id);
      if (quickFilterToUpdate) {
        aiRecommendationsQuickFilterObjects[index] = quickFilterToUpdate;
      }
    }

    const quickFiltersAndDefaultsByTabName = getQuickFiltersAndDefaultsByTabName({
      filters: quickFilterTemplatesObjects,
      pathname: window.location.pathname,
    });

    const allQuickFilters = [...quickFiltersAndDefaultsByTabName, ...aiRecommendationsQuickFilterObjects];
    sortQuickFiltersByType({ quickFilters: allQuickFilters });
    return allQuickFilters;
  }

  const allQuickFilters = useMemo(
    () => getAllQuickFilters(),
    [appliedQuickFilters, quickFiltersAIRecommendations, shouldUseAIRecommendation]
  );

  function onClickOpenFilter({ type }) {
    servicesStore.eventTracker.track({
      eventName: Events.quickFilters.openedQuickFilter,
      properties: {
        quickFilterType: quickFiltersCreationTypesLabel[type],
        quickFiltersCount: allQuickFilters.filter((filter) => filter.active).length,
      },
    });
  }

  function onClickApplyFilter({ filter, newSelectedOptions }) {
    const updatedQuickFilters = [...appliedQuickFilters];
    const currentFilterIndex = appliedQuickFilters.findIndex((quickFilter) => quickFilter.id === filter.id);
    const isFilterUIOnly = filter.filter.isUiOnly;

    if (newSelectedOptions.length === 0) {
      updatedQuickFilters.splice(currentFilterIndex, 1);
      setQuickFilters(updatedQuickFilters, !isFilterUIOnly);
      return;
    }

    const updatedQuickFilter = getUpdatedQuickFilter({ filter, newSelectedOptions, filtersData });
    if (currentFilterIndex >= 0) {
      updatedQuickFilters[currentFilterIndex] = updatedQuickFilter;
    } else {
      updatedQuickFilters.push(updatedQuickFilter);
    }
    setQuickFilters(updatedQuickFilters, !isFilterUIOnly);

    const properties = getFilterEventProperties({ filter: filter.filter });
    servicesStore.eventTracker.track({
      eventName: Events.quickFilters.appliedQuickFilter,
      properties: {
        quickFilterType: quickFiltersCreationTypesLabel[filter.type],
        ...properties,
      },
    });
  }

  function onRemoveFilter({ filter }) {
    servicesStore.eventTracker.track({
      eventName: Events.quickFilters.appliedQuickFilter,
      properties: {
        quickFilterType: quickFiltersCreationTypesLabel[filter.type] || quickFiltersCreationTypesLabel.default,
        isErrorState: filter.type === errorIndicationStatuses.error,
      },
    });
  }

  return (
    <div className={styles.quickFiltersContainer}>
      {allQuickFilters.map((filter) => {
        const leftIcon = filter.typeUI === quickFiltersCreationTypes.ai
          ? <img src={aiIcon} alt="AI Filter" />
          : null;
        return (
          filter.active ? (
            <FilterMenuButton
              key={filter.id}
              title={filter.label}
              filterKind={filter.kind}
              type={filter.type}
              leftIcon={leftIcon}
              selectedOptions={filter.filterSelectedOptions}
              dataTestId={`filter-menu-button-${filter.id}`}
              options={filter.options}
              onApply={({ newSelectedOptions }) => onClickApplyFilter({ filter, newSelectedOptions })}
              filtersConfig={filtersData}
              filterData={filter.filter.data}
              getFilterKindOptions={getFilterKindOptions}
              onOpenFilter={() => onClickOpenFilter({ type: filter.type })}
              onRemoveFilter={() => onRemoveFilter({ filter })}
              typeUI={filter.typeUI}
            />
          ) : null
        );
      })}
    </div>
  );
}

export default inject(
  ({
    attributionStore: {
      appliedQuickFilters,
      setQuickFilters,
    } = {},
    filterStore: {
      filtersData,
      quickFilters: quickFiltersTemplates,
      quickFiltersAIRecommendations,
      shouldUseAIRecommendation,
      getFilterKindOptions,
      updateQuickFilterServerRequest,
    } = {},
  }) => ({
    filtersData,
    appliedQuickFilters,
    setQuickFilters,
    quickFiltersTemplates,
    quickFiltersAIRecommendations,
    shouldUseAIRecommendation,
    getFilterKindOptions,
    updateQuickFilterServerRequest,
  }),
  observer
)(QuickFiltersPanel);
