import { isEmpty } from 'lodash';

import { beforeStageMap, makeFilter } from 'components/utils/filters';
import { checkIfFilterDataExists } from 'components/pages/users/Filters/FilterPanel/logic/FiltersPanel';
import { isCostCampaignsFilter, isCostCustomFieldsFilter } from 'components/utils/filters/logic';

export function getUpdatedFiltersItems({ filtersItems, newFilter, shouldRemoveBeforeFunnelFilter }) {
  const updatedFiltersItems = [...filtersItems];
  const filterIndex = filtersItems.findIndex((item) => item.kind === newFilter.kind);
  const isFilterDataExists = checkIfFilterDataExists(newFilter.data);

  if (isFilterDataExists) {
    if (filterIndex === -1) {
      updatedFiltersItems.push(newFilter);
    } else {
      updatedFiltersItems[filterIndex] = newFilter;
    }
  } else if (filterIndex !== -1) {
    updatedFiltersItems.splice(filterIndex, 1);
  }

  if (shouldRemoveBeforeFunnelFilter) {
    const filterKind = beforeStageMap[newFilter.kind]();
    const filterBeforeStageIndex = updatedFiltersItems.findIndex((item) => item.kind === filterKind);
    if (filterBeforeStageIndex !== -1) {
      updatedFiltersItems.splice(filterBeforeStageIndex, 1);
    }
  }

  return updatedFiltersItems;
}

export function getCombineSelectedFilters({
  filters, newFilters, selectedFilter, configs,
}) {
  let filtersWithoutSelectedFilter = [...filters];
  if (selectedFilter && filters.length > 0) {
    filtersWithoutSelectedFilter = filters.filter((f) => f.id !== selectedFilter.id);
  }

  const notEmptyFilters = newFilters.filter(({ data, kind }) => (data.comparisonOperator || isCostCampaignsFilter(kind) || isCostCustomFieldsFilter(kind) || !isEmpty(data.selectedOptions)));
  const mappedNewFilters = notEmptyFilters.map(({ kind, data }) => makeFilter({ kind, data }, configs)).filter((filterItem) => filterItem && !filtersWithoutSelectedFilter.find((f) => f.id === filterItem.id));
  const isFiltersAreUIOnly = newFilters.every((f) => f.isUiOnly);
  const combinedFilters = [...filtersWithoutSelectedFilter, ...mappedNewFilters];

  return {
    combinedFilters,
    isFiltersAreUIOnly,
  };
}
