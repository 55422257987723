import React, { useState } from 'react';
import classnames from 'classnames';
import uniqueId from 'lodash/uniqueId';

import userStore from 'stores/userStore';
import EllipsisTooltip from 'components/controls/EllipsisTooltip';
import InfoMarker from 'components/pages/InfoMarker';
import Tooltip from 'components/controls/Tooltip';

import useStyles from 'hooks/useStyles';

import { dateFormat, checkIfDate } from 'utils';
import { getChannelNickname } from 'components/utils/filters/channels';
import { getFilterTagTooltip } from 'components/pages/users/Filters/FilterPanel/logic/FilterTag';
import { filterTagsColorThemeOptions } from 'components/pages/users/Filters/FilterPanel/enums';

import filterStyles from 'styles/users/filters.css';

const styles = filterStyles.locals || {};

function FilterTag({
  isUiFilter, onClick, className, onRemove, label, kind = '', selectedOptions = [], tagNumber, isDisabled, colorTheme,
}) {
  const [isTagRemoved, setIsTagRemoved] = useState(false);

  useStyles([filterStyles]);

  const onRemoveTag = () => {
    setIsTagRemoved(true);
    onRemove();
  };

  const dateFilter = (getter, item) => {
    if (checkIfDate(item)) {
      const date = dateFormat(item);
      return getter ? getter(date) : date;
    } else {
      return getter ? getter(item) : item;
    }
  };

  let classNameAccordingColorTheme;
  if (colorTheme === filterTagsColorThemeOptions.orange) {
    classNameAccordingColorTheme = styles.orange;
  }
  if (colorTheme === filterTagsColorThemeOptions.ai) {
    classNameAccordingColorTheme = styles.ai;
  }

  const isMultipleOptions = selectedOptions.length > 0;
  const isChannelFilter = kind === 'channels' || kind === 'channelBeforeStages';
  const isFunnelFilter = kind === 'funnelStages';
  const getter = isChannelFilter ? getChannelNickname : (isFunnelFilter ? (metric) => userStore.getMetricNickname({ metric }) : undefined);
  return (
    <div
      className={classnames(styles.filterTag, isTagRemoved && styles.tagHide, className, classNameAccordingColorTheme, isDisabled && styles.disabledFilters)}
      data-testid={`filter-tag-${label}-${colorTheme || ''}-tagNumber-${tagNumber || '0'}`}
    >
      {tagNumber && <div className={styles.filterTagNumber}>{tagNumber}</div>}
      <div className={classnames(styles.filterLabel, !onClick && styles.filterLabelDisabled, isDisabled && styles.disabledFilters, styles.filterIcon)} onClick={isDisabled ? null : onClick}>
        {isMultipleOptions ? (
          <Tooltip
            id={uniqueId('filter-tooltip-')}
            tip={getFilterTagTooltip(selectedOptions, kind, getter, dateFilter)}
            TooltipProps={{ multiline: true }}
          >
            <div className={styles.filterTagLabel}>{label}</div>
          </Tooltip>
        ) : (
          <EllipsisTooltip
            text={label}
            place="bottom"
            TooltipProps={{ wide: true }}
          />
        )}
      </div>
      {isUiFilter && (
        <InfoMarker
          containerClass={styles.infoMarkerWrapper}
          TooltipProps={{
            className: styles.infoMarkerTip,
          }}
          tooltipText="Note that this filter hides/shows the selected options in specific modules and doesn’t filter the actual journeys"
          dataTestId="info-marker"
        />
      )}
      {onRemove && !isDisabled && <div data-testid="remove-tag" className={styles.filterRemoveButton} onClick={onRemoveTag} />}
    </div>
  );
}
export default FilterTag;
