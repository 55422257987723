import React from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import timeFrameModule from 'modules/timeframe';

import Component from 'components/Component';
import FeatureFlags from 'components/common/FeatureFlags';
import FunnelView from 'components/pages/dashboard/dashboardNavigate/FunnelView';
import Loader from 'components/controls/Loader';
import Metrics from 'components/pages/dashboard/dashboardNavigate/Metrics';
import OffTrackWithButton from 'components/pages/dashboard/dashboardNavigate/OffTrackWithButton';
import Select from 'components/controls/Select';
import SpendVsAttributedChart from 'components/pages/dashboard/dashboardNavigate/SpendVsAttributedChart/index';
import StatSquareWithButton from 'components/pages/dashboard/dashboardNavigate/SpendVsAttributedChart/StatSquareWithButton';
import TopGoalsChart from 'components/pages/dashboard/dashboardNavigate/TopGoalsChart';
import dashboardStore from 'stores/dashboardStore';

import { compose } from 'components/utils/utils';
import { daysInMonth } from 'components/utils/date';
import { TIMEFRAME_VALUES, TIMEFRAME_LABELS } from 'components/utils/timeframe';
import { getFunnelPopupData, NEW_FUNNEL_PREFIX } from 'components/utils/indicators';
import { getNewTitleAccordingDateAndTimeframe } from 'components/pages/plan/logic/plan';
import { getReportSyncStatus } from 'components/pages/createIntegration/logic/CRMConfigFunnel';
import { parsCampaignsCostsForPlanVsActual } from 'stores/plan/logic/planStore';
import { navigateToJourneys } from 'stores/analyze/logic/journeysStore';

import style from 'styles/dashboard/dashboard-navigate.css';

const getSelectOptions = () => [
  {
    label: TIMEFRAME_LABELS.MONTH,
    value: TIMEFRAME_VALUES.MONTH,
    type: TIMEFRAME_VALUES.MONTH,
  },
  {
    label: TIMEFRAME_LABELS.PREV_MONTH,
    value: TIMEFRAME_VALUES.PREV_MONTH,
    type: TIMEFRAME_VALUES.MONTH,
  },
  {
    label: TIMEFRAME_LABELS.QUARTER,
    value: TIMEFRAME_VALUES.QUARTER,
    type: TIMEFRAME_VALUES.QUARTER,
  },
  {
    label: TIMEFRAME_LABELS.PREV_QUARTER,
    value: TIMEFRAME_VALUES.PREV_QUARTER,
    type: TIMEFRAME_VALUES.QUARTER,
  },
  {
    label: TIMEFRAME_LABELS.YEAR,
    value: TIMEFRAME_VALUES.YEAR,
    type: TIMEFRAME_VALUES.YEAR,
  },
  {
    label: TIMEFRAME_LABELS.PREV_YEAR,
    value: TIMEFRAME_VALUES.PREV_YEAR,
    type: TIMEFRAME_VALUES.YEAR,
  },
];

const enhance = compose(
  inject((stores) => {
    const {
      attributionStore,
      userStore: {
        userAccount: {
          customFieldsIdToLabelMap,
        },
      },
    } = stores;

    const {
      timeFrameValue,
      startDate: currentStartDate,
      actualIndicators: dashboardActualIndicators,
      previousTimeFrameActualIndicators,
      totalPipeline,
      totalRevenue,
      channelsSpendVsAttributedImpact,
      campaignsSpendVsAttributedImpact,
      totalActualSpent,
      totalBudgetedAmount,
      totalCampaignsCost,
      totalChannelsCost,
      funnelStagesToTheirGroupByType,
      isLoadingDashboardData,
    } = dashboardStore;
    const { setAutoFilter, autoFilter, isAccountMode } = attributionStore;

    return {
      setAutoFilter,
      autoFilter,
      isAccountMode,
      timeFrameValue,
      currentStartDate,
      dashboardActualIndicators,
      previousTimeFrameActualIndicators,
      totalPipeline,
      totalRevenue,
      channelsSpendVsAttributedImpact,
      campaignsSpendVsAttributedImpact,
      totalActualSpent,
      totalBudgetedAmount,
      totalCampaignsCost,
      totalChannelsCost,
      funnelStagesToTheirGroupByType,
      customFieldsIdToLabelMap,
      isLoadingDashboardData,
    };
  }),
  observer
);

class DashboardNavigate extends Component {
  style = style;

  static defaultProps = {
    channelsSpendVsAttributedImpact: [],
    dashboardActualIndicators: {},
    previousTimeFrameActualIndicators: {},
  };

  constructor(props) {
    super(props);
    const {
      dashboardConfig: {
        funnelMetric1 = `${NEW_FUNNEL_PREFIX}2`,
        funnelMetric2 = `${NEW_FUNNEL_PREFIX}3`,
      },
      timeFrameValue,
    } = props;
    this.state = {
      timeFrame: timeFrameValue,
      funnelMetric1,
      funnelMetric2,
      isLoading: false,
      reportSyncStatus: {},
    };
    this.goalsLimit = 10;
  }

  componentDidMount() {
    getReportSyncStatus({
      region: this.props.region,
      handleResponseData: ({ responseData }) => {
        this.setState({ reportSyncStatus: responseData });
      },
    });

    if (!this.props.dashboardActualIndicators) {
      dashboardStore.setDefaultTimeFrameValue();
    }
  }

  async componentDidUpdate(prevProps) {
    const didAccountModeChanged = this.props.isAccountMode !== prevProps.isAccountMode;

    if (didAccountModeChanged) {
      await this.setAsyncState({ isLoading: true });

      dashboardStore.getDashboardData().then(() => this.setState({ isLoading: false }));
    }
  }

  get isMonthView() {
    return this._isMonthView(this.state.timeFrame);
  }

  get isQuarterView() {
    return this._isQuarterView(this.state.timeFrame);
  }

  get dateTitle() {
    const { currentStartDate, timeFrameValue, fiscalYearFirstMonth } = this.props;
    return getNewTitleAccordingDateAndTimeframe({ startDate: currentStartDate, timeFrame: timeFrameValue, fiscalYearFirstMonth });
  }

  _isMonthView(timeFrame) {
    return [TIMEFRAME_VALUES.MONTH, TIMEFRAME_VALUES.PREV_MONTH].includes(timeFrame);
  }

  _isQuarterView(timeFrame) {
    return [TIMEFRAME_VALUES.QUARTER, TIMEFRAME_VALUES.PREV_QUARTER].includes(timeFrame);
  }

  redirect = (path, data = {}) => {
    const { indicator } = data;
    const {
      setAutoFilter, autoFilter, router, timeFrameValue,
    } = this.props;

    if (indicator) {
      if (!autoFilter) {
        setAutoFilter();
      }
      navigateToJourneys({ indicator, timeFrame: timeFrameValue }, []);
    } else {
      router.push(path);
    }
  };

  metricsData = () => {
    const {
      dashboardActualIndicators, totalPipeline, previousTimeFrameActualIndicators, totalRevenue,
    } = this.props;

    const { funnelMetric1, funnelMetric2 } = this.state;
    return {
      currentTimeFrame: {
        newPipeline: totalPipeline,
        totalRevenue,
        funnelMetric1Value: dashboardActualIndicators[funnelMetric1],
        funnelMetric2Value: dashboardActualIndicators[funnelMetric2],
        marketingPipeline: dashboardActualIndicators.marketingPipeline,
        marketingRevenue: dashboardActualIndicators.marketingRevenue,
      },
      pastData: {
        marketingPipeline: previousTimeFrameActualIndicators.marketingPipeline,
        marketingRevenue: previousTimeFrameActualIndicators.marketingRevenue,
      },
    };
  };

  setAsyncState = (newState) => new Promise((resolve) => { this.setState(newState, () => resolve()); });

  selectTimeFrame = ({ value, type }) => {
    const { dashboardActualIndicators } = this.props;
    const {
      startDate, endDate, prevPeriodStartDate, prevPeriodEndDate, budgetPeriodEndDate,
    } = dashboardStore.getDatesAccordingTimeframeValue(value);

    dashboardStore.setTimeFrameValue({ timeFrameValue: value });
    const newState = {
      timeFrame: type,
    };

    if (dashboardActualIndicators) {
      newState.isLoading = true;
    }

    this.setAsyncState({
      ...newState,
    })
      .then(() => dashboardStore.setTimeFrame({
        startDate, endDate, prevPeriodStartDate, prevPeriodEndDate, budgetPeriodEndDate,
      }))
      .then(() => this.setState({ isLoading: false }));
  };

  render() {
    const {
      timeFrameValue,
      daysOfQuarterTotal,
      daysPassedQuarter,
      daysPassedYear,
      marketingAssistedWeight,
      salesforceapi,
      hubspotapi,
      CRMConfig,
      fiscalYearFirstMonth,
      pacingMethod,
      dashboardConfig: {
        funnelBoardName,
      },
      channelsSpendVsAttributedImpact,
      totalActualSpent,
      totalBudgetedAmount,
      totalCampaignsCost,
      totalChannelsCost,
      flags,
      customFieldsIdToLabelMap,
      funnelStagesToTheirGroupByType,
      isLoadingDashboardData,
    } = this.props;

    if (isLoadingDashboardData) {
      return (
        <div className={this.classes.loader}>
          <Loader newStyle />
        </div>
      );
    }

    const {
      startDate,
      endDate,
    } = timeFrameModule.getTimeframeParams({ value: dashboardStore.timeFrameValue, fiscalYearFirstMonth });

    const { parsedChannels, parsedCampaigns } = parsCampaignsCostsForPlanVsActual({
      campaignsCostsResponse: totalCampaignsCost,
      channelsCostsResponse: totalChannelsCost,
      startDate,
      endDate,
    });

    const daysPassed = this.isMonthView
      ? (new Date().getDate() - 1)
      : this.isQuarterView ? daysPassedQuarter : daysPassedYear;
    const periodLenTotal = this.isMonthView ? daysInMonth() : this.isQuarterView ? daysOfQuarterTotal : 365;

    const { funnelMetric1, funnelMetric2 } = this.state;

    const isPrev = timeFrameValue.includes('prev');

    const { currentTimeFrame, pastData } = this.metricsData();

    const sumPacing = _.sumBy(parsedChannels, 'spending.actual');
    const currentSpendSpeed = sumPacing / daysPassed;
    const forecastedSpend = isPrev ? totalActualSpent : currentSpendSpeed * (periodLenTotal - daysPassed);
    const isOnTrack = totalActualSpent <= totalBudgetedAmount;

    return (
      <div>
        {this.state.isLoading && (
          <div className={this.classes.loader}>
            <Loader newStyle />
          </div>
        )}
        <div className={this.classes.dateRow}>
          <div className={this.classes.currentTimeFrame}>
            {this.dateTitle}
          </div>
          <div className={this.classes.dateRowRight}>
            <Select
              selected={timeFrameValue}
              select={{
                options: getSelectOptions(),
              }}
              onChange={this.selectTimeFrame}
              style={{ width: '150px' }}
            />
          </div>
        </div>
        <div className={classnames(this.classes.rows)}>
          <div className={this.classes.navigateColumn}>
            <Metrics
              containerClass={this.classes.navigateItem}
              currentTimeFrame={currentTimeFrame}
              budget={totalActualSpent}
              funnelMetric1={funnelMetric1}
              funnelMetric2={funnelMetric2}
            />
            <FeatureFlags flag={flags.goalsWidgetDashboard}>
              <TopGoalsChart
                containerClass={this.classes.navigateItem}
                redirect={() => this.redirect('/settings/goals/goals-main')}
                pacingMethod={pacingMethod}
                timeframe={{
                  startDate,
                  endDate,
                  value: dashboardStore.timeFrameValue,
                }}
              />
            </FeatureFlags>
            <div className={this.classes.subRow}>
              <FeatureFlags flag={flags.plannedBudgetVsLeftToInvestDashboard}>
                <StatSquareWithButton
                  classes={this.classes}
                  sumPlannedWithFuture={totalBudgetedAmount}
                  isOnTrack={isOnTrack}
                  sumPacing={forecastedSpend}
                  sumActual={totalActualSpent}
                  redirect={this.redirect}
                  isMonthView={this.isMonthView}
                  hoverContainerClass={this.classes.statSquareWrapper}
                  time={this.dateTitle}
                />
              </FeatureFlags>
              <FeatureFlags flag={flags.offTracksDashboard}>
                <OffTrackWithButton
                  parsedCampsOffTrack={parsedCampaigns}
                  channelsParsed={parsedChannels}
                  classes={this.classes}
                  isPrev={isPrev}
                  redirect={this.redirect}
                  hoverContainerClass={this.classes.offTrackGroup}
                />
              </FeatureFlags>
            </div>
          </div>
          <div className={classnames(this.classes.navigateColumn, this.classes.navigateColumnRight)}>
            <FunnelView
              marketingAssistedWeight={marketingAssistedWeight}
              containerStyle={this.classes.navigateItem}
              currentTimeFrame={currentTimeFrame}
              pastMarketingPipeline={_.get(pastData, 'marketingPipeline')}
              pastMarketingRevenue={_.get(pastData, 'marketingRevenue')}
              fiscalYearFirstMonth={fiscalYearFirstMonth}
              timeIndicator={this.state.timeFrame}
              funnelPopupData={getFunnelPopupData({
                salesforceapi,
                hubspotapi,
                funnelStagesToTheirGroupByType,
                predefinedFiltersConfig: _.get(CRMConfig, 'predefinedFiltersConfig', {}),
                customFieldsIdToLabelMap,
                reportSyncStatus: this.state.reportSyncStatus,
              })}
              titleText={funnelBoardName}
              hoverContainerClass={classnames(this.classes.navigateItem, this.classes.funnel)}
            />
            <FeatureFlags flag={flags.spendVsImpactDashboard}>
              <SpendVsAttributedChart
                classes={this.classes}
                channels={channelsSpendVsAttributedImpact}
                isCurrentMonth={!isPrev}
              />
            </FeatureFlags>
          </div>
        </div>
      </div>
    );
  }
}

export default withLDConsumer()(enhance(DashboardNavigate));
