exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".popupWithDrawerSteps__pageWrapper-3Tu>div>div{width:920px;margin:0 auto;gap:16px}.popupWithDrawerSteps__wrapper-161{display:flex;flex-direction:column;gap:16px}.popupWithDrawerSteps__title-35v{display:flex;gap:8px}.popupWithDrawerSteps__closeButton-2FI{position:absolute;top:12px;right:12px;width:28px;height:28px;background:url(/assets/close-icon-big.svg) 50% no-repeat;cursor:pointer;z-index:10}.popupWithDrawerSteps__row-1NI{width:100%}.popupWithDrawerSteps__innerRow-2dI,.popupWithDrawerSteps__row-1NI{display:flex;align-items:center;gap:12px}.popupWithDrawerSteps__text-3bO{display:flex;gap:4px;align-items:center;color:var(--colorsGeneralIgBlack);font-size:16px;font-weight:400;line-height:20px}.popupWithDrawerSteps__textFieldInput-23z{flex:1 0 0;background:var(--white);color:var(--black);font-size:20px;font-weight:600;width:460px;text-overflow:ellipsis}.popupWithDrawerSteps__footerButtons-3ci{display:flex;justify-content:space-between}.popupWithDrawerSteps__stepBodySection-3Qz{display:flex;padding:20px;flex-direction:column;justify-content:center;align-items:flex-start;gap:20px;align-self:stretch;border-radius:4px;border:1px solid var(--grayLine)}.popupWithDrawerSteps__stepBodySectionOpen-12P{background:var(--lightGrayBackground)}.popupWithDrawerSteps__stepBodyTitleWrapper-1mZ{display:flex;justify-content:space-between;align-items:center;width:100%}.popupWithDrawerSteps__stepBodyTitle-3zM{color:var(--colorsGeneralIgBlack);font-size:16px;font-weight:600;line-height:20px;gap:4px}.popupWithDrawerSteps__stepBodyTitle-3zM:not(.popupWithDrawerSteps__withoutHover-2W5):hover{cursor:pointer}.popupWithDrawerSteps__collapsedTitle-wz0{opacity:.4;display:flex}.popupWithDrawerSteps__checkedIconGreen-2HD{background:url(/assets/indicator-icons/checked-green.svg) 50% no-repeat;background-size:contain;height:16px;width:16px}", ""]);

// exports
exports.locals = {
	"pageWrapper": "popupWithDrawerSteps__pageWrapper-3Tu",
	"pageWrapper": "popupWithDrawerSteps__pageWrapper-3Tu",
	"wrapper": "popupWithDrawerSteps__wrapper-161",
	"wrapper": "popupWithDrawerSteps__wrapper-161",
	"title": "popupWithDrawerSteps__title-35v",
	"title": "popupWithDrawerSteps__title-35v",
	"closeButton": "popupWithDrawerSteps__closeButton-2FI",
	"closeButton": "popupWithDrawerSteps__closeButton-2FI",
	"row": "popupWithDrawerSteps__row-1NI",
	"row": "popupWithDrawerSteps__row-1NI",
	"innerRow": "popupWithDrawerSteps__innerRow-2dI",
	"innerRow": "popupWithDrawerSteps__innerRow-2dI",
	"text": "popupWithDrawerSteps__text-3bO",
	"text": "popupWithDrawerSteps__text-3bO",
	"textFieldInput": "popupWithDrawerSteps__textFieldInput-23z",
	"textFieldInput": "popupWithDrawerSteps__textFieldInput-23z",
	"footerButtons": "popupWithDrawerSteps__footerButtons-3ci",
	"footerButtons": "popupWithDrawerSteps__footerButtons-3ci",
	"stepBodySection": "popupWithDrawerSteps__stepBodySection-3Qz",
	"stepBodySection": "popupWithDrawerSteps__stepBodySection-3Qz",
	"stepBodySectionOpen": "popupWithDrawerSteps__stepBodySectionOpen-12P",
	"stepBodySectionOpen": "popupWithDrawerSteps__stepBodySectionOpen-12P",
	"stepBodyTitleWrapper": "popupWithDrawerSteps__stepBodyTitleWrapper-1mZ",
	"stepBodyTitleWrapper": "popupWithDrawerSteps__stepBodyTitleWrapper-1mZ",
	"stepBodyTitle": "popupWithDrawerSteps__stepBodyTitle-3zM",
	"stepBodyTitle": "popupWithDrawerSteps__stepBodyTitle-3zM",
	"withoutHover": "popupWithDrawerSteps__withoutHover-2W5",
	"withoutHover": "popupWithDrawerSteps__withoutHover-2W5",
	"collapsedTitle": "popupWithDrawerSteps__collapsedTitle-wz0",
	"collapsedTitle": "popupWithDrawerSteps__collapsedTitle-wz0",
	"checkedIconGreen": "popupWithDrawerSteps__checkedIconGreen-2HD",
	"checkedIconGreen": "popupWithDrawerSteps__checkedIconGreen-2HD"
};