import React from 'react';
import classnames from 'classnames';

import useStyles from 'hooks/useStyles';

import Skeleton from 'components/common/Skeleton';

import style from 'styles/questions/ai-answers.css';

const styles = style.locals || {};

function FollowUpQuestionBox({
  content, iconPosition, onClick, isLoaded,
}) {
  useStyles([style]);

  return (
    <div
      onClick={onClick}
      className={classnames(styles.followUpQuestionBox, iconPosition && styles[iconPosition])}
    >
      <div className={styles.answersIcon} />
      {isLoaded ? (
        <div>{content}</div>
      ) : (
        <div className={styles.skeletonFullWidth}><Skeleton /></div>
      )}
    </div>
  );
}

export default FollowUpQuestionBox;
