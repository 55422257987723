import React, { useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import { inject, observer } from 'mobx-react';
import { Checkbox } from '@infinigrow/libs';

import useStyles from 'hooks/useStyles';

import attributionStore from 'stores/attributionStore';
import Dropdown from 'components/controls/Dropdown';
import Tooltip from 'components/controls/Tooltip';
import Toggle from 'components/controls/Toggle';
import CustomDateSelect from 'components/pages/users/Filters/CustomDateSelect';
import InfoMarker from 'components/pages/InfoMarker';

import {
  positionOptions,
  supportedConfigByFilterKind,
  defaultBeforeFunnelConversionValue,
} from 'components/pages/settings/quickFilters/enums';
import { beforeFunnelConversionTooltip, filterKinds, beforeFunnelConversionTimeframeTooltip } from 'components/utils/filters/logic';
import { POSITION_TYPES } from 'components/utils/filters';

import style from 'styles/settings/quickFilters/quickFilterPopup.css';

const styles = style.locals || {};

function AdditionalConfigurationSection({
  filterKind,
  filterAdditionalData,
  setFilterAdditionalData,
  getMetricNickname,
  customFieldsIdToLabelMap,
  funnelsOptions,
  isQuickFilterDisabled,
}) {
  useStyles([style]);

  const [shouldDisableBeforeFunnelConversion, setShouldDisableBeforeFunnelConversion] = useState(filterAdditionalData.position !== POSITION_TYPES.anywhere);

  useEffect(() => {
    if (filterAdditionalData.position === POSITION_TYPES.first || filterAdditionalData.position === POSITION_TYPES.last) {
      setFilterAdditionalData({ selectedFunnel: null });
      setShouldDisableBeforeFunnelConversion(true);
      return;
    }
    setShouldDisableBeforeFunnelConversion(false);
  }, [filterAdditionalData.position]);

  const userFunnelsAsString = useMemo(() => {
    let userFunnelsStr = 'accounts';
    const selectedFunnels = filterAdditionalData?.selectedFunnels;
    const isAllFunnelStagesSelected = selectedFunnels?.some((funnel) => funnel === 'allStages');
    const hasSelectedAccountStage = selectedFunnels?.some((funnel) => funnel === 'accounts');
    if (isAllFunnelStagesSelected) {
      userFunnelsStr = 'all funnel stages';
    } else if (selectedFunnels && selectedFunnels.length > 0 && !hasSelectedAccountStage) {
      const userFunnels = selectedFunnels.map((funnel) => getMetricNickname({ metric: funnel }));
      userFunnelsStr = userFunnels.join(', ');
    }

    return userFunnelsStr;
  }, [filterAdditionalData?.selectedFunnels]);

  return (
    <div className={styles.wrapper}>
      {supportedConfigByFilterKind.position.includes(filterKind) ? (
        <>
          <div className={classnames(styles.controllerTitle, styles.titleGap)}>Touchpoint position</div>
          <Tooltip
            id="touchpoint-position-quick-filter-tooltip"
            tip="test"
          />
          <Toggle
            options={positionOptions}
            selectedValue={filterAdditionalData?.position}
            onClick={(newPosition) => setFilterAdditionalData({ position: newPosition })}
            boxClass={styles.toggleBox}
            disabled={isQuickFilterDisabled}
          />
        </>
      ) : null}

      {supportedConfigByFilterKind.beforeFunnelConversion.includes(filterKind) ? (
        <div className={styles.wrapper}>
          <div className={classnames(styles.row, styles.rowWithDropdown)}>
            <Checkbox
              key="Before funnel conversion"
              checked={filterAdditionalData?.selectedFunnel}
              onChange={() => setFilterAdditionalData({ selectedFunnel: filterAdditionalData?.selectedFunnel ? null : defaultBeforeFunnelConversionValue })}
              disabled={isQuickFilterDisabled || shouldDisableBeforeFunnelConversion}
            />
            <span className={styles.additionalConfigurationText}>Before funnel conversion</span>
            <InfoMarker
              id="before-funnel-conversion-quick-filter-tooltip"
              tooltipText={beforeFunnelConversionTooltip}
            />
            {filterAdditionalData?.selectedFunnel ? (
              <Dropdown
                selectedKey={filterAdditionalData.selectedFunnel}
                options={filterKind === filterKinds.FUNNEL_STAGES ? attributionStore.metricsOptions : funnelsOptions}
                onChange={(newSelectedFunnel) => setFilterAdditionalData({ selectedFunnel: newSelectedFunnel.value })}
                disabled={isQuickFilterDisabled}
              />
            ) : null}
          </div>
          <div className={classnames(styles.row, styles.rowWithDropdown)}>
            <Checkbox
              key="Custom Date"
              checked={filterAdditionalData?.timeFrame}
              onChange={() => setFilterAdditionalData({ timeFrame: filterAdditionalData?.timeFrame ? null : {} })}
              disabled={isQuickFilterDisabled}
            />
            <span className={styles.additionalConfigurationText}>Custom date</span>
            <InfoMarker
              id="before-funnel-conversion-timefrmae-quick-filter-tooltip"
              tooltipText={beforeFunnelConversionTimeframeTooltip}
            />
            {filterAdditionalData?.timeFrame ? (
              <CustomDateSelect
                timeFrame={filterAdditionalData.timeFrame}
                updateFilter={(newTimeframe) => setFilterAdditionalData({ timeFrame: newTimeframe.timeFrame })}
                isTitleShow={false}
              />
            ) : null}
          </div>
        </div>
      ) : null}

      {supportedConfigByFilterKind.association.includes(filterKind) ? (
        <div className={styles.wrapper}>
          <div className={classnames(styles.row, styles.rowWithDropdown)}>
            <Checkbox
              key="Use Associated objects"
              checked={filterAdditionalData?.relatedEntities}
              onChange={() => setFilterAdditionalData({ relatedEntities: !filterAdditionalData?.relatedEntities })}
              disabled={isQuickFilterDisabled}
            />
            <div className={styles.additionalConfigurationComplexText}>
              {customFieldsIdToLabelMap[filterAdditionalData?.fieldId]
                ? (
                  <>
                    {'Find '}
                    <div className={styles.bold}>
                      {userFunnelsAsString}
                    </div>
                    {' that are associated with '}
                    <div className={styles.bold}>
                      {customFieldsIdToLabelMap[filterAdditionalData?.fieldId]}
                    </div>
                  </>
                )
                : (
                  <div className={styles.additionalConfigurationText}>
                    {`Track filtered ${userFunnelsAsString} through all funnel stages`}
                  </div>
                )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default inject(
  ({
    userStore: {
      funnelsOptions,
      getMetricNickname,
      userAccount: {
        customFieldsIdToLabelMap,
      },
    },
  }) => ({
    funnelsOptions,
    getMetricNickname,
    customFieldsIdToLabelMap,
  }),
  observer
)(AdditionalConfigurationSection);
