import { isEqual } from 'lodash';
import moment from 'moment/moment';

import userStore from 'stores/userStore';

import { analyzeWidgetsResultKeyNames } from 'stores/logic/enums';

export function isAllWidgetsDataLoaded({
  widgetsData = [], isCompareToPreviousEnabled, resultKeyNames, prevResultKeyNames,
}) {
  const isCurrentTimeFrameDataLoaded = widgetsData.every((widget) => widget?.[resultKeyNames || analyzeWidgetsResultKeyNames.currentTimeFrame]?.isLoaded);
  const isPrevTimeFrameDataLoaded = widgetsData.every((widget) => widget?.[prevResultKeyNames || analyzeWidgetsResultKeyNames.previousTimeFrame]?.isLoaded);

  return isCurrentTimeFrameDataLoaded && (!isCompareToPreviousEnabled || isPrevTimeFrameDataLoaded);
}

export function isFiltersChanged({ filters, prevFilters }) {
  const filtersWithoutUIFilters = filters.filter((filter) => !filter.isUiOnly);
  const prevFiltersWithoutUIFilters = prevFilters.filter((filter) => !filter.isUiOnly);

  return !isEqual(filtersWithoutUIFilters, prevFiltersWithoutUIFilters);
}

export function getConversionRateAndVelocityOptions({ isIncludeConversionRate = true, isIncludeVelocity = true }) {
  const options = [];
  if (isIncludeConversionRate) {
    options.push('conversionRate');
  }
  if (isIncludeVelocity) {
    options.push('velocity');
  }
  const conversionRateAndVelocityObjects = userStore.userMetrics.filter((metric) => metric.enabled && options.includes(metric.metricType));
  return conversionRateAndVelocityObjects.map((metric) => ({ value: metric.metricName, label: metric.nickname }));
}

export function isTimeframeChanged({ timeFrame, prevTimeFrame }) {
  let didTimeFrameChanged = false;
  if (timeFrame?.startDate && timeFrame?.endDate) {
    didTimeFrameChanged = !moment(timeFrame.startDate).isSame(prevTimeFrame.startDate) || !moment(timeFrame.endDate).isSame(prevTimeFrame.endDate);
    if (!didTimeFrameChanged && timeFrame.value !== prevTimeFrame.value) {
      didTimeFrameChanged = true;
    }
  } else if (timeFrame.value !== prevTimeFrame.value) {
    didTimeFrameChanged = true;
  }

  return didTimeFrameChanged;
}
