import { filtersUIConfig } from 'components/pages/users/Filters/FilterPopup';
import { filterKinds, EQUALS } from 'components/utils/filters/logic';
import {
  CONTAINS, POSITION_TYPES, LOWER, HIGHER,
} from 'components/utils/filters';

export const emptyStateTitle = 'Create your first quick filter';

export const emptyStateSubTitle = 'Quick filters will be available in one click to everyone on your team - no learning curve.';

export const confirmPopupDescription = 'Deleting this filter will remove it from your quick filters, and from Analyze and Reports if it’s enabled. This cannot be undone.';

export const customQuickFiltersDescription = 'Create custom quick filters that will be available with a click to anyone on your team - without a learning curve.';

export const aiQuickFiltersDescription = 'Toggle on to show three automatically generated filters, based on your account’s usage patterns.';

export const aiQuickFilterPinTooltip = 'Pin this suggestion in order to keep it as a ‘Quick Filter’. This would generate another suggestion from your commonly-used filters.';

export const onSaveQuickFilterToastMessage = 'Quick filter changes saved!';

export const onConfirmQuickFilterDeleteRoastMessage = 'Quick Filter Deleted!';

export const quickFiltersCreationTypes = {
  default: 'default',
  manuallyCreated: 'manuallyCreated',
  ai: 'ai',
};

export const quickFiltersCreationTypesLabel = {
  [quickFiltersCreationTypes.default]: 'Default',
  [quickFiltersCreationTypes.manuallyCreated]: 'Manually Created',
  [quickFiltersCreationTypes.ai]: 'InfiniGrow AI',
};

export const defaultNewQuickFilterKind = filterKinds.CHANNELS;

export const quickFiltersKinds = [filterKinds.CUSTOM_FIELDS, filterKinds.FUNNEL_STAGES, filterKinds.CHANNELS, filterKinds.CAMPAIGNS, filterKinds.CONTENT, filterKinds.MARKETING_VS_SALES];

export const filterKindsWithoutAdditionalConfigurationStep = [filterKinds.MARKETING_VS_SALES];

export const supportedConfigByFilterKind = {
  comparisonOperator: [filterKinds.CAMPAIGNS, filterKinds.CAMPAIGN_TAGS, filterKinds.CONTENT, filterKinds.CUSTOM_FIELDS],
  funnelStageSelection: [filterKinds.CUSTOM_FIELDS, filterKinds.FUNNEL_STAGES, filterKinds.CHANNELS, filterKinds.CAMPAIGNS, filterKinds.CAMPAIGN_TAGS, filterKinds.CONTENT],
  numericComparisonOperators: [],
  position: [filterKinds.CHANNELS, filterKinds.CAMPAIGNS, filterKinds.CAMPAIGN_TAGS, filterKinds.CONTENT],
  association: [filterKinds.CUSTOM_FIELDS, filterKinds.CHANNELS, filterKinds.CAMPAIGNS, filterKinds.CONTENT],
  beforeFunnelConversion: [filterKinds.CHANNELS, filterKinds.CAMPAIGNS, filterKinds.CAMPAIGN_TAGS, filterKinds.CONTENT, filterKinds.FUNNEL_STAGES],
};

export const quickFiltersKindsOptions = quickFiltersKinds.map((type) => ({ value: type, label: filtersUIConfig[type].title }));

export const toggleOptionsByFilterKind = {
  [filterKinds.CHANNELS]: [{
    text: 'Channels',
    value: 0,
  },
  {
    text: 'Categories',
    value: 1,
  }],
  [filterKinds.CAMPAIGNS]: [{
    text: 'Campaigns',
    value: 0,
  },
  {
    text: 'Campaign tags',
    value: 1,
  }],
  [filterKinds.CAMPAIGN_TAGS]: [{
    text: 'Campaigns',
    value: 0,
  },
  {
    text: 'Campaign tags',
    value: 1,
  }],
  [filterKinds.CONTENT]: [{
    text: 'Content',
    value: 0,
  },
  {
    text: 'Content URL',
    value: 1,
  }, {
    text: 'Content Type',
    value: 2,
  }],
};

export const comparisonOperatorOptions = [
  {
    label: 'Is equal to',
    value: EQUALS,
  },
  {
    label: 'Contains',
    value: CONTAINS,
  },
];

export const numericComparisonOperatorOptions = [
  {
    label: 'Is greater than',
    value: HIGHER,
  },
  {
    label: 'Is less than',
    value: LOWER,
  },
];

export const positionOptions = [
  {
    text: 'Anywhere',
    value: POSITION_TYPES.anywhere,
  },
  {
    text: 'First',
    value: POSITION_TYPES.first,
  },
  {
    text: 'Last',
    value: POSITION_TYPES.last,
  },
];

export const defaultBeforeFunnelConversionValue = 'funnel1';
