import React from 'react';
import classnames from 'classnames';
import { inject, observer } from 'mobx-react';

import useStyles from 'hooks/useStyles';

import CardRowsTable from 'components/common/CardRowsTable';
import EllipsisTooltip from 'components/controls/EllipsisTooltip';
import Switch from 'components/controls/Switch';
import FilterTag from 'components/pages/users/Filters/FilterPanel/FilterTag';
import Tooltip from 'components/controls/Tooltip';
import servicesStore from 'stores/servicesStore';

import {
  aiQuickFiltersDescription, quickFiltersCreationTypesLabel, aiQuickFilterPinTooltip, quickFiltersCreationTypes,
} from 'components/pages/settings/quickFilters/enums';
import { getQuickFilterTagLabel } from 'components/pages/settings/quickFilters/logic/quickFilters';
import { filterTagsColorThemeOptions } from 'components/pages/users/Filters/FilterPanel/enums';
import { Events } from 'trackers/analytics/enums';
import { getFilterEventProperties } from 'components/pages/users/Filters/logic/FilterActions';

import tableStyle from 'styles/settings/quickFilters/quickFiltersTable.css';
import style from 'styles/settings/quickFilters/quickFilters.css';

const tableStyles = tableStyle.locals || {};
const styles = style.locals || {};

function AIQuickFilters({
  updateQuickFilterServerRequest,
  setSelectedQuickFilter,
  aiQuickFilters,
  isLoading,
  shouldUseAIRecommendation,
  updateShouldUseAIRecommendationServerRequest,
  getAIRecommendationServerRequest,
  removeAIRecommendationFilter,
  setStepIndexToOpen,
  filtersData,
}) {
  useStyles([tableStyle, style]);

  const columns = [
    {
      id: 'name',
      header: 'Name',
      sortable: true,
      cell: (item) => (
        <>
          <div
            className={tableStyles.rowWrapper}
            onClick={() => {
              setSelectedQuickFilter(item);
              setStepIndexToOpen(0);
            }}
          >
            <EllipsisTooltip
              text={item.name}
              place="bottom"
              className={tableStyles.clickableItem}
            />
          </div>
        </>
      ),
    },
    {
      id: 'filters',
      header: 'Filters',
      sortable: false,
      cell: (item) => (
        <>
          <FilterTag
            key={`quick-filter-tag-${item.id}`}
            label={getQuickFilterTagLabel({ quickFilter: item.filter, filtersData })}
            colorTheme={filterTagsColorThemeOptions.ai}
            className={!shouldUseAIRecommendation && tableStyles.sectionDisabled}
            onClick={() => {
              setSelectedQuickFilter(item);
              setStepIndexToOpen(1);
            }}
          />
        </>
      ),
    },
    {
      id: 'type',
      accessor: 'type',
      header: 'Type',
      sortable: true,
      cell: (type) => (
        <>
          <div className={classnames(tableStyles.rowWrapper, type === quickFiltersCreationTypes.ai && tableStyles.aiType)}>
            {quickFiltersCreationTypesLabel[type]}
          </div>
        </>
      ),
    },
    {
      id: 'pin',
      header: '',
      width: 100,
      cell: (item) => (
        <>
          <Tooltip
            tip={aiQuickFilterPinTooltip}
          >
            <div
              data-testid="pinIcon"
              className={classnames(tableStyles.rowWrapper, tableStyles.icon, tableStyles.clickableItem, !shouldUseAIRecommendation && tableStyles.sectionDisabled)}
              onClick={() => (shouldUseAIRecommendation ? onPinedAIQuickFilter({ filter: item }) : null)}
              data-icon="quickFilters:pin"
            />
          </Tooltip>
        </>
      ),
    },
  ];

  function onIsAIQuickFiltersEnabledClick({ isEnable }) {
    updateShouldUseAIRecommendationServerRequest({ shouldUseSuggestedFilters: isEnable });

    servicesStore.eventTracker.track({
      eventName: Events.quickFilters.toggledAIRecommendedQuickFilters,
      properties: {
        status: shouldUseAIRecommendation ? 'disabled' : 'enabled',
      },
    });
  }

  async function onPinedAIQuickFilter({ filter }) {
    removeAIRecommendationFilter({ filterId: filter.id });
    filter.active = true;
    await updateQuickFilterServerRequest({ quickFilter: filter });

    getAIRecommendationServerRequest({ limit: 1 });

    const properties = getFilterEventProperties({ filter: filter.filter });
    servicesStore.eventTracker.track({
      eventName: Events.quickFilters.pinnedAIQuickFilter,
      properties,
    });
  }

  return (
    <>
      <div className={classnames(styles.wrapper, styles.aiWrapper)}>
        <div className={styles.header}>
          <div className={styles.title}>
            AI Generated Filters
          </div>
          <div className={styles.headerActions}>
            <Switch
              onSwitchClick={() => onIsAIQuickFiltersEnabledClick({ isEnable: !shouldUseAIRecommendation })}
              isActive={shouldUseAIRecommendation}
              dataTestId="shouldUseAIRecommendationSwitch"
            />
          </div>
        </div>
        <div className={styles.description}>
          {aiQuickFiltersDescription}
        </div>
        <CardRowsTable
          data={aiQuickFilters}
          columns={columns}
          isLoading={isLoading}
          amountOfSkeletonRows={1}
        />
      </div>
    </>
  );
}

export default inject(
  ({
    filterStore: {
      shouldUseAIRecommendation,
      updateShouldUseAIRecommendationServerRequest,
      getAIRecommendationServerRequest,
      updateQuickFilterServerRequest,
      removeAIRecommendationFilter,
      filtersData,
    } = {},
  }) => ({
    shouldUseAIRecommendation,
    updateShouldUseAIRecommendationServerRequest,
    getAIRecommendationServerRequest,
    updateQuickFilterServerRequest,
    removeAIRecommendationFilter,
    filtersData,
  }),
  observer
)(AIQuickFilters);
