import { widgetTypes } from 'components/pages/analyze/enums';
import base from './base';
import historicalPerformance from './historicalPerformance';
import goalsAnalysis from './goalsAnalysis';
import journeysTable from './journeysTable';
import performanceItem from './performanceItem';
import segmentsAnalysis from './segmentsAnalysis';
import impactBySegment from './impactBySegment';
import whatIf from './whatIf';
import trendAnalysis from './trendAnalysis';

export function getWidgetDefinitions({ widget }) {
  if (!widgetTypes[widget]) {
    return base;
  }

  const widgetDefinitionsMapping = {
    [widgetTypes.historicalPerformance]: historicalPerformance,
    [widgetTypes.goalsAnalysis]: goalsAnalysis,
    [widgetTypes.journeysTable]: journeysTable,
    [widgetTypes.impactBySegment]: impactBySegment,
    [widgetTypes.segmentsAnalysis]: segmentsAnalysis,
    [widgetTypes.performanceItem]: performanceItem,
    [widgetTypes.whatIf]: whatIf,
    [widgetTypes.trendAnalysis]: trendAnalysis,
  };

  return widgetDefinitionsMapping[widget] || base;
}
