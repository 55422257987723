import { isEmpty } from 'lodash';
import moment from 'moment';

import userStore from 'stores/userStore';
import {
  VELOCITY_MAPPING_SUFFIX,
  CONVERSION_RATE_MAPPING_SUFFIX,
  getRevenueFunnel,
  getPipelineFunnel,
  getIndicatorDisplaySign,
} from 'components/utils/indicators';
import { operatorTypes, actionTriggerTypes } from 'components/pages/settings/actions/enums';
import { frequencyTypes } from 'components/pages/settings/goals/logic/enums';
import { getMonthTitle, getQuarterTitle, getTimeData } from 'components/utils/goals';

export function getOptimizationMetricsOptions({ funnels = [] }) {
  const metricsOptions = [];
  for (const [index, funnel] of funnels.entries()) {
    const optionGroup = {
      label: userStore.getMetricNickname({ metric: funnel }),
      options: [
        { label: `Attributed ${userStore.getMetricNickname({ metric: funnel })}`, value: funnel, originFunnel: funnel },
      ],
    };

    if (funnels.length - 1 !== index) {
      const conversionRateKey = `funnel${index + 1}ToFunnel${index + 2}${CONVERSION_RATE_MAPPING_SUFFIX}`;
      optionGroup.options.push({
        label: userStore.getMetricNickname({ metric: conversionRateKey, isSingular: true }),
        value: conversionRateKey,
        originFunnel: funnel,
      });
    }

    if (index !== 0) {
      const velocityKey = `funnel${index}ToFunnel${index + 1}${VELOCITY_MAPPING_SUFFIX}`;
      optionGroup.options.push({
        label: userStore.getMetricNickname({ metric: velocityKey, isSingular: true }),
        value: velocityKey,
        originFunnel: funnel,
      });
    }

    if (funnel === getRevenueFunnel()) {
      optionGroup.options.push({
        label: 'Attributed Revenue',
        value: 'revenue',
        originFunnel: funnel,
      }, {
        label: 'ARPA',
        value: 'ARPA',
        originFunnel: funnel,
      });
    }

    if (funnel === getPipelineFunnel()) {
      optionGroup.options.push({
        label: 'Pipeline',
        value: 'pipeline',
        originFunnel: funnel,
      });
    }
    metricsOptions.push(optionGroup);
  }

  return metricsOptions;
}

export function isAnomalyTriggerRuleValid({ triggerRule }) {
  if (isEmpty(triggerRule)) {
    return false;
  }

  const requiredKeys = ['metric', 'timeframe', 'operator', 'value'];
  const isAllRequireFieldsExists = requiredKeys.every((requireKey) => {
    const requireKeyValue = triggerRule[requireKey];

    if (requireKeyValue === undefined) {
      return false;
    }

    if (typeof requireKeyValue === 'string' && requireKeyValue === '') {
      return false;
    }

    if (Array.isArray(requireKeyValue) && requireKeyValue.length === 0) {
      return false;
    }

    if (typeof requireKeyValue === 'object' && isEmpty(requireKeyValue)) {
      return false;
    }

    return true;
  });

  if (!isAllRequireFieldsExists) {
    return false;
  }

  return true;
}

export function getDisplaySign({ operator, metric }) {
  const operatorsThatSupportPercentage = [operatorTypes.increase, operatorTypes.decrease];
  if (operatorsThatSupportPercentage.includes(operator)) {
    return '%';
  }
  return getIndicatorDisplaySign(metric);
}

export function isTriggerRulesAreValid({ triggerType, triggerRules }) {
  if (triggerType === actionTriggerTypes.anomaly) {
    return triggerRules?.length > 0 ? triggerRules.every((triggerRule) => isAnomalyTriggerRuleValid({ triggerRule })) : false;
  }

  if (triggerType === actionTriggerTypes.goalsTracking) {
    const firstGoalTriggerRule = triggerRules?.[0];
    return firstGoalTriggerRule?.goalId != null;
  }

  if (triggerType === actionTriggerTypes.accountEngagement) {
    return true;
  }

  return false;
}

export function getAlertErrorTooltipMessage({ alert }) {
  if (alert.triggerType === actionTriggerTypes.goalsTracking) {
    return 'This alert has been disabled because its goal was changed. To reactivate it, please select the relevant goal.';
  }

  return '';
}

export function getGoalsOptionsList({ goals }) {
  const listOptions = [];
  for (const goal of goals) {
    listOptions.push({
      label: goal.title,
      value: goal.id,
    });

    if (goal.milestonesConfig) {
      const haveQuarterlyAdditionalMilestones = goal.frequencyType === frequencyTypes.YEAR && goal.milestonesConfig.frequencyType === frequencyTypes.MONTH;

      if (haveQuarterlyAdditionalMilestones) {
        for (const quarterlyMilestone of goal.milestones) {
          const { isOngoing } = getTimeData(moment(quarterlyMilestone.timeframe.startDate), moment(quarterlyMilestone.timeframe.endDate));

          if (isOngoing) {
            listOptions.push({
              label: `${goal.title} in ${getQuarterTitle({ startDate: quarterlyMilestone.timeframe.startDate })}`,
              value: quarterlyMilestone.id,
            });
          }

          for (const monthlyMilestone of quarterlyMilestone.milestones) {
            const { isOngoing: isMonthlyOngoing } = getTimeData(moment(monthlyMilestone.timeframe.startDate), moment(monthlyMilestone.timeframe.endDate));

            if (isMonthlyOngoing) {
              listOptions.push({
                label: `${goal.title} in ${getMonthTitle({ startDate: quarterlyMilestone.timeframe.startDate })}`,
                value: monthlyMilestone.id,
              });
            }
          }
        }
      } else {
        const isMonthlyBreakdowns = goal.milestonesConfig.frequencyType === frequencyTypes.MONTH;
        for (const milestone of goal.milestones) {
          const { isOngoing } = getTimeData(moment(milestone.timeframe.startDate), moment(milestone.timeframe.endDate));

          if (isOngoing) {
            listOptions.push({
              label: `${goal.title} in ${isMonthlyBreakdowns ? getMonthTitle({ startDate: milestone.timeframe.startDate }) : getQuarterTitle({ startDate: milestone.timeframe.startDate })}`,
              value: milestone.id,
            });
          }
        }
      }
    }
  }

  return listOptions;
}
