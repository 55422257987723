import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import { Button } from '@infinigrow/libs';

import Dropdown from 'components/controls/Dropdown';
import FiltersPanel from 'components/pages/users/Filters/FilterPanel/FiltersPanel';
import Textfield from 'components/controls/Textfield';
import RollingTimeframeSelect from 'components/controls/RollingTimeframeSelect';
import MenuButton from 'components/common/MenuButton';

import { anomalyTriggerOperatorsOptions, percentageOperators, operatorTypes } from 'components/pages/settings/actions/enums';
import { getOptimizationMetricsOptions, isAnomalyTriggerRuleValid, getDisplaySign } from 'components/pages/settings/actions/logic/actions';
import { rollingTimeframeTypesOptions } from 'components/utils/timeframe';

import style from 'styles/settings/actions/actionPopup.css';

const styles = style.locals || {};

function Anomaly({
  anomalyData,
  updateAnomalyData,
  userFunnels,
  getMetricType,
}) {
  const [triggerRules, setTriggerRules] = useState(anomalyData);
  const [rollingPopupIndex, setRollingPopupIndex] = useState();

  useEffect(() => {
    style.use();
    return () => {
      if (!style.unuse) {
        return;
      }
      style.unuse();
    };
  }, []);

  useEffect(() => {
    if (isEmpty(anomalyData)) {
      setTriggerRules([getNewTriggerRule()]);
    } else {
      setTriggerRules(anomalyData);
    }
  }, [anomalyData]);

  function getNewTriggerRule() {
    return {
      metric: '',
      metricType: '',
      filters: [],
      operator: operatorTypes.increase,
      value: '',
      timeframe: {},
    };
  }

  function updateTriggerRule({ ruleIndex, ruleKey, ruleValue }) {
    const updatedTriggerRules = [...triggerRules];
    updatedTriggerRules[ruleIndex][ruleKey] = ruleValue;
    updateAnomalyData(updatedTriggerRules);
  }

  function updateOperatorValue({ ruleIndex, operatorValue }) {
    const valueWithNumbersOnly = operatorValue.replace(/\D/g, '');
    updateTriggerRule({ ruleIndex, ruleValue: valueWithNumbersOnly, ruleKey: 'value' });
  }

  function removeTriggerRule({ ruleIndex }) {
    const updatedTriggerRules = [...triggerRules];
    updatedTriggerRules.splice(ruleIndex, 1);
    updateAnomalyData(updatedTriggerRules);
  }

  function addNewRuleAfterGivenIndex({ index }) {
    const updatedTriggerRules = [...triggerRules];
    updatedTriggerRules.splice(index + 1, 0, getNewTriggerRule());
    updateAnomalyData(updatedTriggerRules);
  }

  function updateMetric({ ruleIndex, newMetric }) {
    const metricType = getMetricType({ metric: newMetric.value });
    updateTriggerRule({ ruleIndex, ruleValue: newMetric.value, ruleKey: 'metric' });
    updateTriggerRule({ ruleIndex, ruleValue: metricType, ruleKey: 'metricType' });
  }

  function getTimeframePlaceholder({ timeframe }) {
    if (isEmpty(timeframe)) {
      return 'select timeframe';
    }
    // eslint-disable-next-line no-unsafe-optional-chaining
    const [, amountOfUnit, unit] = timeframe.rollingValue?.split('_');
    return `Last ${amountOfUnit} ${unit}${amountOfUnit > 1 ? 's' : ''}`;
  }

  return (
    <>
      {triggerRules?.map((triggerRule, ruleIndex) => {
        const {
          metric, filters = [], operator, timeframe = {}, value = '',
        } = triggerRule;
        const ruleKey = `anomaly${ruleIndex}`;

        return (
          <div className={styles.ruleWrapper} key={ruleKey}>
            <div className={classNames(styles.row, styles.alignTop)}>
              <div className={styles.innerRow}>
                <div className={styles.text}>
                  If
                </div>
                <Dropdown
                  selectedKey={metric}
                  options={getOptimizationMetricsOptions({ funnels: userFunnels })}
                  onChange={(newMetric) => updateMetric({ ruleIndex, newMetric })}
                  controlWidth={180}
                />
              </div>
              <FiltersPanel
                filters={filters}
                setFilters={(newFilters) => updateTriggerRule({ ruleIndex, ruleValue: newFilters, ruleKey: 'filters' })}
                buttonText="Add filters (optional)"
                addFilterButtonClassName={styles.addFilterButton}
              />
              {triggerRules.length > 1 && (
              <div
                data-testid="trashIcon"
                className={styles.trashIcon}
                onClick={() => removeTriggerRule({ ruleIndex })}
              />
              )}
            </div>
            <div className={styles.row}>
              <Dropdown
                selectedKey={operator}
                options={anomalyTriggerOperatorsOptions}
                onChange={(newOperator) => updateTriggerRule({ ruleIndex, ruleValue: newOperator.value, ruleKey: 'operator' })}
                controlWidth={160}
                placeholder="Set condition"
              />
              {operator && (
              <>
                {percentageOperators.includes(operator) && (
                <div className={styles.text}>
                  by
                </div>
                )}
                <Textfield
                  dataTestId="ruleValue"
                  value={`${value}`}
                  className={styles.smallTextField}
                  inputClassName={getDisplaySign({ operator, metric }).length > 2 ? styles.largeTextFieldInputClassName : styles.textFieldInputClassName}
                  signClassName={styles.sign}
                  inputSign={getDisplaySign({ operator, metric })}
                  onChange={(event) => updateOperatorValue({ ruleIndex, operatorValue: event.target.value })}
                />
                <div className={styles.text}>
                  in the following timeframe
                </div>
                <div className={styles.rollingTimeframeWrapper}>
                  <MenuButton
                    title={getTimeframePlaceholder({ timeframe })}
                    onClick={() => setRollingPopupIndex(ruleIndex)}
                    className={styles.rollingTimeframeMenuButton}
                    withArrowIndication
                  />
                  {rollingPopupIndex === ruleIndex && (
                    <RollingTimeframeSelect
                      className={styles.rollingTimeframeContainer}
                      timeframeTypeOptions={rollingTimeframeTypesOptions}
                      timeFrame={timeframe}
                      onApply={(newRollingTimeframe) => {
                        updateTriggerRule({ ruleIndex, ruleValue: newRollingTimeframe, ruleKey: 'timeframe' });
                        setRollingPopupIndex(null);
                      }}
                      onCancel={() => setRollingPopupIndex(null)}
                      shouldIncludeThisMonthData
                    />
                  )}
                </div>
              </>
              )}
            </div>
            {operator && (
            <div className={styles.text}>
              compared to the previous period
            </div>
            )}
            <Button
              dataTestId="addTriggerRule"
              type="secondaryBlue"
              disabled={!isAnomalyTriggerRuleValid({ triggerRule })}
              onClick={() => addNewRuleAfterGivenIndex({ index: ruleIndex })}
            >
              + AND
            </Button>
          </div>
        );
      })}
    </>
  );
}

export default inject(
  ({
    userStore: {
      userFunnels,
      getMetricType,
    } = {},
  }) => ({
    userFunnels,
    getMetricType,
  }),
  observer
)(Anomaly);
