import React from 'react';
import classnames from 'classnames';

import useStyles from 'hooks/useStyles';

import style from 'styles/beta-tag.css';

const styles = style.locals || {};

export default function BetaTag({
  hidden,
  className,
  label = 'Beta',
  dataTestId = 'beta-tag',
}) {
  useStyles([style]);

  if (hidden) {
    return null;
  }

  return (
    <div data-testid={dataTestId} className={classnames(styles.betaTag, className)}>
      {label}
    </div>
  );
}
