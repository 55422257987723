import React from 'react';

import useStyles from 'hooks/useStyles';

import Table from 'components/controls/Table';
import Skeleton from 'components/common/Skeleton';

import style from 'styles/common/cardRowsTable.css';

const styles = style.locals || {};

export default function CardRowsTable({
  data,
  columns,
  onRowClick,
  isLoading,
  wrapperClassname,
  amountOfSkeletonRows = 3,
}) {
  useStyles([style]);

  return (
    <>
      <Table
        key="cardRowsTable"
        data={data}
        columns={columns}
        headerClassName={styles.header}
        headRowClassName={styles.headerRow}
        cellClassName={styles.cell}
        bodyCustomClass={styles.customBodyClass}
        rowClassName={styles.tableRowGroup}
        tableClassName={styles.table}
        onRowClick={onRowClick}
        className={wrapperClassname}
      />
      {isLoading ? [...Array(amountOfSkeletonRows)].map((key, index) => {
        const skeletonKey = `${key}-${index}`;
        return (
          <div className={styles.skeleton} key={skeletonKey}>
            <Skeleton height={68} borderRadius={12} />
          </div>
        );
      })
        : null}
    </>
  );
}
