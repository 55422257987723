import React from 'react';

import useStyles from 'hooks/useStyles';

import Dropdown from 'components/controls/Dropdown';

import { quickFiltersKindsOptions } from 'components/pages/settings/quickFilters/enums';
import { filterKinds } from 'components/utils/filters/logic';

import style from 'styles/settings/quickFilters/quickFilterPopup.css';

const styles = style.locals || {};

export default function FilterKindSection({ filterKind, setFilterKind, isQuickFilterDisabled }) {
  useStyles([style]);

  return (
    <div className={styles.controllerWrapper}>
      <Dropdown
        selectedKey={filterKind === filterKinds.CAMPAIGN_TAGS ? filterKinds.CAMPAIGNS : filterKind}
        options={quickFiltersKindsOptions}
        onChange={(newKind) => setFilterKind(newKind.value)}
        disabled={isQuickFilterDisabled}
      />
    </div>
  );
}
