import { action, decorate, observable } from 'mobx';

import serverCommunication from 'data/serverCommunication';
import userStore from 'stores/userStore';
import servicesStore from 'stores/servicesStore';

import { attributionFiltersOrder, costFiltersOrder } from 'stores/logic/enums';
import { orderConfigFilters } from 'stores/logic/attributionStore';
import { getQuickFilterTagLabel } from 'components/pages/settings/quickFilters/logic/quickFilters';
import { getFilterIdAsHash } from 'stores/logic/filterStore';

class FilterStore {
  constructor() {
    this.isFilterDataLoaded = false;
    this.filtersData = [];
    this.quickFilters = null;
    this.shouldUseAIRecommendation = null;
    this.quickFiltersAIRecommendations = [];
    this.hasMoreQuickFiltersAIRecommendations = true;
    this.isLoadingAIRecommendationServerRequest = false;
    this.isLoadingSuggestMoreAIRecommendationServerRequest = false;
    this.isLoadingQuickFiltersServerRequest = false;
    this.suggestMoreAIRecommendationFilters = [];
  }

  async getUserAttributionFiltersData() {
    try {
      const response = await serverCommunication.serverRequest('GET', 'filters/getAttribution', null, userStore.userMonthPlan.region);
      const attributionFilterDataFromResponse = await response.json();
      const updateAttributionFilterConfig = orderConfigFilters(attributionFilterDataFromResponse, attributionFiltersOrder);
      return updateAttributionFilterConfig;
    } catch (exception) {
      servicesStore.logger.error('failed to get user attribution filters data', {
        UID: userStore.userMonthPlan.UID, region: userStore.userMonthPlan.region,
      });
      return [];
    }
  }

  async getUserCostFiltersData() {
    try {
      const response = await serverCommunication.serverRequest('GET', 'filters/getCosts', null, userStore.userMonthPlan.region);
      const costFilterDataFromResponse = await response.json();
      const updateCostFilterConfig = orderConfigFilters(costFilterDataFromResponse, costFiltersOrder);
      return updateCostFilterConfig;
    } catch (exception) {
      servicesStore.logger.error('failed to get user costs filters data', {
        UID: userStore.userMonthPlan.UID, region: userStore.userMonthPlan.region,
      });
      return [];
    }
  }

  async getUserFiltersData() {
    this.isFilterDataLoaded = false;
    const [attributionFiltersData, costFiltersData] = await Promise.all([this.getUserAttributionFiltersData(), this.getUserCostFiltersData()]);
    this.filtersData = [...attributionFiltersData, ...costFiltersData];
    this.isFilterDataLoaded = true;
  }

  async getFilterKindOptions({
    searchValue, filterKind, filterKey, offset, limit = 20,
  }) {
    try {
      const body = {
        filterKind,
        filterKey,
        searchValue,
        limit,
        offset,
      };
      const response = await serverCommunication.serverRequest('POST', 'filters/search', JSON.stringify(body), userStore.userMonthPlan.region);
      return response.json();
    } catch (exception) {
      servicesStore.logger.error('failed to get filter kind options data', {
        UID: userStore.userMonthPlan.UID,
        region: userStore.userMonthPlan.region,
        filterKind,
        filterKey,
      });
      return {};
    }
  }

  async updateSavedFilters({ filters, alias = '' }) {
    const parsedFilers = filters.map(({ config, data }) => ({ kind: config.kind, data }));
    const filter = {
      filters: parsedFilers,
      alias,
    };
    try {
      const response = await serverCommunication.serverRequest('POST', 'savedFilters', JSON.stringify({ filter }), userStore.userMonthPlan.region);
      if (response.ok) {
        const responseData = await response.json();
        return responseData.savedFilters;
      }
    } catch (error) {
      servicesStore.logger.error('failed to set savedFilters export', {
        UID: userStore.userMonthPlan.UID,
        region: userStore.userMonthPlan.region,
        error,
      });
      throw error;
    }
    return [];
  }

  async getQuickFiltersServerRequest({ activeFilterOnly } = {}) {
    this.isLoadingQuickFiltersServerRequest = true;
    try {
      const response = await servicesStore.serverCommunication.serverRequest({
        method: 'GET',
        route: 'quickFilters',
        queryParams: {
          region: userStore.userMonthPlan?.region,
          active: activeFilterOnly,
        },
      });

      this.quickFilters = response;
    } catch (exception) {
      servicesStore.logger.error('failed to get quick filters data from server request', {
        exception,
        UID: userStore.userMonthPlan.UID,
        region: userStore.userMonthPlan.region,
        activeFilterOnly,
      });
    }
    this.isLoadingQuickFiltersServerRequest = false;
  }

  async updateQuickFilterServerRequest({ quickFilter }) {
    const updatedQuickFilter = [...this.quickFilters];

    if (quickFilter.id && quickFilter.id.includes('ai-recommendation')) {
      delete quickFilter.id;
    }

    try {
      const updatedFilter = await servicesStore.serverCommunication.serverRequest({
        method: 'PUT',
        route: 'quickFilters',
        queryParams: {
          region: userStore.userMonthPlan?.region,
        },
        body: {
          quickFilter,
        },
      });

      const updatedFilterId = updatedFilter.id;
      const existingFilterIndex = this.quickFilters.findIndex((filter) => filter.id === updatedFilterId);
      if (existingFilterIndex !== -1) {
        updatedQuickFilter[existingFilterIndex] = updatedFilter;
      } else {
        updatedQuickFilter.push(updatedFilter);
      }

      this.quickFilters = updatedQuickFilter;
    } catch (exception) {
      servicesStore.logger.error('failed to update quick filter data server request', {
        exception,
        UID: userStore.userMonthPlan.UID,
        region: userStore.userMonthPlan.region,
        quickFilter,
      });
    }
  }

  async deleteQuickFilterServerRequest({ quickFilterId }) {
    const updatedQuickFilter = this.quickFilters.filter((filter) => filter.id !== quickFilterId);
    try {
      await servicesStore.serverCommunication.serverRequest({
        method: 'DELETE',
        route: 'quickFilters',
        queryParams: {
          region: userStore.userMonthPlan?.region,
          quickFilterId,
        },
      });

      this.quickFilters = updatedQuickFilter;
    } catch (exception) {
      servicesStore.logger.error('failed to delete quick filter server request', {
        exception,
        UID: userStore.userMonthPlan.UID,
        region: userStore.userMonthPlan.region,
        quickFilterId,
      });
    }
  }

  async shouldUseAIRecommendationServerRequest() {
    try {
      const response = await servicesStore.serverCommunication.serverRequest({
        method: 'GET',
        route: 'quickFilters/useSuggestedFilters',
        queryParams: {
          region: userStore.userMonthPlan?.region,
        },
      });

      this.shouldUseAIRecommendation = response.shouldUseSuggestedFilters;
    } catch (exception) {
      servicesStore.logger.error('failed to get should use AI recommendations server request', {
        exception,
        UID: userStore.userMonthPlan.UID,
        region: userStore.userMonthPlan.region,
      });
    }
  }

  async updateShouldUseAIRecommendationServerRequest({ shouldUseSuggestedFilters }) {
    this.shouldUseAIRecommendation = shouldUseSuggestedFilters;
    try {
      await servicesStore.serverCommunication.serverRequest({
        method: 'PUT',
        route: 'quickFilters/useSuggestedFilters',
        queryParams: {
          region: userStore.userMonthPlan?.region,
        },
        body: {
          shouldUseSuggestedFilters,
        },
      });
    } catch (exception) {
      servicesStore.logger.error('failed to update should use AI recommendations server request', {
        exception,
        UID: userStore.userMonthPlan.UID,
        region: userStore.userMonthPlan.region,
      });
    }
  }

  async getAIRecommendationServerRequest({ limit = 3, suggestMoreRequest = false } = {}) {
    if (suggestMoreRequest) {
      this.isLoadingSuggestMoreAIRecommendationServerRequest = true;
    } else {
      this.isLoadingAIRecommendationServerRequest = true;
    }
    try {
      const response = await servicesStore.serverCommunication.serverRequest({
        method: 'POST',
        route: 'quickFilters/suggestedFilters',
        queryParams: {
          region: userStore.userMonthPlan?.region,
        },
        body: {
          limit,
          filtersToExclude: [...this.suggestMoreAIRecommendationFilters.map((suggestMoreFilter) => suggestMoreFilter.filter), ...this.quickFiltersAIRecommendations.map((quickFilter) => quickFilter.filter)],
        },
      });

      const parsedFiltersResponse = response.suggestedFilters.map((suggestedFilter) => ({
        name: getQuickFilterTagLabel({ quickFilter: suggestedFilter, filtersData: this.filtersData }),
        filter: suggestedFilter,
        id: `ai-recommendation-${getFilterIdAsHash({ filter: suggestedFilter })}`,
        type: 'ai',
      }));

      if (suggestMoreRequest) {
        const updatedSuggestMoreAIRecommendationFilters = [...this.suggestMoreAIRecommendationFilters];
        updatedSuggestMoreAIRecommendationFilters.push(...parsedFiltersResponse);
        this.suggestMoreAIRecommendationFilters = updatedSuggestMoreAIRecommendationFilters;
      } else {
        const updatedQuickFiltersAIRecommendations = [...this.quickFiltersAIRecommendations];
        updatedQuickFiltersAIRecommendations.push(...parsedFiltersResponse);
        this.quickFiltersAIRecommendations = updatedQuickFiltersAIRecommendations;
      }

      this.hasMoreQuickFiltersAIRecommendations = response.hasMore;
    } catch (exception) {
      servicesStore.logger.error('failed to get quick filters AI recommendations data from server request', {
        exception,
        UID: userStore.userMonthPlan.UID,
        region: userStore.userMonthPlan.region,
        limit,
      });
    }
    this.isLoadingAIRecommendationServerRequest = false;
    this.isLoadingSuggestMoreAIRecommendationServerRequest = false;
  }

  async removeAIRecommendationFilter({ filterId }) {
    if (!filterId) {
      return;
    }
    this.quickFiltersAIRecommendations = this.quickFiltersAIRecommendations.filter((filter) => filter.id !== filterId);
    this.suggestMoreAIRecommendationFilters = this.suggestMoreAIRecommendationFilters.filter((filter) => filter.id !== filterId);
  }
}

decorate(FilterStore, {
  suggestMoreAIRecommendationFilters: observable,
  isLoadingQuickFiltersServerRequest: observable,
  hasMoreQuickFiltersAIRecommendations: observable,
  isLoadingAIRecommendationServerRequest: observable,
  isLoadingSuggestMoreAIRecommendationServerRequest: observable,
  quickFiltersAIRecommendations: observable,
  shouldUseAIRecommendation: observable,
  quickFilters: observable,
  filtersData: observable,
  isFilterDataLoaded: observable,
  getUserCostFiltersData: action.bound,
  getUserAttributionFiltersData: action.bound,
  getFilterKindOptions: action.bound,
  getUserFiltersData: action.bound,
  updateSavedFilters: action.bound,
  getQuickFiltersServerRequest: action.bound,
  updateQuickFilterServerRequest: action.bound,
  deleteQuickFilterServerRequest: action.bound,
  updateShouldUseAIRecommendationServerRequest: action.bound,
  getAIRecommendationServerRequest: action.bound,
  removeAIRecommendationFilter: action.bound,
  shouldUseAIRecommendationServerRequest: action.bound,
});

export default new FilterStore();
