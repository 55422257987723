import React, { Fragment } from 'react';
import moment from 'moment';
import {
  get, isEmpty, startCase,
} from 'lodash';
import classnames from 'classnames';

import Component from 'components/Component';
import Tooltip from 'components/controls/Tooltip';
import Spinner from 'components/pages/journeys/Spinner';
import EventsTimelineSessionItem from 'components/pages/users/EventsTimelineSessionItem';
import { formatBudget } from 'components/utils/budget';
import { getFormName } from 'components/utils/users';
import { stringifyDateWithTimeZone } from 'components/utils/date';
import { formatToFloatWithoutZeros } from 'components/utils/logic/budget';
import { formatIndicatorDisplay } from 'components/utils/indicators';

import style from 'styles/users/users-popup.css';

const INFINITE_SCROLL_OFFSET = 50;
const FETCH_MORE_USER_JOURNEYS_OFFSET = 300;

function getScrollOffset({ element, container }) {
  let offset = 0;

  while (element && element !== container) {
    offset += element.offsetTop;
    element = element.offsetParent;
  }

  if (element !== container) {
    return null;
  }

  return offset - container.scrollTop;
}

export default class EventsTimeline extends Component {
  style = style;

  constructor(props) {
    super(props);
    this.state = {
      lastInteractionEventElement: null,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.lastInteractionEventElement !== null) {
      return;
    }

    const lastInteractionEventElement = document.querySelector('[data-last-interaction-event=true]');
    if (lastInteractionEventElement === null) {
      return;
    }

    this.setState({
      lastInteractionEventElement,
    });
  }

  handleScroll = async (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + INFINITE_SCROLL_OFFSET;
    if (bottom && this.props.moreToRender) {
      this.props.showMoreEvents();
    }

    const { lastInteractionEventElement } = this.state;
    if (lastInteractionEventElement === null) {
      return;
    }

    const popup = document.getElementById('user-journeys-popup');
    const scrollOffset = getScrollOffset({
      element: lastInteractionEventElement,
      container: popup,
    });

    const shouldFetchMore = scrollOffset < e.currentTarget.scrollTop + e.currentTarget.clientHeight + FETCH_MORE_USER_JOURNEYS_OFFSET;
    if (shouldFetchMore) {
      this.setState({
        lastInteractionEventElement: null,
      });
      this.props.fetchMoreJourneySessions();
    }
  };

  getDateTitle = (date) => {
    const today = moment();
    const yesterday = moment().subtract(1, 'day');
    if (moment(date).isSame(today, 'day')) {
      return 'Today';
    }
    if (moment(date).isSame(yesterday, 'day')) {
      return 'Yesterday';
    }
    return date;
  };

  shouldShowStartOfPeriod = (date, dateIndex) => {
    const { groupedEvents } = this.props;
    // show timeline for current date
    const startOfPeriodIndex = groupedEvents[date].findIndex((item) => item.isStartOfPeriod);
    let showStartOfPeriod = false;
    if (startOfPeriodIndex >= 0) {
      // show start of period line if there are older events
      // 1. start of period is not the oldest item/event on current date, or
      // 2. current date is not the oldest date
      if (startOfPeriodIndex !== groupedEvents[date].length - 1) {
        showStartOfPeriod = true;
      } else if (dateIndex !== Object.keys(groupedEvents).length - 1) {
        showStartOfPeriod = true;
      }
    }
    return {
      showStartOfPeriod,
      startOfPeriodIndex,
    };
  };

  newDealTooltip = ({
    status, amount, closeDate, dealName,
  }, stringifyDate) => {
    const dateString = closeDate ? `Close date - ${stringifyDate(closeDate, 'D MMM YYYY')}` : '';
    return `
      <div>
        <div style="margin-bottom: 5px">Deal name - ${dealName || ''}</div>
        <div style="margin-bottom: 5px">Deal amount - ${formatBudget(amount || 0)}</div>
        <div style="margin-bottom: 5px">Deal status - ${status}</div>
        <div>${dateString}</div>
      </div>
    `;
  };

  render() {
    const {
      groupedEvents,
      timezone,
      isLoadingFilteredJourneySessions,
      hidden,
      wrapperClassname,
      eventClassname,
      segments,
      isLoadingJourneySessions,
    } = this.props;
    const eventsKeys = Object.keys(groupedEvents);
    const {
      bold,
      capsule,
      dailyEvents,
      date: dateClass,
      eventLine,
      eventText,
      eventTime,
      iconCircle,
      iconContainer,
      iconGreen,
      label,
      otherPages,
      periodSeparation,
      timeline,
      toggle,
      specialTitle,
    } = this.classes;

    return (
      <div
        className={classnames(wrapperClassname || this.classes.userJourney, hidden ? this.classes.hidden : null)}
        onScroll={this.handleScroll}
      >
        <div className={classnames(eventClassname || this.classes.events)}>
          <div>
            {isLoadingFilteredJourneySessions ? (
              <div className={this.classes.userJourneyLoading}>
                <Spinner />
                <div>
                  Loading data...
                </div>
              </div>
            ) : (

              eventsKeys.map((date, dateIndex) => {
                const stringifyDate = stringifyDateWithTimeZone(timezone);
                const groupedEvent = groupedEvents[date];
                const firstEvent = groupedEvent[0];
                const firstEventDate = stringifyDate(firstEvent.startTime || firstEvent.timestamp, 'MMM D, YYYY');
                const eventKey = `${firstEvent.startTime}-${dateIndex}`;
                if (groupedEvent.length === 1) {
                  if (dateIndex === 0 && firstEvent.isEndOfPeriod) {
                    // hide event timeline for current date if it only contain end of period
                    // and there are no newer events
                    return null;
                  }
                  if (dateIndex === eventsKeys.length - 1 && firstEvent.isStartOfPeriod) {
                    // hide event timeline for current date if it only contain start of period
                    // and there are no older events
                    return null;
                  }
                  if ((firstEvent.isStartOfPeriod || firstEvent.isEndOfPeriod)) {
                    // hide timeline for current date and show start of period line if it only contain start of period or end of period
                    // and there are older events
                    return (
                      <div
                        key={eventKey}
                        className={periodSeparation}
                      >
                        <span>{firstEventDate}</span>
                      </div>
                    );
                  }
                }

                // show timeline for current date
                const { showStartOfPeriod, startOfPeriodIndex } = this.shouldShowStartOfPeriod(date, dateIndex);
                return (
                  <Fragment key={eventKey}>
                    <div className={dailyEvents}>
                      <div
                        className={classnames(label, dateClass)}
                      >
                        {this.getDateTitle(firstEventDate)}
                      </div>
                      <div className={timeline}>
                        {
                          groupedEvent.map((event, index) => {
                            const {
                              email,
                              isEndOfPeriod,
                              isForm,
                              isFunnelStage,
                              touchpointTitle,
                              isRevenue,
                              isStartOfPeriod,
                              nickname,
                              path,
                              previousFunnelStageNickname,
                              startTime,
                              timestamp,
                              selectedChangedBy,
                              sessions,
                              eventIndex,
                              pages,
                              amount,
                              firstSegment,
                              secondSegment,
                            } = event;
                            const segmentsForEventTimeline = {
                              ...segments,
                              firstSegmentValue: firstSegment,
                              secondSegmentValue: secondSegment,
                            };

                            const fragStartTime = startTime;
                            if (isStartOfPeriod || isEndOfPeriod) {
                              return null;
                            }
                            const groupedEventKey = `groupedEvent-${index}`;
                            if (isForm) {
                              return (
                                <Tooltip
                                  className={eventLine}
                                  key={`stage-${fragStartTime}-${groupedEventKey}-${eventKey}`}
                                  tip={getFormName(event)}
                                  id={`stage-${index}`}
                                >
                                  <div
                                    className={eventLine}
                                    key={`form-${fragStartTime}-${groupedEventKey}-${eventKey}`}
                                  >
                                    <div className={iconContainer}>
                                      <div
                                        className={iconCircle}
                                        data-icon="event:form"
                                        style={{ backgroundSize: '40%' }}
                                      />
                                    </div>
                                    <div className={eventText}>
                                      {`${email} submitted a `}
                                      <span className={bold}>form</span>
                                      {' on '}
                                      <span className={capsule}>{path}</span>
                                      <div className={eventTime}>
                                        {stringifyDate(timestamp)}
                                      </div>
                                    </div>
                                  </div>
                                </Tooltip>
                              );
                            }
                            if (isFunnelStage) {
                              const tip = amount
                                ? `Deal amount - ${formatBudget(amount)}` : null;
                              const isFirstFunnel = !previousFunnelStageNickname;
                              return (
                                <Tooltip
                                  className={eventLine}
                                  key={`stage-${fragStartTime}-${groupedEventKey}-${eventKey}`}
                                  tip={tip}
                                  id={`stage-isFunnelStage-${index}`}
                                >
                                  <div className={iconContainer}>
                                    <div
                                      className={iconCircle}
                                      data-icon="event:status"
                                      style={{ backgroundSize: '40%' }}
                                    />
                                  </div>
                                  {touchpointTitle ? (
                                    <div className={specialTitle}>
                                      {touchpointTitle}
                                      {' '}
                                      <strong>{nickname}</strong>
                                    </div>
                                  ) : (
                                    <div className={eventText}>
                                      {'Status changed to '}
                                      <span className={bold}>{nickname}</span>
                                      {isFirstFunnel ? ' creation' : ''}
                                      <div className={eventTime}>
                                        {`${stringifyDate(startTime)} ${selectedChangedBy}`}
                                      </div>
                                    </div>
                                  )}
                                </Tooltip>
                              );
                            }
                            if (isRevenue) {
                              return (
                                <Tooltip
                                  className={eventLine}
                                  key={`stage-${fragStartTime}-${groupedEventKey}-${eventKey}`}
                                  tip={this.newDealTooltip(event, stringifyDate)}
                                  id={`stage-isRevenue-${index}`}
                                >
                                  <div className={iconContainer}>
                                    <div
                                      className={iconCircle}
                                      data-icon="event:status"
                                      style={{ backgroundSize: '40%' }}
                                    />
                                  </div>
                                  <div className={eventText}>
                                    New deal creation
                                    <div className={eventTime}>
                                      {stringifyDate(startTime)}
                                    </div>
                                  </div>
                                </Tooltip>
                              );
                            }

                            const getTipPagesList = (tooltipPages, withPrefix = true) => {
                              const mapped = tooltipPages.map((p, idx) => {
                                if (tooltipPages.length < 2) {
                                  return `<li>${p.path}</li>`;
                                }
                                const isLast = (idx + 1) === tooltipPages.length;
                                let prefix = '';
                                if (withPrefix) {
                                  if (idx === 0) {
                                    prefix = '<b>Landing Page - </b>';
                                  } else if (isLast) {
                                    prefix = '<b>Exit page - </b>';
                                  }
                                }
                                return `<li>${prefix}${p.path}</li>`;
                              });
                              return `
                                <div>
                                    <ul>${mapped.join('')}</ul>
                                </div>
                              `;
                            };
                            const pagesLength = get(pages, 'length');
                            const firstSession = sessions[0];
                            const pageLinks = pages?.filter((page) => page.linkClickUrl !== undefined);
                            const pageLinksLength = pageLinks?.length;

                            let credit = event.credit !== null ? formatToFloatWithoutZeros({ number: event.credit }) : null;
                            let metricThatGotCredit = event.metricThatGotCredit;

                            if (this.props.metricType === 'revenue' || this.props.metricType === 'pipeline') {
                              credit = formatIndicatorDisplay(this.props.metricType, event[this.props.metricType], false, true, true, 0);
                              metricThatGotCredit = startCase(this.props.metricType);
                            }

                            return (
                              <>
                                {pagesLength > 0
                                  && (
                                    <div
                                      className={eventLine}
                                      key={`pages-${fragStartTime}-${groupedEventKey}-${eventKey}`}
                                    >
                                      <div className={iconContainer}>
                                        <div
                                          className={classnames(iconCircle, iconGreen)}
                                          data-icon="event:page"
                                        />
                                      </div>
                                      <div className={eventText}>
                                        {'Viewed '}
                                        {pagesLength === 1 ? (
                                          <span
                                            className={capsule}
                                          >
                                            {pages[0].path}
                                          </span>
                                        ) : (
                                          <span className={otherPages}>
                                            <Tooltip
                                              id={`pages-${dateIndex}-${index}`}
                                              tip={getTipPagesList(pages)}
                                              TooltipProps={{
                                                clickable: true,
                                                delayHide: 200,
                                                className: this.classes.tooltipAsList,
                                              }}
                                            >
                                              <span
                                                className={classnames(capsule, toggle)}
                                              >
                                                {`${pagesLength}  ${pagesLength === 1 ? 'page ' : 'pages '}`}
                                              </span>
                                            </Tooltip>
                                          </span>
                                        )}
                                        { `for ${moment.duration(moment(firstSession.endTime).diff(moment(startTime))).humanize()}`}
                                        { pageLinksLength
                                          ? (
                                            <div className={eventText}>
                                              {'Clicked on '}
                                              <span className={otherPages}>
                                                <Tooltip
                                                  id={`pages-links-${dateIndex}-${index}`}
                                                  tip={getTipPagesList(pageLinks, false)}
                                                  TooltipProps={{
                                                    clickable: true,
                                                    delayHide: 200,
                                                    className: this.classes.tooltipAsList,
                                                  }}
                                                >
                                                  <span
                                                    className={classnames(capsule, toggle)}
                                                  >
                                                    {`${pageLinksLength}  ${pageLinksLength === 1 ? 'link' : 'links'}`}
                                                  </span>
                                                </Tooltip>
                                              </span>
                                            </div>
                                          ) : '' }
                                        <div className={eventTime}>
                                          {stringifyDate(startTime)}
                                          {firstSession.email && `, ${firstSession.email}`}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                <EventsTimelineSessionItem
                                  key={`channel-${fragStartTime}-${groupedEventKey}-${eventKey}`}
                                  sessionsChannel={event.channel}
                                  credit={credit}
                                  metricThatGotCredit={metricThatGotCredit}
                                  sessions={sessions}
                                  isLastInteractionEventItem={eventIndex + 1 === this.props.journeySessionsOffset}
                                  stringifyDate={stringifyDate}
                                  isShowChannelTooltip={!isEmpty(segments)}
                                  segments={!isEmpty(segments) ? segmentsForEventTimeline : null}
                                />
                              </>
                            );
                          })
                        }
                      </div>
                    </div>
                    {showStartOfPeriod && (
                      <div className={periodSeparation}>
                        <span>{stringifyDate(groupedEvent[startOfPeriodIndex].startTime, 'MMM D, YYYY')}</span>
                      </div>
                    )}
                  </Fragment>
                );
              })
            )}
          </div>
        </div>
        {isLoadingJourneySessions ? (<Spinner />) : null}
      </div>
    );
  }
}
