import React, { useState } from 'react';
import classnames from 'classnames';
import { Button } from '@infinigrow/libs';
import { toast } from 'react-toastify';
import { inject, observer } from 'mobx-react';

import useStyles from 'hooks/useStyles';

import CardRowsTable from 'components/common/CardRowsTable';
import ConfirmPopup from 'components/ConfirmPopup';
import EllipsisTooltip from 'components/controls/EllipsisTooltip';
import Switch from 'components/controls/Switch';
import FilterTag from 'components/pages/users/Filters/FilterPanel/FilterTag';
import servicesStore from 'stores/servicesStore';

import { Events } from 'trackers/analytics/enums';
import {
  confirmPopupDescription, customQuickFiltersDescription, quickFiltersCreationTypesLabel, quickFiltersCreationTypes, onConfirmQuickFilterDeleteRoastMessage,
} from 'components/pages/settings/quickFilters/enums';
import { getQuickFilterTagLabel, removeAppliedFilterFromAnalyze } from 'components/pages/settings/quickFilters/logic/quickFilters';
import { getFilterEventProperties } from 'components/pages/users/Filters/logic/FilterActions';

import tableStyle from 'styles/settings/quickFilters/quickFiltersTable.css';
import style from 'styles/settings/quickFilters/quickFilters.css';

const tableStyles = tableStyle.locals || {};
const styles = style.locals || {};

function CustomQuickFilters({
  isLoading,
  updateQuickFilterServerRequest,
  deleteQuickFilterServerRequest,
  setSelectedQuickFilter,
  customQuickFilters,
  suggestMoreAIRecommendationFilters,
  setStepIndexToOpen,
  removeAIRecommendationFilter,
  filtersData,
}) {
  useStyles([tableStyle, style]);

  const [isShowConfirmationPopup, setIsShowConfirmationPopup] = useState(false);
  const [filterToDelete, setFilterToDelete] = useState({});

  function onChangeStatus({ filter }) {
    const isActive = !filter.active;
    updateQuickFilterServerRequest({
      quickFilter: {
        ...filter,
        active: isActive,
      },
    });

    const isChangedStatusToNotActive = !isActive;
    if (isChangedStatusToNotActive) {
      removeAppliedFilterFromAnalyze({ filter });
    }

    const isQuickFilterFromAIRecommendation = filter.type === quickFiltersCreationTypes.ai;
    if (isQuickFilterFromAIRecommendation) {
      removeAIRecommendationFilter({ filterId: filter.id });
    }

    const properties = getFilterEventProperties({ filter: filter.filter });
    servicesStore.eventTracker.track({
      eventName: Events.quickFilters.toggledQuickFilter,
      properties: {
        ...properties,
        quickFilterType: quickFiltersCreationTypesLabel[filterToDelete.type],
        status: isActive ? 'enabled' : 'disabled',
      },
    });
  }

  function confirmDeletePopupCallBack({ isCanceled }) {
    if (!isCanceled) {
      const filterId = filterToDelete.id;
      deleteQuickFilterServerRequest({ quickFilterId: filterId });
      toast.success(onConfirmQuickFilterDeleteRoastMessage);
      removeAppliedFilterFromAnalyze({ filter: filterToDelete });

      const properties = getFilterEventProperties({ filter: filterToDelete.filter });
      servicesStore.eventTracker.track({
        eventName: Events.quickFilters.deletedQuickFilter,
        properties: {
          ...properties,
          quickFilterType: quickFiltersCreationTypesLabel[filterToDelete.type],
        },
      });
    }
    setIsShowConfirmationPopup(false);
  }

  const columns = [
    {
      id: 'name',
      header: 'Name',
      sortable: true,
      cell: (item) => (
        <>
          <div
            className={tableStyles.rowWrapper}
            onClick={() => setSelectedQuickFilter(item)}
          >
            <EllipsisTooltip
              text={item.name}
              place="bottom"
              className={tableStyles.clickableItem}
            />
          </div>
        </>
      ),
    },
    {
      id: 'filters',
      header: 'Filters',
      sortable: false,
      cell: (item) => (
        <>
          <FilterTag
            key={`quick-filter-tag-${item.id}`}
            label={getQuickFilterTagLabel({ quickFilter: item.filter, filtersData })}
            onClick={() => {
              setSelectedQuickFilter(item);
              setStepIndexToOpen(1);
            }}
          />
        </>
      ),
    },
    {
      id: 'type',
      accessor: 'type',
      header: 'Type',
      sortable: true,
      cell: (type) => (
        <>
          <div className={classnames(tableStyles.rowWrapper, type === quickFiltersCreationTypes.ai && tableStyles.aiType)}>
            {quickFiltersCreationTypesLabel[type]}
          </div>
        </>
      ),
    },
    {
      id: 'status',
      header: 'Status',
      width: 100,
      cell: (item) => (
        <>
          <div className={tableStyles.switchCell}>
            <Switch
              onSwitchClick={() => onChangeStatus({ filter: item })}
              isActive={item.active}
              dataTestId={`switch-${item.id}`}
            />
          </div>
        </>
      ),
    },
    {
      id: 'delete',
      header: '',
      width: 100,
      cell: (item) => (
        <>
          {item.type !== quickFiltersCreationTypes.default && !item.id.includes('ai-recommendation') ? (
            <div
              data-testid="trashIcon"
              className={classnames(tableStyles.rowWrapper, tableStyles.icon, tableStyles.clickableItem)}
              onClick={() => {
                setFilterToDelete(item);
                setIsShowConfirmationPopup(true);
              }}
              data-icon="quickFilters:delete"
            />
          ) : null}
        </>
      ),
    },
  ];

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.title}>
            My Quick Filters
          </div>
          <div className={styles.headerActions}>
            <Button
              type="primaryBlue"
              onClick={() => {
                setSelectedQuickFilter({});
                servicesStore.eventTracker.track({
                  eventName: Events.quickFilters.clickedAddQuickFilter,
                });
              }}
            >
              + Quick Filter
            </Button>
          </div>
        </div>
        <div className={styles.description}>
          {customQuickFiltersDescription}
        </div>
        {isShowConfirmationPopup ? (
          <ConfirmPopup
            callback={(isCanceled) => confirmDeletePopupCallBack({ isCanceled })}
            confirmBtn="Delete"
            title={`Are you sure you want to delete ${filterToDelete.name}? `}
            description={confirmPopupDescription}
          />
        ) : null}
        <CardRowsTable
          data={[...customQuickFilters, ...suggestMoreAIRecommendationFilters]}
          columns={columns}
          isLoading={isLoading}
          wrapperClassname={tableStyles.cardRowsTableWrapper}
        />
      </div>
    </>
  );
}

export default inject(
  ({
    filterStore: {
      updateQuickFilterServerRequest,
      deleteQuickFilterServerRequest,
      removeAIRecommendationFilter,
      filtersData,
    } = {},
  }) => ({
    updateQuickFilterServerRequest,
    deleteQuickFilterServerRequest,
    removeAIRecommendationFilter,
    filtersData,
  }),
  observer
)(CustomQuickFilters);
