import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import userStore from 'stores/userStore';
import serverCommunication from 'data/serverCommunication';
import SaveFilterItem from 'components/pages/users/Filters/FilterPanel/UI/SaveFilterItem';

import { trackFilterEvents } from 'components/pages/users/Filters/logic/FilterActions';

import filterStyles from 'styles/users/filters.css';

const styles = filterStyles.locals;

function SavedFilter(props) {
  const [searchInputText, setSearchInputText] = useState('');
  const [savedFiltersList, setSavedFiltersList] = useState([...props.savedFilters]);
  const [searchMessageText, setSearchMessageText] = useState(`${savedFiltersList.length} saved filters`);

  useEffect(() => {
    setSearchMessageText(`${savedFiltersList.length} saved filters`);
  }, [savedFiltersList]);

  const onInputSearchChange = (e) => {
    const inputText = e.target.value;
    const filteredSavedFilters = props.savedFilters.filter((item) => item.alias.toLowerCase().includes(inputText.toLowerCase()));
    setSearchInputText(inputText);
    setSavedFiltersList(filteredSavedFilters);

    let messageTxt = `${filteredSavedFilters.length} saved filters`;

    if (props.savedFilters.length > filteredSavedFilters.length) {
      messageTxt = `We found ${filteredSavedFilters.length} saved filters matching “${inputText}"`;
    } else if (filteredSavedFilters.length < 1) {
      messageTxt = `We couldn't find any saved filters matching “${inputText}"`;
    }
    setSearchMessageText(messageTxt);
  };

  async function handleFilterApply({ filterName }) {
    const selectedFilterData = props.savedFilters.find((f) => f.alias === filterName);
    trackFilterEvents({ filterItems: selectedFilterData.filters, isSaved: true });
    await props.addFilterToSelection(selectedFilterData.filters, null, filterName, () => props.onAddFilter());
  }

  const popupEditHandler = (index, oldName, newName) => {
    const getFiltersList = [...savedFiltersList];
    getFiltersList[index].alias = newName;
    setSavedFiltersList(getFiltersList);

    serverCommunication.serverRequest('PUT', 'savedFilters', JSON.stringify({
      filterOldAlias: oldName,
      filterNewAlias: newName,
    }), userStore.userMonthPlan.region)
      .then((response) => {
        if (response.ok) {
          response.json()
            .then((data) => {
              setSavedFiltersList([...data.savedFilters]);
            });
        }
      });
  };

  const popupDeleteHandler = (item) => {
    setSavedFiltersList((prevFilters) => prevFilters.filter((filterName) => filterName !== item));
    serverCommunication.serverRequest('DELETE', 'savedFilters', JSON.stringify({
      filterAlias: item,
    }), userStore.userMonthPlan.region)
      .then((response) => {
        if (response.ok) {
          response.json()
            .then((data) => {
              setSavedFiltersList([...data.savedFilters]);
              props.updateSavedFiltersList([...data.savedFilters]);
            });
        }
      });
  };

  return (
    <div className={styles.filterConfig}>
      <div className={styles.filterContent}>
        <div className={styles.savedFilter}>
          <div className={styles.savedFilterSearch}>
            <div className={styles.savedFilterIcon} />
            <input
              className={styles.savedFilterInput}
              placeholder="Search saved filters"
              type="text"
              value={searchInputText}
              onChange={onInputSearchChange}
            />
          </div>
        </div>
        <div
          className={classnames(
            styles.savedViewCount,
            savedFiltersList.length < 1 && styles.savedViewCountCenter
          )}
        >
          {searchMessageText}
        </div>
        <div className={styles.savedViewItems}>
          {savedFiltersList.map((item, index) => {
            const savedFilterKey = `saved-filter-${item.alias}-${index}`;
            return (
              <SaveFilterItem
                popupDeleteHandler={popupDeleteHandler}
                popupEditHandler={popupEditHandler}
                handleFilterApply={({ filterName }) => handleFilterApply({ filterName })}
                item={item}
                index={index}
                key={savedFilterKey}
                savedFiltersList={savedFiltersList}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default SavedFilter;
