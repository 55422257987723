import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';
import { isEqual, isEmpty, get } from 'lodash';
import { inject, observer } from 'mobx-react';
import { Button } from '@infinigrow/libs';

import Component from 'components/Component';
import FilterPopup from 'components/pages/users/Filters/FilterPopup';
import FilterTag from 'components/pages/users/Filters/FilterPanel/FilterTag';

import { compose } from 'components/utils/utils';
import {
  FilterPropType, isUiFilter, makeFilter, beforeStageMap,
} from 'components/utils/filters';
import { filterKinds, isCostCampaignsFilter, isCostCustomFieldsFilter } from 'components/utils/filters/logic';
import { checkIfFilterDataExists } from 'components/pages/users/Filters/FilterPanel/logic/FiltersPanel';
import { Events } from 'trackers/analytics/enums';

import servicesStore from 'stores/servicesStore';

import filterStyles from 'styles/users/filters.css';

const enhance = compose(
  inject(({
    filterStore: {
      filtersData,
    } = {},
    userStore: {
      userMonthPlan: {
        CRMConfig: {
          predefinedFiltersConfig,
        } = {},
        savedFilters,
        UID,
        region,
      } = {},
    } = {},
  }) => ({
    filtersData,
    predefinedFiltersConfig,
    savedFilters,
    UID,
    region,
  })),
  observer
);

class FiltersPanel extends Component {
  static propTypes = {
    filters: PropTypes.arrayOf(FilterPropType).isRequired,
    setFilters: PropTypes.func.isRequired,
    buttonText: PropTypes.string,
  };

  static defaultProps = {
    buttonText: 'Add Filters',
  };

  style = filterStyles;

  constructor(props) {
    super(props);
    this.state = {
      showPopup: false,
      isTagPopup: false,
      filter: null,
      filtersItems: [],
      tagsToRemove: [],
      savedFilters: props.savedFilters || [],
    };
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.savedFilters, this.props.savedFilters)) {
      this.setState({ savedFilters: this.props.savedFilters });
    }
  }

  addFilter = (selectedFilters, filterConfigs) => {
    const { setFilters } = this.props;
    const { filters } = this.props;
    let filtersCopy = [...filters];
    if (this.state.filter && filters.length > 0) {
      filtersCopy = filters.filter((f) => f.id !== this.state.filter.id);
    }
    const filtersWithInvisible = [...filtersCopy];

    const notEmptyFilters = selectedFilters
      .filter(({ data, kind }) => (data.comparisonOperator || isCostCampaignsFilter(kind) || isCostCustomFieldsFilter(kind) || !isEmpty(data.selectedOptions)));
    const newFilters = notEmptyFilters
      .map(({ kind, data }) => makeFilter({ kind, data }, filterConfigs))
      .filter((newFilter) => newFilter && !filtersWithInvisible.find((f) => f.id === newFilter.id));

    const newFiltersAreUIOnly = newFilters.every((f) => f.isUiOnly);
    const combined = [...filters, ...newFilters];

    if (combined.length > 0) {
      setFilters(combined, !newFiltersAreUIOnly);
      this.hidePopup();
    }
  };

  openPopup = () => {
    servicesStore.eventTracker.track({
      eventName: Events.filtersAddFilter,
    });
    this.setState({ filtersItems: [] });
    this.setState({
      showPopup: true,
    });
  };

  hidePopup = () => {
    this.setState({ showPopup: false, filter: null, isTagPopup: false });
  };

  processFilter = ({ config: { kind }, data }) => ({ kind, data });

  showPopupWithTag = (filter) => {
    this.setState({
      filter,
      showPopup: true,
      isTagPopup: true,
      filtersItems: [],
    });
  };

  handleClear = () => {
    const isAllFiltersAreUIOnly = this.props.filters.every((filter) => filter.isUiOnly);
    this.setState({ filtersItems: [] });
    this.props.setFilters([], !isAllFiltersAreUIOnly);
  };

  handleClearMultipleTags = (id) => {
    const { filters, setFilters, clearTagsDebounce = 2000 } = this.props;
    const { tagsToRemove } = this.state;
    const tagsItemsClicked = [...tagsToRemove, id];
    this.setState({
      tagsToRemove: tagsItemsClicked,
    }, () => {
      if (this.tagsRemoveTimeout) {
        clearTimeout(this.tagsRemoveTimeout);
      }
      this.tagsRemoveTimeout = setTimeout(() => {
        const isRemovedOnlyUIFilters = this.state.tagsToRemove.every((filterId) => {
          const filterObject = filters.find((filter) => filter.id === filterId);
          return filterObject?.isUiOnly;
        });
        const filteredItems = filters.filter((filter) => !this.state.tagsToRemove.includes(filter.id));
        setFilters(filteredItems, !isRemovedOnlyUIFilters);
        this.setState({ tagsToRemove: [] });
      }, clearTagsDebounce);
    });
  };

  setFiltersItems = (filter, shouldRemoveBeforeFunnelFilter) => {
    const { filtersItems } = this.state;
    const filterIndex = filtersItems.findIndex((element) => element.kind === filter.kind);
    const isFilterDataExists = checkIfFilterDataExists(filter.data);

    if (isFilterDataExists) {
      if (filterIndex === -1) {
        filtersItems.push(filter);
      } else {
        filtersItems[filterIndex] = filter;
      }
    } else if (filterIndex !== -1) {
      filtersItems.splice(filterIndex, 1);
    }

    if (shouldRemoveBeforeFunnelFilter) {
      const filterKind = beforeStageMap[filter.kind]();
      const filterBeforeStageIndex = filtersItems.findIndex((element) => element.kind === filterKind);
      if (filterBeforeStageIndex !== -1) {
        filtersItems.splice(filterBeforeStageIndex, 1);
      }
    }
    this.setState({ filtersItems });
  };

  render() {
    const {
      filters,
      predefinedFiltersConfig,
      isSaveFiltersButton,
      filtersData = [],
      buttonText,
      addFilterButtonClassName,
      filterTagClassName,
      disabled,
    } = this.props;
    const { showPopup, filter, savedFilters } = this.state;
    const filtersWithInvisible = [...filters];

    let configs = filtersData;
    if (isEmpty(predefinedFiltersConfig)) {
      configs = configs.filter(({ kind }) => kind !== filterKinds.PRE_DEFINED);
    }
    if (!isEmpty(savedFilters)) {
      const savedFilter = {
        kind: filterKinds.SAVED,
        options: savedFilters.map((f) => get(f, 'alias', '')),
        fieldKey: 0,
      };
      configs = [...configs, savedFilter];
    }

    return (
      <div className={classnames(this.classes.panel)}>
        <div className={this.classes.filterTags}>
          <Button
            type="primaryBlue"
            onClick={() => this.openPopup()}
            className={addFilterButtonClassName}
            disabled={disabled}
            dataTestId="addFilters"
          >
            <div className={this.classes.addFilterButtonIcon} />
            {buttonText}
          </Button>
          {filtersWithInvisible.length > 1 && (
            <div className={classnames(this.classes.filterPlainLabel, disabled && this.classes.clearAllDisabled)} onClick={disabled ? null : this.handleClear}>
              Clear all
            </div>
          )}
          {filtersWithInvisible.map((filterItem) => {
            const isUiOnly = isUiFilter(filterItem.data);
            const kind = get(filterItem, 'config.kind', '');
            const isCustomFieldsFilter = isCostCustomFieldsFilter(kind);
            const selectedOptions = isCustomFieldsFilter ? get(filterItem, 'data.rules', []) : get(filterItem, 'data.selectedOptions', []);

            return (
              <FilterTag
                key={filterItem.id}
                label={filterItem.label}
                selectedOptions={selectedOptions}
                kind={kind}
                onRemove={() => this.handleClearMultipleTags(filterItem.id)}
                onClick={() => this.showPopupWithTag(filterItem)}
                isUiFilter={isUiOnly}
                className={classnames(isSaveFiltersButton ? this.classes.reportsFilterTag : null, filterTagClassName)}
                isDisabled={filterItem.isDisabled || disabled}
                colorTheme={filterItem.filterTagColorTheme}
              />
            );
          })}

        </div>
        {showPopup && (
          <FilterPopup
            opened
            onClose={this.hidePopup}
            updateSavedFiltersList={(newFilters) => this.setState({ savedFilters: newFilters })}
            filterConfigs={configs}
            onFilterAdd={(selectedFilters) => this.addFilter(selectedFilters, configs)}
            savedFilters={savedFilters}
            filterFromTag={filter ? this.processFilter(filter) : null}
            isTagPopup={this.state.isTagPopup}
            setFiltersItems={this.setFiltersItems}
            filtersItems={this.state.filtersItems}
            localSavedFilters={filters}
            className={this.props.filtersPopupStyle}
          />
        )}
      </div>
    );
  }
}

export default enhance(FiltersPanel);
