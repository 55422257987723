export function getFilterKind({ kind }) {
  const map = {
    channelBeforeStages: 'channels',
    campaignBeforeStages: 'campaigns',
    campaignTags: 'campaigns',
    contentBeforeStages: 'content',
    contentChannelBeforeStages: 'content',
  };
  return map[kind] || kind;
}
