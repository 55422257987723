exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".ActionPopup-module__wrapper___Ui5tD{display:flex;flex-direction:column;gap:24px;font-size:16px}.ActionPopup-module__wrapper___Ui5tD h4{margin-bottom:0}.ActionPopup-module__buttonsWrapper___voZcn{display:flex;justify-content:space-between;gap:16px;width:100%}.ActionPopup-module__buttonsWrapper___voZcn .ActionPopup-module__primaryButtonWrapper___1hXW4{display:flex;gap:8px;align-items:center}", ""]);

// exports
exports.locals = {
	"wrapper": "ActionPopup-module__wrapper___Ui5tD",
	"buttonsWrapper": "ActionPopup-module__buttonsWrapper___voZcn",
	"primaryButtonWrapper": "ActionPopup-module__primaryButtonWrapper___1hXW4"
};