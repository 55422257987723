exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")();
// imports


// module
exports.push([module.id, ".quickFilterPopup__pageContainer-2iw>div>div{width:600px}.quickFilterPopup__wrapper-269{display:flex;flex-direction:column;align-self:stretch}.quickFilterPopup__wrapperSpaces-2wh{gap:20px}.quickFilterPopup__row-26M{display:flex;align-items:center;gap:8px}.quickFilterPopup__controllerWrapper-210{width:100%}.quickFilterPopup__controllerTitle-2Qv{color:var(--colorsTextsTextDarkGray);font-size:12px;font-weight:600}.quickFilterPopup__titleGap-3lZ{padding-bottom:12px}.quickFilterPopup__additionalConfigurationText-1z_{color:var(--colorsGeneralIgBlack);font-size:14px;font-weight:500}.quickFilterPopup__additionalConfigurationComplexText-15W{display:flex;width:100%;gap:4px}.quickFilterPopup__rowWithDropdown-3r_{height:45px}.quickFilterPopup__relatedObjectsLabel-gnb{display:flex;white-space:nowrap;gap:4px}.quickFilterPopup__relatedObjectsEllipsis-3KK{font-weight:600;max-width:120px}.quickFilterPopup__toggleBox-1vg{width:100%}.quickFilterPopup__toggleBox-1vg>div{flex:1}.quickFilterPopup__bold-1AL{font-weight:600}", ""]);

// exports
exports.locals = {
	"pageContainer": "quickFilterPopup__pageContainer-2iw",
	"pageContainer": "quickFilterPopup__pageContainer-2iw",
	"wrapper": "quickFilterPopup__wrapper-269",
	"wrapper": "quickFilterPopup__wrapper-269",
	"wrapperSpaces": "quickFilterPopup__wrapperSpaces-2wh",
	"wrapperSpaces": "quickFilterPopup__wrapperSpaces-2wh",
	"row": "quickFilterPopup__row-26M",
	"row": "quickFilterPopup__row-26M",
	"controllerWrapper": "quickFilterPopup__controllerWrapper-210",
	"controllerWrapper": "quickFilterPopup__controllerWrapper-210",
	"controllerTitle": "quickFilterPopup__controllerTitle-2Qv",
	"controllerTitle": "quickFilterPopup__controllerTitle-2Qv",
	"titleGap": "quickFilterPopup__titleGap-3lZ",
	"titleGap": "quickFilterPopup__titleGap-3lZ",
	"additionalConfigurationText": "quickFilterPopup__additionalConfigurationText-1z_",
	"additionalConfigurationText": "quickFilterPopup__additionalConfigurationText-1z_",
	"additionalConfigurationComplexText": "quickFilterPopup__additionalConfigurationComplexText-15W",
	"additionalConfigurationComplexText": "quickFilterPopup__additionalConfigurationComplexText-15W",
	"rowWithDropdown": "quickFilterPopup__rowWithDropdown-3r_",
	"rowWithDropdown": "quickFilterPopup__rowWithDropdown-3r_",
	"relatedObjectsLabel": "quickFilterPopup__relatedObjectsLabel-gnb",
	"relatedObjectsLabel": "quickFilterPopup__relatedObjectsLabel-gnb",
	"relatedObjectsEllipsis": "quickFilterPopup__relatedObjectsEllipsis-3KK",
	"relatedObjectsEllipsis": "quickFilterPopup__relatedObjectsEllipsis-3KK",
	"toggleBox": "quickFilterPopup__toggleBox-1vg",
	"toggleBox": "quickFilterPopup__toggleBox-1vg",
	"bold": "quickFilterPopup__bold-1AL",
	"bold": "quickFilterPopup__bold-1AL"
};