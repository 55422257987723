import { theme } from '@infinigrow/libs';

import { filterTagsColorThemeOptions } from 'components/pages/users/Filters/FilterPanel/enums';

export function getFilterRemoveIconColor({ isSelectedValues, type }) {
  if (!isSelectedValues) {
    return '';
  }

  if (type === filterTagsColorThemeOptions.orange) {
    return theme.colors.general.orange;
  }

  if (type === filterTagsColorThemeOptions.ai) {
    return theme.colors.general.purple;
  }

  if (type === filterTagsColorThemeOptions.error) {
    return theme.colors.inputs.errorRed;
  }

  if (type === filterTagsColorThemeOptions.default) {
    return theme.colors.general.blue;
  }

  return theme.colors.texts.IgGray;
}
