import React from 'react';

import userStore from 'stores/userStore';

export const numberWithZeroDefault = (getter) => (arg, ...other) => getter(arg, ...other) || 0;

export const getMappingOptions = (idToLabelMap = {}) => {
  const mappingOptions = [];
  for (const [id, label] of Object.entries(idToLabelMap)) {
    const optionObject = { value: id, label };
    const customSegmentMapping = userStore.userAccount?.customSegmentsMapping?.[id];
    if (customSegmentMapping) {
      optionObject.disabled = !customSegmentMapping.enabled;
      optionObject.icon = (<img alt="customSegment" src="/assets/analyze-icons/custom-segment.svg" />);
    }
    mappingOptions.push(optionObject);
  }

  return mappingOptions;
};

export function getGroupedUserMetrics({ userMetrics = [] } = {}) {
  const groupLabels = {};
  const groups = {};
  const { isPipelineStageHidden } = userStore.userMonthPlan || {};

  for (const metricData of userMetrics) {
    const {
      relevantFor, title, metricName, isRelevantForTarget, nickname, enabled, metricType,
    } = metricData;

    if (isPipelineStageHidden && metricName.toLowerCase().includes('pipeline')) {
      continue;
    }

    const optionItem = {
      label: nickname,
      value: metricName,
      enabled,
      metricType,
      relevantFor,
    };

    const relevantForValue = relevantFor || metricName;
    if (!groups[relevantForValue]) {
      groups[relevantForValue] = [];
    }

    groups[relevantForValue].push(optionItem);

    if (isRelevantForTarget || relevantForValue === metricName) {
      groupLabels[relevantForValue] = title;
    }
  }

  const groupedMetrics = Object.entries(groups).map(([groupName, options]) => {
    const label = groupLabels[groupName] || groupName;
    return {
      label,
      groupName,
      options,
    };
  });
  return groupedMetrics;
}

export function camelCaseToReadable({ camelCaseString }) {
  if (typeof camelCaseString !== 'string') {
    return '';
  }

  const isStringAlreadyReadable = /\s/.test(camelCaseString);
  if (isStringAlreadyReadable) {
    return camelCaseString;
  }

  return camelCaseString
  // Insert a space before all caps
    .replace(/([A-Z])/g, ' $1')
  // Insert a space before numbers only if preceded by a letter
    .replace(/([a-zA-Z])(\d)/g, '$1 $2')
  // Remove the first space if it exists
    .replace(/^ /, '')
  // Handle consecutive uppercase letters (like in acronyms)
    .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
  // Capitalize the first letter
    .replace(/^./, (str) => str.toUpperCase());
}

export function getUniqueTitle({ baseTitle, listOfNames }) {
  let copyNumber = 1;
  let newTitle = `${baseTitle} (${`${copyNumber}`.padStart(2, '0')})`;

  while (listOfNames.includes(newTitle)) {
    copyNumber++;
    newTitle = `${baseTitle} (${`${copyNumber}`.padStart(2, '0')})`;
  }

  return newTitle;
}
