import { allFunnelStagesOption, accountsFunnelStageOption } from 'components/utils/filters/enums';

export const getUpdateSelectedFunnels = (items) => {
  const accountsFunnelStage = accountsFunnelStageOption.value;
  const allFunnelStages = allFunnelStagesOption.value;
  const selectedLastItem = items[items.length - 1];

  if (selectedLastItem?.value !== accountsFunnelStage && selectedLastItem?.value !== allFunnelStages) {
    return items.filter((item) => item?.value !== accountsFunnelStage && item?.value !== allFunnelStages);
  }

  return [selectedLastItem];
};
