import React, { useRef } from 'react';
import { Link } from 'react-router';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import classnames from 'classnames';

import useStyles from 'hooks/useStyles';

import FeatureFlags from 'components/common/FeatureFlags';
import ButtonWithOptions from 'components/common/ButtonWithOptions';
import BetaTag from 'components/common/BetaTag';

import { subMenuTabsNamesWithBetaTag } from 'components/enums';
import { checkIfShowMenuTab } from 'components/logic/headerTabsMenu';
import { tabsToFeatureFlags } from 'enums';

import style from 'styles/header.css';

const styles = style.locals || {};

function HeaderTabsMenu({
  tabs,
  flags,
}) {
  useStyles([style]);

  const buttonWithOptionsRef = useRef();

  function checkIfSubMenuTabActive({ routes }) {
    return routes?.some((route) => window.location.pathname.includes(route.path));
  }

  return (
    <>
      {tabs.map(({ name, path, childRoutes }) => {
        const childRoutesByFlags = childRoutes?.filter((childRoute) => flags[tabsToFeatureFlags[childRoute.path]] ?? true);
        const shouldShowTab = checkIfShowMenuTab({ path, childRoutes: childRoutesByFlags, flags });

        return (
          <FeatureFlags key={`FeatureFlags-${name}-${path}`} flag={shouldShowTab}>
            {childRoutesByFlags?.length > 0 ? (
              <ButtonWithOptions
                ref={buttonWithOptionsRef}
                options={childRoutesByFlags.map((childRoute) => ({
                  name: childRoute.tabName,
                  path: childRoute.path,
                }))}
                renderMenuOption={({ name: childName, path: childPath }) => (
                  <Link
                    to={childPath}
                    activeClassName={styles.subMenuItemSelected}
                    className={styles.subMenuItem}
                    key={`${childName}-${childPath}`}
                    onClick={() => {
                      if (buttonWithOptionsRef.current) {
                        buttonWithOptionsRef.current.closePopup();
                      }
                    }}
                    onlyActiveOnIndex
                  >
                    {childName}
                    <BetaTag
                      dataTestId="sub-menu-beta-tag"
                      hidden={!subMenuTabsNamesWithBetaTag.includes(childName)}
                    />
                  </Link>
                )}
                buttonTitle={name}
                menuClassName={classnames(
                  styles.subMenuDropdown,
                  styles.headTab,
                  checkIfSubMenuTabActive({ routes: childRoutes }) && styles.headTabSelected
                )}
                popupClassName={styles.subMenuDropdownPopup}
              />
            ) : (
              <Link
                to={path}
                activeClassName={styles.headTabSelected}
                className={styles.headTab}
                key={`${name}-${path}`}
                onlyActiveOnIndex
              >
                {name}
                <BetaTag
                  hidden={!subMenuTabsNamesWithBetaTag.includes(name)}
                />
              </Link>
            )}
          </FeatureFlags>
        );
      })}
    </>
  );
}

export default withLDConsumer()(HeaderTabsMenu);
