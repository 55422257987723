import React, { useEffect, useState } from 'react';
import { Button } from '@infinigrow/libs';

import useStyles from 'hooks/useStyles';

import SuggestionItem from 'components/pages/questions/SuggestionItem';
import Skeleton from 'components/common/Skeleton';

import { maxOptionsOfSuggestionsResults } from 'components/pages/questions/enums';
import { skeletonListRandomWidth } from 'components/common/enums';

import style from 'styles/questions/suggestions.css';

const styles = style.locals || {};

function Suggestions({
  data = [],
  onSelectSuggestion,
  displayShowMoreSuggestionsButton = true,
  isShowHeaderTitle = true,
  selectedSuggestions,
  isShowCheckboxes,
  onUpdatePlaceHolderValue,
}) {
  const [isShowAllData, setIsShowAllData] = useState(false);

  useStyles([style]);

  useEffect(() => {
    setIsShowAllData(data?.length <= maxOptionsOfSuggestionsResults);
  }, [data]);

  const suggestionsListToShow = isShowAllData ? data : data.slice(0, maxOptionsOfSuggestionsResults);

  return (
    <div>
      {isShowHeaderTitle ? (
        <div className={styles.head}>
          <div className={styles.suggestionsIcon} />
          <div className={styles.title}>
            Suggestions
            <div className={styles.betaTag}>Beta</div>
          </div>
        </div>
      ) : null}

      {suggestionsListToShow.length > 0 ? (
        <>
          <div className={styles.suggestionsList}>
            {suggestionsListToShow.map((suggestion, index) => {
              const suggestionItemKey = `${suggestion.questionText}-${index}`;
              return (
                <SuggestionItem
                  data={suggestion}
                  key={suggestionItemKey}
                  onSelectSuggestion={onSelectSuggestion}
                  selectedSuggestions={selectedSuggestions}
                  isShowCheckboxes={isShowCheckboxes}
                  onUpdatePlaceHolderValue={onUpdatePlaceHolderValue}
                />
              );
            })}
          </div>
          {displayShowMoreSuggestionsButton && (isShowAllData ? null : (
            <Button
              type="secondaryBlue"
              onClick={() => setIsShowAllData(true)}
              className={styles.showMore}
            >
              Show more
            </Button>
          ))}
        </>
      ) : (
        <div className={styles.suggestionsSkeletonList}>
          {skeletonListRandomWidth.map((width) => (
            <Skeleton key={width} width={400 * width} height={24} />
          ))}
        </div>
      )}
    </div>
  );
}

export default Suggestions;
