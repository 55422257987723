import { tabsToFeatureFlags } from 'enums';

export function checkIfShowMenuTab({ path, childRoutes, flags = {} }) {
  if (!path && !childRoutes) {
    return false;
  }
  if (!path && childRoutes.length > 0) {
    return childRoutes.some((childRoute) => checkIfShowMenuTab(childRoute));
  }
  return flags[tabsToFeatureFlags[path]] ?? true;
}
