import React, { useState } from 'react';
import classnames from 'classnames';
import { inject, observer } from 'mobx-react';
import { Button } from '@infinigrow/libs';

import useStyles from 'hooks/useStyles';

import Page from 'components/Page';
import Textfield from 'components/controls/Textfield';
import Tooltip from 'components/controls/Tooltip';
import Dropdown from 'components/controls/Dropdown';

import style from 'styles/common/popupWithDrawerSteps.css';

const styles = style.locals || {};

function PopupWithDrawerSteps({
  onClose,
  title,
  updateTitle,
  isTitleEditable = true,
  titlePlaceholder,
  isShowBusinessUnitSelection,
  region,
  onClickSave,
  userRegionsNicknames,
  getRegionValue,
  steps = [],
  onContinue = () => {},
  className,
  stepIndexToOpen = 0,
  disabled,
}) {
  useStyles([style]);

  const [selectedRegion, setSelectedRegion] = useState(region);
  const [stepIndexToBeOpen, setStepIndexToBeOpen] = useState(stepIndexToOpen);

  const minCharToShowTooltip = 30;

  return (
    <Page className={classnames(styles.pageWrapper, className)} popup>
      <div className={styles.wrapper} data-testid="popupWithDrawerSteps">
        <div className={styles.title}>
          <div
            data-testid="closeIcon"
            className={styles.closeButton}
            onClick={onClose}
          />
          {title ? (
            <Tooltip id="tooltip-actionName" tip={title.name} hidden={title.name.length < minCharToShowTooltip}>
              <Textfield
                dataTestId="popupWithDrawerStepsTitle"
                value={title.name}
                inputClassName={styles.textFieldInput}
                placeHolder={titlePlaceholder}
                isShowError={title.error !== ''}
                inputErrorMessage={title.error}
                onChange={(event) => updateTitle({ newTitle: event.target.value })}
                disabled={!isTitleEditable || disabled}
              />
            </Tooltip>
          ) : null}
        </div>

        {isShowBusinessUnitSelection ? (
          <div className={styles.row}>
            <div className={styles.text}>
              Business unit
            </div>
            <Dropdown
              dataTestId="popupWithDrawerStepsRegionDropdown"
              selectedKey={selectedRegion}
              options={userRegionsNicknames.map((userRegion) => ({ value: getRegionValue({ regionNickname: userRegion }), label: userRegion }))}
              onChange={(newRegion) => setSelectedRegion(newRegion.value)}
              controlWidth={354}
              disabled={disabled}
            />
          </div>
        ) : null}

        {steps.map((step, stepIndex) => (
          <div className={classnames(styles.stepBodySection, stepIndexToBeOpen === stepIndex && styles.stepBodySectionOpen)} key={step.title}>
            <div
              className={styles.stepBodyTitleWrapper}
              onClick={() => setStepIndexToBeOpen(stepIndex)}
            >
              <div
                className={classnames(styles.stepBodyTitle, stepIndexToBeOpen === stepIndex ? null : styles.collapsedTitle)}
              >
                {step.title}
              </div>
              {step.isValid && !step.hideCheckedIcon ? (<div data-testid="checkedIconGreen" className={styles.checkedIconGreen} />) : null}
            </div>
            {stepIndexToBeOpen === stepIndex ? (
              <>
                {step.content}
                {step.isShowContinueButton ? (
                  <Button
                    type="primaryBlue"
                    onClick={() => {
                      setStepIndexToBeOpen(stepIndex + 1);
                      onContinue({ stepIndex });
                    }}
                    disabled={!step.isValid}
                  >
                    Continue
                  </Button>
                ) : null}
              </>
            ) : null}
          </div>
        ))}

        <div className={styles.footerButtons}>
          <Button
            type="secondaryBlue"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            type="primaryBlue"
            onClick={() => onClickSave({ newRegion: selectedRegion })}
            disabled={title.name === '' || steps.some((step) => !step.isValid || disabled)}
          >
            Save
          </Button>
        </div>
      </div>
    </Page>
  );
}

export default inject(
  ({
    userStore: {
      userRegionsNicknames,
      getRegionValue,
    } = {},
  }) => ({
    userRegionsNicknames,
    getRegionValue,
  }),
  observer
)(PopupWithDrawerSteps);
