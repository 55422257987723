import base from './base';

function getWidgetConfig({ widgetConfig } = {}) {
  if (!widgetConfig) {
    return widgetConfig;
  }

  const { trendAnalysisParams } = widgetConfig;

  if (typeof trendAnalysisParams.frequency === 'string') {
    return {
      ...widgetConfig,
      trendAnalysisParams: {
        ...trendAnalysisParams,
        frequency: parseInt(trendAnalysisParams.frequency, 10),
      },
    };
  }

  return widgetConfig;
}

export default {
  ...base,
  getWidgetConfig,
};
