import React from 'react';
import filterStyles from 'styles/users/filters.css';
import classnames from 'classnames';
import { Button } from '@infinigrow/libs';

import { trackFilterEvents } from 'components/pages/users/Filters/logic/FilterActions';

const styles = filterStyles.locals;

function FilterActions({
  onApply, onCancel, setCustomDate, showCustomDate, filtersItems = [], disabled,
}) {
  return (
    <footer className={styles.filterActions}>
      <div className={styles.row}>
        <Button
          className={classnames(styles.filterActionsButtonWrap, styles.filterActionSecondary)}
          containerClassName={styles.filterActionsButton}
          type="secondaryWhite"
          onClick={() => onCancel()}
        >
          Cancel
        </Button>
        {showCustomDate && (
        <Button
          className={classnames(
            styles.filterActionsButtonWrap,
            styles.filterActionSecondary,
            styles.filterActionsCustomDate
          )}
          containerClassName={styles.filterActionsButton}
          type="secondaryWhite"
          onClick={() => setCustomDate()}
        >
          Custom Date
        </Button>
        )}
      </div>
      <Button
        type="primaryBlue"
        className={styles.filterActionsButtonWrap}
        containerClassName={styles.filterActionsButton}
        onClick={() => {
          trackFilterEvents({ filterItems: filtersItems });
          onApply();
        }}
        disabled={!filtersItems.length || disabled}
      >
        {`Apply ${filtersItems.length > 0 ? filtersItems.length : ''} filters`}
      </Button>
    </footer>
  );
}

export default FilterActions;
