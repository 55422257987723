import React, { useEffect } from 'react';

import useStyles from 'hooks/useStyles';

import Dropdown from 'components/controls/Dropdown';
import Anomaly from 'components/pages/settings/actions/actionPopup/triggerSection/anomaly';
import GoalsTrigger from 'components/pages/settings/actions/actionPopup/triggerSection/goalsTrigger';
import AccountEngagementTrigger from 'components/pages/settings/actions/actionPopup/triggerSection/accountEngagementTrigger';

import {
  actionTriggerTypesOptions, actionTriggerTypes, emptyNotification,
} from 'components/pages/settings/actions/enums';
import { isTriggerRulesAreValid } from 'components/pages/settings/actions/logic/actions';

import style from 'styles/settings/actions/actionPopup.css';

const styles = style.locals || {};

export default function TriggerSection({
  triggerType,
  setTriggerType,
  triggerRules,
  setTriggerRules,
  setNotifications,
  isActionsAreValid,
  setIsActionsAreValid,
}) {
  useStyles([style]);

  useEffect(() => {
    const isNewTriggerRulesAreValid = isTriggerRulesAreValid({ triggerType, triggerRules });
    if (isNewTriggerRulesAreValid !== isActionsAreValid) {
      setIsActionsAreValid(isNewTriggerRulesAreValid);
    }
  }, [triggerRules, triggerType]);

  function onChangeTriggerType(newTriggerType) {
    setTriggerType(newTriggerType);
    setTriggerRules([]);
    setNotifications([emptyNotification]);
  }

  return (
    <>
      <div className={styles.row}>
        <div className={styles.text}>
          Trigger type
        </div>
        <Dropdown
          selectedKey={triggerType}
          options={actionTriggerTypesOptions}
          onChange={(newTriggerType) => onChangeTriggerType(newTriggerType.value)}
          controlWidth={300}
          placeholder="Select"
        />
      </div>
      {triggerType === actionTriggerTypes.anomaly ? (
        <Anomaly
          anomalyData={triggerRules}
          updateAnomalyData={(newAnomalyData) => setTriggerRules(newAnomalyData)}
        />
      ) : null}

      {triggerType === actionTriggerTypes.goalsTracking ? (
        <GoalsTrigger
          triggerRules={triggerRules}
          updateTriggerRules={(newTriggerRules) => setTriggerRules(newTriggerRules)}
        />
      ) : null}

      {triggerType === actionTriggerTypes.accountEngagement ? (
        <AccountEngagementTrigger
          triggerRules={triggerRules}
          updateTriggerRules={(newTriggerRules) => setTriggerRules(newTriggerRules)}
        />
      ) : null}

    </>
  );
}
