import React from 'react';
import classnames from 'classnames';
import filterStyles from 'styles/users/filters.css';

const styles = filterStyles.locals || {};

function ArrowToggle(props) {
  const isToggleOpen = props.isToggleOpen;

  return (
    <div className={classnames(styles.arrowContiner)}>
      <div className={classnames(isToggleOpen ? styles.arrowDown : styles.arrowRight, styles.arrow)} onClick={props.onToggleClick} />
    </div>
  );
}
export default ArrowToggle;
