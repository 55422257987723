import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { inject, observer } from 'mobx-react';

import { Events } from 'trackers/analytics/enums';

import ConfirmPopup from 'components/ConfirmPopup';
import CardRowsTable from 'components/common/CardRowsTable';
import EllipsisTooltip from 'components/controls/EllipsisTooltip';
import Switch from 'components/controls/Switch';
import Tooltip from 'components/controls/Tooltip';
import servicesStore from 'stores/servicesStore';

import { notificationTypesTooltips, actionTriggerTypesLabels, notificationTypesLabels } from 'components/pages/settings/actions/enums';
import { getAlertErrorTooltipMessage } from 'components/pages/settings/actions/logic/actions';

import style from 'styles/settings/actions/actionsTable.css';

const styles = style.locals || {};

function ActionsTable({
  actions,
  deleteActionRequest,
  isMultiRegionsAccount,
  setSelectedAction,
  updateActionRequest,
}) {
  const [isShowConfirmationPopup, setIsShowConfirmationPopup] = useState(false);
  const [actionToDelete, setActionToDelete] = useState({});

  useEffect(() => {
    style.use();
    return () => {
      if (!style.unuse) {
        return;
      }
      style.unuse();
    };
  }, []);

  function onChangeStatus({ action }) {
    const isActive = !action.active;
    updateActionRequest({ actionId: action.actionId, properties: { active: isActive } });
    servicesStore.eventTracker.track({
      eventName: Events.alertsToggleStatus,
      properties: {
        status: isActive ? 'enabled' : 'disabled',
      },
    });
  }

  function confirmDeletePopupCallBack({ isCanceled }) {
    if (!isCanceled) {
      const actionId = actionToDelete.id;
      deleteActionRequest({ actionId });

      const actionToDeleteData = actions.find((action) => action.actionId === actionId);
      servicesStore.eventTracker.track({
        eventName: Events.alertsDeletedAlert,
        properties: {
          triggerType: actionTriggerTypesLabels[actionToDeleteData.triggerType],
          alertType: notificationTypesLabels[actionToDeleteData.notifications[0].notificationType],
          frequency: actionToDeleteData.frequency,
        },
      });
    }
    setIsShowConfirmationPopup(false);
  }

  let columns = [
    {
      id: 'name',
      header: 'Name',
      sortable: true,
      cell: (item) => (
        <>
          <div
            className={styles.rowWrapper}
            onClick={() => setSelectedAction(item)}
          >
            <EllipsisTooltip
              text={item.name}
              place="bottom"
              className={styles.clickableItem}
              onClick={() => setSelectedAction(item)}
            />
          </div>
        </>
      ),
    },
    {
      id: 'type',
      header: 'Type',
      cell: (item) => {
        const notificationTypes = new Set();
        for (const notification of item?.notifications || []) {
          notificationTypes.add(notification.notificationType);
        }

        return (
          <>
            <div className={styles.rowWrapper} onClick={() => setSelectedAction(item)}>
              {Array.from(notificationTypes).map((notificationType) => (
                <Tooltip
                  id="notificationTypes"
                  tip={notificationTypesTooltips[notificationType]}
                >
                  <div className={styles.icon} data-icon={`userActions:${notificationType}`} data-testid="notificationTypeIcon" />
                </Tooltip>
              ))}
            </div>
          </>
        );
      },
    },
    {
      id: 'trigger',
      header: 'Trigger',
      sortable: true,
      cell: (item) => (
        <>
          <div className={styles.rowWrapper} onClick={() => setSelectedAction(item)}>
            {actionTriggerTypesLabels[item.triggerType]}
          </div>
        </>
      ),
    },
    {
      id: 'bu',
      accessor: 'region',
      header: 'BU',
      sortable: true,
      cell: (region) => (
        <>
          <div className={styles.rowWrapper}>
            {region}
          </div>
        </>
      ),
    },
    {
      id: 'createdBy',
      accessor: 'createdBy',
      header: 'Created by',
      sortable: true,
      cell: (createdBy) => (
        <>
          <div className={styles.rowWrapper}>
            {createdBy}
          </div>
        </>
      ),
    },
    {
      id: 'status',
      header: 'Status',
      width: 100,
      cell: (item) => (
        <>
          {item.error ? (
            <Tooltip id="outOfSync" tip={getAlertErrorTooltipMessage({ alert: item })}>
              <div className={classnames(styles.switchCell, styles.syncError)} data-icon="syncError" />
            </Tooltip>
          ) : (
            <div className={styles.switchCell}>
              <Switch
                onSwitchClick={() => onChangeStatus({ action: item })}
                isActive={item.active}
              />
            </div>
          )}
        </>
      ),
    },
    {
      id: 'delete',
      header: '',
      width: 100,
      cell: (item) => (
        <>
          <div
            data-testid="trashIcon"
            className={classnames(styles.rowWrapper, styles.icon, styles.clickableItem)}
            onClick={() => {
              setActionToDelete({ id: item.actionId, name: item.name });
              setIsShowConfirmationPopup(true);
            }}
            data-icon="userActions:delete"
          />
        </>
      ),
    },
  ];

  if (!isMultiRegionsAccount) {
    columns = columns.filter((column) => column.id !== 'bu');
  }

  return (
    <>
      {isShowConfirmationPopup && (
      <ConfirmPopup
        callback={(isCanceled) => confirmDeletePopupCallBack({ isCanceled })}
        confirmBtn="Delete"
        title={`Are you sure you want to delete ${actionToDelete.name}? `}
      />
      )}
      <CardRowsTable
        data={actions}
        columns={columns}
      />
    </>
  );
}

export default inject(
  ({
    userStore: {
      deleteActionRequest,
      updateActionRequest,
    } = {},
  }) => ({
    deleteActionRequest,
    updateActionRequest,
  }),
  observer
)(ActionsTable);
