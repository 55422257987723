import React from 'react';
import { inject, observer } from 'mobx-react';
import groupBy from 'lodash/groupBy';

import useStyles from 'hooks/useStyles';
import SelectTimeFrameWithCustom from 'components/controls/SelectTimeFrameWithCustom';
import ChannelIcon from 'components/common/ChannelIcon';
import SearchableDropdownPopup from 'components/pages/questions/SearchableDropdownPopup';

import { suggestionsDropdownTypes, suggestionsTypesToFilterKind, suggestionsDropdownStyle } from 'components/pages/questions/enums';
import {
  getChannelIcon, getCategoryIcon, getChannelNickname, getChannelsWithNicknames,
} from 'components/utils/filters/channels';
import { getSegmentsOptionsFields } from 'components/pages/analyze/OverviewTab/logic';
import { groupBySegmentsOptions } from 'components/pages/analyze/SegmentsTab/logic/segments';
import { getMappingOptions } from 'components/utils/logic/utils';
import { dateFormat, checkIfDate } from 'utils';
import { getConversionRateAndVelocityOptions } from 'components/pages/analyze/logic/Tabs';
import { FREQUENCY_OPTIONS } from 'components/utils/frequency';
import { budgetChangeOperatorsOptions, timeFrameOptions } from 'components/widgets/whatIf/enums';
import { getBaseFunnelAndKpiStages } from 'components/widgets/whatIf/logic/whatIfScenarios';
import { getCostPerAndROIMetricsOptions } from 'components/pages/analyze/OverviewTab/logic/HistoricalPerformance';

import style from 'styles/questions/suggestions.css';
import analyzeStyle from 'styles/analyze/analyze.css';
import selectStyle from 'styles/controls/select.css';

const styles = style.locals || {};
const selectStyles = selectStyle.locals || {};

function SuggestionPlaceholderDropdown({
  type,
  text,
  customFieldsIdToLabelMap,
  customUtmsWhitelist,
  getMetricOptions,
  leadSourcesIdToLabelMap,
  onChange,
  filtersData,
  getFilterKindOptions,
}) {
  useStyles([style, analyzeStyle, selectStyle]);

  const {
    dropdownControlText, dropdownIndicatorWrapper, dropdownIndicatorArrow,
  } = suggestionsDropdownStyle;

  if (type === suggestionsDropdownTypes.timeframe) {
    return (
      <div className={styles.timeframeDropdown}>
        <SelectTimeFrameWithCustom
          widgetTimeFrame={text}
          updateWidgetTimeframe={(newTimeframe) => onChange({ placeHolderValue: newTimeframe })}
          dropdownClassName={styles.suggestionDropdownLink}
          isShowShortLabel
          menuMaxWidth="240px"
          customControlTextStyles={dropdownControlText}
          customDropdownIndicatorWrapperStyles={dropdownIndicatorWrapper}
          customDropdownIndicatorArrowStyles={dropdownIndicatorArrow}
        />
      </div>
    );
  }

  if (type === suggestionsDropdownTypes.segment) {
    const segmentsOptionsFields = getSegmentsOptionsFields({ customFieldsIdToLabelMap, customUtmsWhitelist });
    return (
      <SearchableDropdownPopup
        options={groupBySegmentsOptions({ options: segmentsOptionsFields })}
        selectedValue={text}
        onSelectValue={(newSegment) => onChange({ placeHolderValue: newSegment.value })}
        dropdownAsLink
      />
    );
  }

  if (type === suggestionsDropdownTypes.channels || type === suggestionsDropdownTypes.categories) {
    const isCategories = type === suggestionsDropdownTypes.categories;
    const channelsWithNicknames = getChannelsWithNicknames();
    const channelsItemsOptions = isCategories ? Object.keys(groupBy(channelsWithNicknames, (c) => c.category)) : channelsWithNicknames.map((c) => c.value);
    const mappedChannelsOptions = channelsItemsOptions.map((value) => ({ value, label: getChannelNickname(value) }));

    return (
      <SearchableDropdownPopup
        options={mappedChannelsOptions}
        selectedValue={text}
        onSelectValue={(newChannel) => onChange({ placeHolderValue: newChannel.value })}
        dropdownAsLink
        dropdownProps={{
          menuMaxWidth: '200px',
          optionRenderer: (item) => (
            <div className={styles.channelItemWrap}>
              {isCategories ? (
                <div
                  className={selectStyles.categoryIcon}
                  data-icon={getCategoryIcon(item.value)}
                />
              ) : (
                <ChannelIcon
                  className={styles.filterItemIcon}
                  channelIcon={getChannelIcon(item.value)}
                  channel={item.value}
                />
              )}

              <div className={styles.channelNickname}>
                {getChannelNickname(item.label)}
              </div>
            </div>
          ),
        }}
      />
    );
  }

  if (type === suggestionsDropdownTypes.numbers) {
    const numbersOptionsOneToOneHundred = Array.from({ length: 100 }, (_, i) => i + 1).map((number) => ({
      label: number.toString(),
      value: number.toString(),
    }));
    return (
      <SearchableDropdownPopup
        options={numbersOptionsOneToOneHundred}
        selectedValue={text}
        onSelectValue={(newNumber) => onChange({ placeHolderValue: newNumber.value })}
        dropdownAsLink
      />
    );
  }

  if (type === suggestionsDropdownTypes.frequency) {
    return (
      <SearchableDropdownPopup
        options={Object.values(FREQUENCY_OPTIONS)}
        selectedValue={text}
        onSelectValue={(newFrequency) => onChange({ placeHolderValue: newFrequency.value })}
        dropdownAsLink
      />
    );
  }

  if (type === suggestionsDropdownTypes.direction) {
    return (
      <SearchableDropdownPopup
        options={budgetChangeOperatorsOptions}
        selectedValue={text}
        onSelectValue={(newDirection) => onChange({ placeHolderValue: newDirection.value })}
        dropdownAsLink
      />
    );
  }

  if (type === suggestionsDropdownTypes.whatIfTimeframes) {
    return (
      <SearchableDropdownPopup
        options={timeFrameOptions}
        selectedValue={text.value}
        onSelectValue={(newTimeframe) => onChange({ placeHolderValue: newTimeframe })}
        dropdownAsLink
      />
    );
  }

  if (type === suggestionsDropdownTypes.whatIfKpiFocus) {
    const { kpiStages } = getBaseFunnelAndKpiStages();
    return (
      <SearchableDropdownPopup
        options={kpiStages}
        selectedValue={text}
        onSelectValue={(newKpiFocus) => onChange({ placeHolderValue: newKpiFocus.value })}
        dropdownAsLink
      />
    );
  }

  if (type === suggestionsDropdownTypes.costs) {
    return (
      <SearchableDropdownPopup
        options={getCostPerAndROIMetricsOptions()}
        selectedValue={text}
        onSelectValue={(newCost) => onChange({ placeHolderValue: newCost.value })}
        dropdownAsLink
      />
    );
  }

  const filterDataConfig = filtersData?.find((filter) => filter.kind === suggestionsTypesToFilterKind[type]) || {};

  const metricsOptions = getMetricOptions();
  const suggestionsOptionsAccordingType = {
    [suggestionsDropdownTypes.funnels]: metricsOptions?.filter((metric) => metric.value !== 'pipeline' && metric.value !== 'revenue'),
    [suggestionsDropdownTypes.kpiFocus]: metricsOptions,
    [suggestionsDropdownTypes.customFields]: getMappingOptions(customFieldsIdToLabelMap),
    [suggestionsDropdownTypes.leadSources]: getMappingOptions(leadSourcesIdToLabelMap),
    [suggestionsDropdownTypes.conversionRateOrVelocity]: getConversionRateAndVelocityOptions({}),
    [suggestionsDropdownTypes.conversionRate]: getConversionRateAndVelocityOptions({ isIncludeVelocity: false }),
    [suggestionsDropdownTypes.velocity]: getConversionRateAndVelocityOptions({ isIncludeConversionRate: false }),
  };

  if (type === suggestionsDropdownTypes.customFields) {
    const selectedField = text.id;
    const selectedFieldOption = text.value;
    const fieldIndex = filterDataConfig.fieldKey?.findIndex((key) => key === selectedField);
    const filterKey = filterDataConfig.fieldKey?.[fieldIndex];
    const optionsForIndex = (filterDataConfig.options?.[fieldIndex] || []).map((filterOption) => ({
      label: checkIfDate(filterOption) ? dateFormat(filterOption) : filterOption,
      value: filterOption,
    }));
    if (optionsForIndex.length === 0) {
      optionsForIndex.push({ value: selectedFieldOption, label: selectedFieldOption });
    }

    return (
      <>
        <SearchableDropdownPopup
          options={groupBySegmentsOptions({ options: suggestionsOptionsAccordingType[type] })}
          selectedValue={selectedField}
          onSelectValue={(newId) => onChange({ placeHolderValue: { value: text.value, id: newId.value } })}
          dropdownAsLink
          dropdownProps={{
            menuMaxWidth: '200px',
          }}
        />
        <div className={styles.text}>
          {' is '}
        </div>
        <SearchableDropdownPopup
          options={optionsForIndex}
          selectedValue={selectedFieldOption}
          onSelectValue={(newValue) => onChange({ placeHolderValue: { value: newValue.value, id: text.id } })}
          dropdownAsLink
          dropdownProps={{
            isAsyncPagination: filterDataConfig.isSearchable,
            searchFunction: (searchValue, offset) => getFilterKindOptions({
              searchValue, filterKind: filterDataConfig.kind, filterKey, offset,
            }),
            menuMaxWidth: '200px',
          }}
        />
      </>
    );
  }

  let fieldIndex = filterDataConfig.fieldKey?.findIndex((key) => key === text);
  if (fieldIndex === -1) {
    fieldIndex = filterDataConfig.fieldKey?.findIndex((key) => key === type);
  }

  const filterKey = filterDataConfig.fieldKey?.[fieldIndex];
  let options = suggestionsOptionsAccordingType[type];
  if (!options) {
    options = (filterDataConfig.options?.[fieldIndex] || []).map((filterOption) => ({
      label: checkIfDate(filterOption) ? dateFormat(filterOption) : filterOption,
      value: filterOption,
    }));
    if (options.length === 0) {
      options.push({ value: text, label: getChannelNickname(text) });
    }
  }

  const shouldShowIcon = type === suggestionsDropdownTypes.contentTypes;

  return (
    <SearchableDropdownPopup
      options={options}
      selectedValue={text}
      onSelectValue={(newValue) => onChange({ placeHolderValue: newValue.value })}
      dropdownAsLink
      dropdownProps={{
        isAsyncPagination: filterDataConfig.isSearchable,
        searchFunction: (searchValue, offset) => getFilterKindOptions({
          searchValue, filterKind: filterDataConfig.kind, filterKey, offset,
        }),
        menuMaxWidth: '200px',
        optionRenderer: (item) => (
          <div className={styles.channelItemWrap}>
            {shouldShowIcon ? (
              <ChannelIcon
                className={styles.filterItemIcon}
                channelIcon={getChannelIcon(item.value)}
                channel={item.value}
              />
            ) : null}
            <div className={styles.channelNickname}>
              {getChannelNickname(item.label)}
            </div>
          </div>
        ),
      }}
    />
  );
}

export default inject(
  ({
    userStore: {
      userAccount: {
        customFieldsIdToLabelMap,
        customUtmsWhitelist,
        leadSourcesIdToLabelMap,
      } = {},
    } = {},
    attributionStore: {
      getMetricOptions,
    } = {},
    filterStore: {
      filtersData,
      getFilterKindOptions,
    } = {},
  }) => ({
    customFieldsIdToLabelMap,
    customUtmsWhitelist,
    getMetricOptions,
    leadSourcesIdToLabelMap,
    filtersData,
    getFilterKindOptions,
  }),
  observer
)(SuggestionPlaceholderDropdown);
