import { isNil } from 'lodash';

import userStore from 'stores/userStore';
import attributionStore from 'stores/attributionStore';

import { filtersUIConfig } from 'components/pages/users/Filters/FilterPopup';
import { VARIANTS_CONFIGS, POSITION_TYPES, getFilterLabel } from 'components/utils/filters';
import { camelCaseToReadable } from 'components/utils/logic/utils';
import { accountsFunnelStageOption, allFunnelStagesOption } from 'components/utils/filters/enums';
import { filterKinds, EQUALS, VARIANTS } from 'components/utils/filters/logic';

export function getQuickFilterTagLabel({ quickFilter, filtersData }) {
  const config = filtersData.find((c) => c.kind === quickFilter.kind);
  if (config) {
    return getFilterLabel({
      data: quickFilter.data,
      filterKind: quickFilter.kind,
      filterFieldNames: config.fieldNames,
      filterFieldKey: config.fieldKey,
    });
  }

  const variantLabel = VARIANTS_CONFIGS[quickFilter.data.variant].label;
  let filterKindLabel = filtersUIConfig[quickFilter.kind]?.title || camelCaseToReadable({ camelCaseString: quickFilter.kind });

  const fieldId = quickFilter.data.fieldId;
  if (fieldId) {
    filterKindLabel = userStore.userAccount.customFieldsIdToLabelMap?.[fieldId] || userStore.userAccount.leadSourcesIdToLabelMap?.[fieldId];
  }

  return `${variantLabel} ${filterKindLabel}`;
}

export function getSelectedFunnelsToFilter({ newFunnelsToFilter, currentFunnelsToFilter }) {
  const newFunnelsToFilterValues = newFunnelsToFilter.map((funnelOption) => funnelOption.value);
  const currentFunnelsToFilterValues = currentFunnelsToFilter.map((funnelOption) => funnelOption.value);

  if (newFunnelsToFilterValues.includes('accounts') && !currentFunnelsToFilterValues.includes('accounts')) {
    return [accountsFunnelStageOption];
  }

  if (newFunnelsToFilterValues.includes('allStages') && !currentFunnelsToFilterValues.includes('allStages')) {
    return [allFunnelStagesOption];
  }

  return newFunnelsToFilter.filter((newFunnels) => !['allStages', 'accounts'].includes(newFunnels.value));
}

export function getExtraFieldOptionsByFilterKind({ filterKind }) {
  const options = [];
  if (filterKind === filterKinds.CUSTOM_FIELDS) {
    for (const [customFieldId, customFieldLabel] of Object.entries(userStore.userAccount?.customFieldsIdToLabelMap || {})) {
      options.push({ value: customFieldId, label: customFieldLabel });
    }
  }

  return options;
}

export function getDefaultNewQuickFilterLogicByFilterKind() {
  return {
    [filterKinds.CUSTOM_FIELDS]: {
      selectedFunnels: ['allStages'],
      variant: VARIANTS.INCLUDE_ANY_OF,
      comparisonOperator: EQUALS,
      fieldId: Object.keys(userStore.userAccount?.customFieldsIdToLabelMap || {})[0],
    },
    [filterKinds.FUNNEL_STAGES]: {
      selectedFunnels: ['allStages'],
      variant: VARIANTS.BECAME_ONE_OF,
    },
    [filterKinds.CHANNELS]: {
      selectedFunnels: ['allStages'],
      variant: VARIANTS.INCLUDE_ANY_OF,
      fieldIndex: 0,
      position: POSITION_TYPES.anywhere,
    },
    [filterKinds.CAMPAIGNS]: {
      selectedFunnels: ['allStages'],
      variant: VARIANTS.INCLUDE_ANY_OF,
      fieldIndex: 0,
      comparisonOperator: EQUALS,
      position: POSITION_TYPES.anywhere,
    },
    [filterKinds.CAMPAIGN_TAGS]: {
      selectedFunnels: ['allStages'],
      variant: VARIANTS.INCLUDE_ANY_OF,
      fieldIndex: 1,
      comparisonOperator: EQUALS,
      position: POSITION_TYPES.anywhere,
    },
    [filterKinds.CONTENT]: {
      selectedFunnels: ['allStages'],
      variant: VARIANTS.INCLUDE_ANY_OF,
      fieldIndex: 0,
      comparisonOperator: EQUALS,
      position: POSITION_TYPES.anywhere,
    },
    [filterKinds.MARKETING_VS_SALES]: {
      variant: VARIANTS.INCLUDE_ANY_OF,
    },
  };
}

export function isFilterLogicValid({ filterKind, filterLogic = {} }) {
  const defaultNewQuickFilterLogicByFilterKind = getDefaultNewQuickFilterLogicByFilterKind();
  const requiredFieldsOfFilterKind = Object.keys(defaultNewQuickFilterLogicByFilterKind[filterKind] || {});

  for (const requiredField of requiredFieldsOfFilterKind) {
    if (isNil(filterLogic[requiredField])) {
      return false;
    }
  }

  return true;
}

export function getSelectedFunnels({ selectedFunnels }) {
  if (selectedFunnels) {
    const isAllFunnelsSelected = selectedFunnels.includes('allStages');
    if (isAllFunnelsSelected) {
      return [allFunnelStagesOption];
    }
    const isAccountSelected = selectedFunnels.length === 0;
    if (isAccountSelected) {
      return [accountsFunnelStageOption];
    }
    return selectedFunnels.map((funnel) => ({ value: funnel, label: userStore.getMetricNickname({ metric: funnel }) }));
  }

  return [];
}

export function getFilterErrorTooltip() {
  const pathname = window.location.pathname;

  const pathnameArray = pathname.split('/');
  const tabName = pathnameArray[pathnameArray.length - 1];
  return `This filter doesn’t affect the data shown in ${tabName}. Consider removing it.`;
}

export function removeAppliedFilterFromAnalyze({ filter }) {
  const isFilterAppliedInAnalyze = attributionStore.appliedQuickFilters.some((quickFilter) => quickFilter.id === filter.id);
  if (isFilterAppliedInAnalyze) {
    const isFilterUIOnly = filter.filter.isUiOnly;
    const appliedFilterIndex = attributionStore.appliedQuickFilters.findIndex((quickFilter) => quickFilter.id === filter.id);
    attributionStore.appliedQuickFilters.splice(appliedFilterIndex, 1);
    attributionStore.setQuickFilters(attributionStore.appliedQuickFilters, !isFilterUIOnly);
  }
}
