import React, { useState } from 'react';
import classnames from 'classnames';
import filterContainerStyle from 'styles/users/filters.css';
import InfoMarker from 'components/pages/InfoMarker';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import ArrowToggle from './UI/ArrowToggle';
import FilterActiveNumber from './UI/FilterActiveNumber';

const styles = filterContainerStyle.locals || {};

function FilterAccordion(props) {
  const {
    filtersUIConfig, filterCategory, activeFilterKind, filtersItems = [], flags,
  } = props;
  const [clickedIndex, setClickedIndex] = useState([0, 1]);
  const [menuItems, setMenuItems] = useState([...props.items]);
  const [inputSearch, setInputSearch] = useState('');

  const toggleHandler = (index) => {
    let temArr = [...clickedIndex, index];
    if (clickedIndex.indexOf(index) > -1) {
      temArr = temArr.filter((e) => e !== index);
    }
    setClickedIndex(temArr);
  };
  const isToggleOpen = (index) => clickedIndex.indexOf(index) > -1;

  const onclickChild = (item) => {
    props.handleActiveFilterClick(item);
  };

  const onInputSearchChange = (e) => {
    const inputText = e.target.value;
    let copyList = JSON.parse(JSON.stringify(props.filterConfigs));
    copyList = copyList.filter((item) => item.kind.toLowerCase().includes(inputText.toLowerCase()));
    setClickedIndex([0, 1]);
    setMenuItems([...copyList]);
    setInputSearch(inputText);
  };

  const clearSearch = () => {
    setMenuItems([...props.filterConfigs]);
    setInputSearch('');
  };

  const getFiltersCategoryWithLD = () => filterCategory.filter((category) => category.id === 1);

  const filterCategoryWithLD = flags.filtersAccordionCostMenu ? getFiltersCategoryWithLD() : filterCategory;

  return (
    <div className={styles.nav}>
      <div className={styles.search}>
        <input className={styles.searchInput} type="text" placeholder="Search..." value={inputSearch} onChange={onInputSearchChange} />
        <button onClick={clearSearch} type="button" className={styles.searchClear} />
      </div>
      <ul className={styles.pannleAccordion}>
        {filterCategoryWithLD.map((categoryItem, index) => {
          const isMenuOPen = isToggleOpen(index);
          const categoryKey = `category-${index}`;
          return (
            <li data-key={categoryItem.kind} key={categoryKey} className={styles.accordion}>
              <div className={styles.accordionCategory}>
                <div>
                  {categoryItem.title}
                </div>
                <InfoMarker containerClass={styles.tooltipIcon} tooltipText={categoryItem.tooltip} />
                <ArrowToggle isToggleOpen={isMenuOPen} onToggleClick={() => toggleHandler(index)} />
              </div>
              {isToggleOpen(index)
                && (
                  <ul>
                    {menuItems.map((child) => filtersUIConfig[child.kind] && child.kind !== 'saved' && filtersUIConfig[child.kind].categotyId === categoryItem.id && (
                      <li
                        key={child.kind}
                        data-key={child.kind}
                        className={classnames(styles.navItem, child.kind === props.getKind(activeFilterKind) && styles.accordionChildActive)}
                      >
                        <div
                          onClick={() => onclickChild(child)}
                        >
                          {filtersUIConfig[child.kind].title}
                          {filtersItems.filter((filterItem) => filterItem.kind === child.kind).map((filtered, filtersItemsIndex) => {
                            const menuItemsKey = `categoryKey-active_${filtersItemsIndex}`;
                            const isShowActiveFilter = filtered.data.selectedOptions?.length > 0 || filtered.data.rules?.length || filtered.data.manualValue >= 0;
                            return (
                              isShowActiveFilter && <FilterActiveNumber key={menuItemsKey} />
                            );
                          })}
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default withLDConsumer()(FilterAccordion);
