import React, { useState, useEffect } from 'react';
import { isEmpty, isNil } from 'lodash';
import { inject, observer } from 'mobx-react';
import { toast } from 'react-toastify';

import useStyles from 'hooks/useStyles';

import PopupWithDrawerSteps from 'components/common/PopupWithDrawerSteps';
import FilterKindSection from 'components/pages/settings/quickFilters/quickFilterPopup/filterKindSection';
import FilterLogicSection from 'components/pages/settings/quickFilters/quickFilterPopup/filterLogicSection';
import AdditionalConfigurationSection from 'components/pages/settings/quickFilters/quickFilterPopup/additionalConfigurationSection';
import servicesStore from 'stores/servicesStore';

import { getUniqueTitle } from 'components/utils/logic/utils';
import { nameAlreadyExistsErrorMessage } from 'components/enums';
import {
  defaultNewQuickFilterKind, quickFiltersCreationTypes, quickFiltersCreationTypesLabel, onSaveQuickFilterToastMessage, filterKindsWithoutAdditionalConfigurationStep,
} from 'components/pages/settings/quickFilters/enums';
import { getDefaultNewQuickFilterLogicByFilterKind, isFilterLogicValid, removeAppliedFilterFromAnalyze } from 'components/pages/settings/quickFilters/logic/quickFilters';
import { isUiFilter } from 'components/utils/filters';
import { Events } from 'trackers/analytics/enums';
import { getFilterEventProperties } from 'components/pages/users/Filters/logic/FilterActions';

import style from 'styles/settings/quickFilters/quickFilterPopup.css';

const styles = style.locals || {};

function QuickFilterPopup({
  quickFilterData = {},
  onClose,
  updateQuickFilterServerRequest,
  quickFilters,
  removeAIRecommendationFilter,
  getAIRecommendationServerRequest,
  stepIndexToOpen,
  isDisabled,
}) {
  useStyles([style]);

  const defaultNewQuickFilterLogicByFilterKind = getDefaultNewQuickFilterLogicByFilterKind();
  const [allQuickFiltersNames, setAllQuickFiltersNames] = useState(quickFilters?.map((filter) => filter.name));
  const [filterName, setFilterName] = useState({
    name: quickFilterData.name || getUniqueTitle({ baseTitle: 'Quick Filter', listOfNames: allQuickFiltersNames }),
    error: '',
  });
  const [filterKind, setFilterKind] = useState(quickFilterData.filter?.kind || defaultNewQuickFilterKind);
  const [filterLogic, setFilterLogic] = useState(quickFilterData.filter?.data || defaultNewQuickFilterLogicByFilterKind[filterKind]);
  const isQuickFilterDisabled = isDisabled || quickFilterData.type === quickFiltersCreationTypes.default;

  useEffect(() => {
    setFilterLogic(defaultNewQuickFilterLogicByFilterKind[filterKind]);
  }, [filterKind]);

  useEffect(() => {
    setFilterLogic(quickFilterData.filter?.data || defaultNewQuickFilterLogicByFilterKind[filterKind]);
  }, [quickFilterData.filter]);

  useEffect(() => {
    setAllQuickFiltersNames(quickFilters.map((filter) => filter.name));
  }, [quickFilters]);

  function changeFilterName({ newName }) {
    const isNameAlreadyExists = allQuickFiltersNames.some((name) => name.toLowerCase() === newName.toLowerCase());
    if (isNameAlreadyExists) {
      setFilterName({ name: newName, error: nameAlreadyExistsErrorMessage });
      return;
    }
    setFilterName({ name: newName, error: '' });
  }

  async function onSaveQuickFilter() {
    if (filterLogic.selectedFunnels?.includes('accounts')) {
      filterLogic.selectedFunnels = [];
    }

    const isQuickFilterFromAIRecommendation = quickFilterData.type === quickFiltersCreationTypes.ai;
    const quickFilterType = (!quickFilterData.type || isQuickFilterFromAIRecommendation) ? quickFiltersCreationTypes.manuallyCreated : quickFilterData.type;

    const quickFilterForServerRequest = {
      ...quickFilterData,
      name: filterName.name,
      type: quickFilterType,
      active: isNil(quickFilterData.active) ? true : quickFilterData.active,
      filter: {
        data: filterLogic,
        kind: filterKind,
        isUiOnly: isUiFilter(filterLogic),
      },
    };

    removeAppliedFilterFromAnalyze({ filter: quickFilterForServerRequest });
    updateQuickFilterServerRequest({ quickFilter: quickFilterForServerRequest });

    if (isQuickFilterFromAIRecommendation) {
      removeAIRecommendationFilter({ filterId: quickFilterData.id });
      getAIRecommendationServerRequest({ limit: 1 });
    }

    const properties = getFilterEventProperties({ filter: { kind: filterKind, data: filterLogic } });
    servicesStore.eventTracker.track({
      eventName: Events.quickFilters.savedNewQuickFilter,
      properties: {
        ...properties,
        isNew: isEmpty(quickFilterData),
        quickFilterType: quickFiltersCreationTypesLabel[quickFilterType],
      },
    });

    onClose();
    toast.success(onSaveQuickFilterToastMessage);
  }

  function onClickContinue({ stepIndex }) {
    if (stepIndex === 0) {
      servicesStore.eventTracker.track({
        eventName: Events.quickFilters.continuedQuickFilterAfterType,
        properties: {
          type: filterKind,
          isNew: isEmpty(quickFilterData),
        },
      });
      return;
    }

    if (stepIndex === 1) {
      const properties = getFilterEventProperties({
        filter: {
          data: filterLogic,
          kind: filterKind,
          isUiOnly: isUiFilter(filterLogic),
        },
      });
      servicesStore.eventTracker.track({
        eventName: Events.quickFilters.continuedQuickFilterAfterStagesAndLogic,
        properties: {
          ...properties,
          isNew: isEmpty(quickFilterData),
        },
      });
    }
  }

  const steps = [{
    title: '1. Filter Type',
    isValid: true,
    isShowContinueButton: true,
    content: (<FilterKindSection
      filterKind={filterKind}
      setFilterKind={(newKind) => setFilterKind(newKind)}
      isQuickFilterDisabled={isQuickFilterDisabled}
    />),
  }, {
    title: '2. Stages & Logic',
    isValid: isFilterLogicValid({ filterKind, filterLogic }),
    isShowContinueButton: true,
    content: (<FilterLogicSection
      filterKind={filterKind}
      setFilterKind={(newKind) => setFilterKind(newKind)}
      filterLogic={filterLogic}
      setFilterLogic={(newLogicData) => setFilterLogic((prevState) => ({ ...prevState, ...newLogicData }))}
      isQuickFilterDisabled={isQuickFilterDisabled}
    />),
  }];

  if (!filterKindsWithoutAdditionalConfigurationStep.includes(filterKind)) {
    steps.push({
      title: '3. Additional Configuration',
      isValid: true,
      isShowContinueButton: false,
      content: (<AdditionalConfigurationSection
        filterKind={filterKind}
        filterAdditionalData={filterLogic}
        setFilterAdditionalData={(newLogicData) => setFilterLogic((prevState) => ({ ...prevState, ...newLogicData }))}
        isQuickFilterDisabled={isQuickFilterDisabled}
      />),
    });
  }

  return (
    <PopupWithDrawerSteps
      onClose={onClose}
      title={filterName}
      updateTitle={({ newTitle }) => changeFilterName({ newName: newTitle })}
      isTitleEditable
      onClickSave={() => onSaveQuickFilter()}
      steps={steps}
      onContinue={({ stepIndex }) => onClickContinue({ stepIndex })}
      className={styles.pageContainer}
      stepIndexToOpen={stepIndexToOpen}
      disabled={isQuickFilterDisabled}
    />
  );
}

export default inject(
  ({
    filterStore: {
      updateQuickFilterServerRequest,
      quickFilters,
      removeAIRecommendationFilter,
      getAIRecommendationServerRequest,
    } = {},
  }) => ({
    updateQuickFilterServerRequest,
    quickFilters,
    removeAIRecommendationFilter,
    getAIRecommendationServerRequest,
  }),
  observer
)(QuickFilterPopup);
