import moment from 'moment';
import { cloneDeep, upperFirst } from 'lodash';

import userStore from 'stores/userStore';
import attributionStore from 'stores/attributionStore';
import timeFrameModule from 'modules/timeframe';

import { mergeAllFunnelsTableData } from 'components/pages/analyze/AttribuitonTable/logic/AttributionTable';
import { mapChartIndicator } from 'components/utils/indicators';
import { attributionModelsItems } from 'attribution/models';
import { defaultBaseFunnel, defaultSettings } from 'components/widgets/whatIf/enums';
import { formatToFloatWithoutZeros } from 'components/utils/logic/budget';

export function getBaseConfigWithSampleTimeframeMonths({ amountOfSampleTimeframeMonths }) {
  const timeframe = {
    rollingValue: `last_${amountOfSampleTimeframeMonths}_months_months`,
    value: 'rolling',
  };

  return {
    filters: [],
    timeFrame: {
      ...timeFrameModule.getTimeframeParams({ ...timeframe, fiscalYearFirstMonth: userStore.userMonthPlan.fiscalYearFirstMonth }),
      ...timeframe,
    },
    attributionModel: attributionModelsItems[0],
    shouldUsePredefinedFilters: true,
  };
}

export function getParsedMockBaseData({ baselineConfig, channelConfig }) {
  const parsedData = {};
  const { kpiStages } = getBaseFunnelAndKpiStages();
  for (const stage of kpiStages) {
    parsedData[stage.value] = {
      conversionRate: baselineConfig?.conversionRate,
      velocity: baselineConfig.velocity,
      avgDealSize: baselineConfig.ARPA,
    };
  }

  const paidChannels = userStore.userPaidChannels;
  for (const channel of paidChannels) {
    parsedData[channel] = {
      conversionRate: channelConfig.conversionRate,
      velocity: channelConfig.velocity,
      avgDealSize: channelConfig.ARPA,
    };
  }

  return parsedData;
}

export function getBaseFunnelAndKpiStages() {
  let baseFunnel = userStore?.userMonthPlan?.forecasterConfig?.bkpiFunnel;
  if (!baseFunnel) {
    baseFunnel = defaultBaseFunnel;
  }

  const sourceFunnelNumber = parseInt(baseFunnel.split('funnel')[1], 10);
  const kpiStages = attributionStore.metricsOptions.slice(sourceFunnelNumber);

  return {
    baseFunnel,
    kpiStages,
  };
}

export function getParsedAccountBaseData({ data, avgMonthlySpend }) {
  const parsedData = {};
  const { baseFunnel, kpiStages } = getBaseFunnelAndKpiStages();
  for (const stage of kpiStages) {
    let arpa = 0;
    if (data?.ARPA?.total?.value) {
      arpa = data.ARPA.total.value * 12;
    }

    parsedData[stage.value] = {
      conversionRate: formatToFloatWithoutZeros({ number: data?.[`${baseFunnel}To${upperFirst(stage.originalFunnel)}ConversionRate`]?.total?.value }),
      velocity: formatToFloatWithoutZeros({ number: data?.[`${baseFunnel}To${upperFirst(stage.originalFunnel)}Velocity`]?.total?.value }),
      avgDealSize: formatToFloatWithoutZeros({ number: arpa }),
    };

    parsedData[stage.value].avgMonthlySpend = formatToFloatWithoutZeros({ number: avgMonthlySpend?.averageMonthlySpend || 0 });
    parsedData[stage.value].shouldUseAverageMonthlySpend = avgMonthlySpend?.hasFullPlan === undefined ? true : !avgMonthlySpend?.hasFullPlan;
    parsedData[stage.value].hasFullPlan = avgMonthlySpend?.hasFullPlan === undefined ? false : avgMonthlySpend?.hasFullPlan;
  }
  return parsedData;
}

export function getParsedChannelBaseData({ data, kpiFocus }) {
  const parsedData = {};
  const paidChannels = userStore.userPaidChannels;
  const currentStage = mapChartIndicator(kpiFocus);
  const { baseFunnel } = getBaseFunnelAndKpiStages();
  const selectedMetricKey = `${baseFunnel}To${upperFirst(currentStage)}`;
  const mergedSegmentsAnalysisData = mergeAllFunnelsTableData({ tableDataByFunnel: data, selectedStageKey: currentStage });

  for (const channel of paidChannels) {
    const channelConfig = mergedSegmentsAnalysisData.find((item) => item.firstSegment === channel);
    parsedData[channel] = {
      conversionRate: channelConfig ? formatToFloatWithoutZeros({ number: channelConfig[`${selectedMetricKey}ConversionRate`] }) : 0,
      velocity: channelConfig ? formatToFloatWithoutZeros({ number: channelConfig[`${selectedMetricKey}Velocity`] }) : 0,
      avgDealSize: channelConfig ? formatToFloatWithoutZeros({ number: channelConfig.ARPA }) : 0,
    };
  }
  return parsedData;
}

export function getParsedAvgMonthlySpendData({ data, channelsBaseData }) {
  const updatedChannelBaseData = cloneDeep(channelsBaseData);
  for (const channelKey of Object.keys(channelsBaseData)) {
    const avgMonthlySpendForChannel = data?.[channelKey] || {};
    updatedChannelBaseData[channelKey].avgMonthlySpend = formatToFloatWithoutZeros({ number: avgMonthlySpendForChannel?.averageMonthlySpend || 0 });
    updatedChannelBaseData[channelKey].shouldUseAverageMonthlySpend = avgMonthlySpendForChannel?.hasFullPlan === undefined ? true : !avgMonthlySpendForChannel?.hasFullPlan;
    updatedChannelBaseData[channelKey].hasFullPlan = avgMonthlySpendForChannel?.hasFullPlan === undefined ? false : avgMonthlySpendForChannel?.hasFullPlan;
  }
  return updatedChannelBaseData;
}

export function getSelectedMetricsForRequest() {
  const selectedMetrics = new Set(['ARPA']);
  const { baseFunnel, kpiStages } = getBaseFunnelAndKpiStages();
  for (const stage of kpiStages) {
    const selectedMetricKey = `${baseFunnel}To${upperFirst(stage.originalFunnel)}`;
    selectedMetrics.add(`${selectedMetricKey}ConversionRate`);
    selectedMetrics.add(`${selectedMetricKey}Velocity`);
  }
  return Array.from(selectedMetrics);
}

export function getPreviousMonthShortLabel({ chartDataFirstMonth }) {
  const currentMonth = moment().format('MMM');
  if (currentMonth === chartDataFirstMonth) {
    return currentMonth;
  }
  return moment().subtract(1, 'month').format('MMM');
}

export function getParsedScenarioChartData({ data, timeFrameLabel }) {
  let isFutureMonth = timeFrameLabel.includes('next');
  const currentMonthLabel = getPreviousMonthShortLabel({ chartDataFirstMonth: data[0]?.name });
  const updatedChartData = cloneDeep(data);

  for (const chartItem of updatedChartData) {
    if (chartItem.name === currentMonthLabel && !isFutureMonth) {
      chartItem.predictedBaseline = chartItem.baseline;
      isFutureMonth = true;
      continue;
    }
    if (isFutureMonth) {
      chartItem.predictedBaseline = chartItem.baseline;
      delete chartItem.baseline;
      continue;
    }
    for (const chartItemKey in chartItem) {
      if (chartItemKey !== 'name' && chartItemKey !== 'baseline') {
        delete chartItem[chartItemKey];
      }
    }
  }
  return updatedChartData;
}

export function getDefaultKpiFocus() {
  const pipeline = defaultSettings.kpiFocus;
  const kpiStages = attributionStore.metricsOptions;
  const hasPipelineInStages = kpiStages.find((stage) => stage.value === pipeline);
  if (hasPipelineInStages) {
    return pipeline;
  }
  return 'revenue';
}
